import { CAMERA, usePermissions } from 'expo-permissions';
import React, { PropsWithChildren, useEffect } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { isWeb } from '../config/constants';
import workflowScreenStyles from '../config/workflowScreenStyles';
import { useInvalidateAvailableVideoDevices } from '../hooks/useAvailableVideoDevices';
import BodyText from './BodyText';
import CameraIllustration from './CameraIllustration';
import Header from './Header';
import NavigationHeader from './NavigationHeader';
import LoadingLogo from './LoadingLogo';
import PrimaryButton from './PrimaryButton';
import ResponsiveContainer from './ResponsiveContainer';

const errorMessage = `Please enable this permission in ${
  isWeb ? 'browser or OS' : 'OS'
} settings.`;

export default function RequireCameraPermission({
  children,
  onBackPressed,
}: PropsWithChildren<{ onBackPressed: () => void }>) {
  const [permission, askPermission] = usePermissions(CAMERA);
  const permissionGranted = permission?.granted ?? false;
  const isPermanentlyDisabled =
    permission && (!permission.canAskAgain || permission.status === 'denied');

  const invalidateAvailableVideoDevices = useInvalidateAvailableVideoDevices();

  useEffect(() => {
    invalidateAvailableVideoDevices();
  }, [permissionGranted, invalidateAvailableVideoDevices]);

  if (!permission) {
    return <LoadingLogo />;
  }

  if (permissionGranted) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <NavigationHeader onBackPressed={onBackPressed} />
      <View style={workflowScreenStyles.content}>
        <ResponsiveContainer>
          <View style={workflowScreenStyles.group}>
            <Header text="Camera time" />
            <BodyText text="This is video chat, so we'll need you to allow camera access." />
            <View style={workflowScreenStyles.illustration}>
              <CameraIllustration />
            </View>
            {isPermanentlyDisabled && (
              <BodyText
                text={errorMessage}
                style={workflowScreenStyles.errorText}
              />
            )}
          </View>
          <View style={workflowScreenStyles.actions}>
            <PrimaryButton
              title="Continue"
              onPress={askPermission}
              disabled={isPermanentlyDisabled}
            />
          </View>
        </ResponsiveContainer>
      </View>
    </SafeAreaView>
  );
}
