import Video from 'twilio-video';
import RemoteVideoTrack from './RemoteVideoTrack';

export default class RemoteVideoTrackPublication {
  twilioRemoteVideoTrackPublication: Video.RemoteVideoTrackPublication;
  remoteTrack?: RemoteVideoTrack;

  constructor(
    twilioRemoteVideoTrackPublication: Video.RemoteVideoTrackPublication
  ) {
    this.twilioRemoteVideoTrackPublication = twilioRemoteVideoTrackPublication;
    if (twilioRemoteVideoTrackPublication.isSubscribed) {
      this.onSubscribed();
    }
    twilioRemoteVideoTrackPublication.on('subscribed', this.onSubscribed);
  }

  get track(): RemoteVideoTrack {
    return this.remoteTrack;
  }

  get trackSid(): string {
    return this.twilioRemoteVideoTrackPublication.trackSid;
  }

  get trackName(): string {
    return this.twilioRemoteVideoTrackPublication.trackName;
  }

  onSubscribed = () => {
    this.remoteTrack = new RemoteVideoTrack(
      this.twilioRemoteVideoTrackPublication.track
    );
  };
}
