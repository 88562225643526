import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DEFAULT_SPACING } from '../../../config/constants';
import useVideoGridCellScaledStyle from '../../../hooks/useVideoGridCellScaledStyle';
import {
  DEFAULT_ICON_HEIGHT,
  DEFAULT_ICON_WIDTH,
  MutedIcon,
} from '../../Icons';

interface Props {
  id: string;
  muted: boolean;
}

export default function MutedVideoOverlay({ id, muted }: Props) {
  const scaledRootStyle = useVideoGridCellScaledStyle(
    {
      bottom: DEFAULT_SPACING,
      left: DEFAULT_SPACING,
      height: DEFAULT_SPACING * 3,
      width: DEFAULT_SPACING * 3,
      borderRadius: DEFAULT_SPACING * 1.5,
    },
    id
  );

  const scaledIconStyle = useVideoGridCellScaledStyle(
    {
      width: DEFAULT_ICON_WIDTH,
      height: DEFAULT_ICON_HEIGHT,
    },
    id
  );

  return (
    muted && (
      <View style={[styles.root, scaledRootStyle]}>
        <MutedIcon {...scaledIconStyle} />
      </View>
    )
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
