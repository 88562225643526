import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import ConnectionContext from '../contexts/ConnectionContext';

interface SubscriptionActions {
  [event: string]: (payload: any) => void;
}

export interface Subscription {
  perform: (action: string, attributes?: any) => void;
}

export default function useSubscription(
  params: any,
  actions: SubscriptionActions
) {
  const consumer = useContext(ConnectionContext);
  const actionsRef = useRef(actions);

  useEffect(() => {
    actionsRef.current = actions;
  }, [actions]);

  const [performQueue, setPerformQueue] = useState([]);
  const subscriptionRef = useRef(null);

  const flushPerformQueue = () => {
    if (!subscriptionRef.current || _.isEmpty(performQueue)) {
      return;
    }

    _.each(performQueue, ({ action, attributes }) => {
      subscriptionRef.current.perform(action, attributes || {});
    });

    setPerformQueue([]);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    flushPerformQueue();
  }, [performQueue]);

  useEffect(() => {
    const subscription = consumer.subscriptions.create(params, {
      received({ event, payload }) {
        const action = actionsRef.current[event];
        if (action) {
          action(payload);
        } else {
          console.error(`${params} received unknown event ${event}`);
        }
      },
    });
    subscriptionRef.current = subscription;

    flushPerformQueue();

    return () => subscription.unsubscribe();
  }, [JSON.stringify(params)]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const perform = useCallback(
    (action: string, attributes: any) => {
      setPerformQueue([...performQueue, { action, attributes }]);
    },
    [performQueue]
  );

  return useMemo(() => ({ perform }), [perform]);
}
