import _ from 'lodash';
import { RemoteConnection } from '../contexts/RemoteConnectionsContext';
import { Connection } from '../hooks/useConnection';
import { VIDEO_TRACK_NAME_PREFIX } from '../hooks/useLocalVideoTrack';
import { RemoteMembership } from '../state/remoteMembershipState';
import {
  LocalAudioTrack,
  LocalParticipant,
  LocalVideoTrack,
  Room,
} from '../twilio';
import TwilioRemoteParticipantConnection from './TwilioRemoteParticipantConnection';

export interface TwilioRoomConnectionCreateParams {
  twilioRoomSid: string;
  token: string;
  membershipId: string;
  connection: Connection;
}

export default class TwilioRoomConnection {
  room: Room;
  membershipId: string;
  connection: Connection;

  twilioRemoteParticipantConnections: {
    [twilioParticipantSid: string]: TwilioRemoteParticipantConnection;
  } = {};

  onChange: (twilioRoomConnection: TwilioRoomConnection) => void;

  constructor(room: Room, membershipId: string, connection: Connection) {
    this.room = room;
    this.membershipId = membershipId;
    this.connection = connection;

    room.on('connected', this.onRoomConnected);
    room.on('disconnected', this.onRoomEvent);
    room.on('failedToConnect', this.onRoomEvent);
    room.on('disconnected', this.onRoomEvent);
    room.on('reconnecting', this.onRoomEvent);
    room.on('reconnected', this.onRoomEvent);
    room.on('participantConnected', this.onRoomEvent);
    room.on('participantDisconnected', this.onRoomEvent);
    room.on('recordingStarted', this.onRoomEvent);
    room.on('recordingStopped', this.onRoomEvent);
    room.on('dominantSpeakerChanged', this.onRoomEvent);
  }

  static async create({
    twilioRoomSid,
    token,
    membershipId,
    connection,
  }: TwilioRoomConnectionCreateParams): Promise<TwilioRoomConnection> {
    const room = await Room.connect(token, {
      roomName: twilioRoomSid,
      bandwidthProfile: {
        video: {
          mode: 'collaboration',
          trackSwitchOffMode: 'detected',
          maxTracks: 10,
          renderDimensions: {
            high: { height: 1440, width: 1440 },
            standard: { height: 720, width: 720 },
            low: { height: 360, width: 360 },
          },
          // maxSubscriptionBitrate: 2500000,
        },
      },
      preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
      networkQualityConfiguration: {
        local: 'minimal',
        remote: 'minimal',
      },
      isNetworkQualityEnabled: true,
      isDominantSpeakerEnabled: true,
      encodingParameters: {
        audioBitrate: 16,
        videoBitrate: 0,
      },
    });
    return new TwilioRoomConnection(room, membershipId, connection);
  }

  onRoomConnected = () => {
    this.room.localParticipant.on(
      'audioTrackPublicationFailed',
      this.onRoomEvent
    );
    this.room.localParticipant.on('audioTrackPublished', this.onRoomEvent);
    this.room.localParticipant.on(
      'dataTrackPublicationFailed',
      this.onRoomEvent
    );
    this.room.localParticipant.on('dataTrackPublished', this.onRoomEvent);
    this.room.localParticipant.on(
      'networkQualityLevelChanged',
      this.onRoomEvent
    );
    this.room.localParticipant.on(
      'videoTrackPublicationFailed',
      this.onRoomEvent
    );
    this.room.localParticipant.on('videoTrackPublished', this.onRoomEvent);

    this.onRoomEvent();
  };

  onRoomEvent = () => {
    this.syncParticipants();

    if (this.onChange) {
      this.onChange(this);
    }
  };

  syncParticipants = () => {
    _.each(this.room.remoteParticipants, (remoteParticipant) => {
      if (
        !_.has(this.twilioRemoteParticipantConnections, remoteParticipant.sid)
      ) {
        const twilioRemoteParticipantConnection = new TwilioRemoteParticipantConnection(
          remoteParticipant,
          this.membershipId,
          this.connection
        );
        twilioRemoteParticipantConnection.onChange = () => {
          this.onChange(this);
        };
        this.twilioRemoteParticipantConnections[
          remoteParticipant.sid
        ] = twilioRemoteParticipantConnection;
      }
    });

    const previousRemoteParticipantSids = _.keys(
      this.twilioRemoteParticipantConnections
    );
    const currentRemoteParticipantSids = _.map(
      this.room.remoteParticipants,
      'sid'
    );
    const remoteParticipantSidsToDestroy = _.difference(
      previousRemoteParticipantSids,
      currentRemoteParticipantSids
    );
    _.each(remoteParticipantSidsToDestroy, (remoteParticipantSid) => {
      this.twilioRemoteParticipantConnections[
        remoteParticipantSid
      ].unsubscribe();
      delete this.twilioRemoteParticipantConnections[remoteParticipantSid];
    });
  };

  destroy = () => {
    this.room.disconnect();
    _.each(
      _.values(this.twilioRemoteParticipantConnections),
      (twilioRemoteParticipantConnection) =>
        twilioRemoteParticipantConnection.unsubscribe()
    );
    this.twilioRemoteParticipantConnections = {};
  };

  toRemoteMemberships: () => RemoteMembership[] = () => {
    return _.compact(
      _.map(
        _.values(this.twilioRemoteParticipantConnections),
        'remoteMembership'
      )
    );
  };

  toRemoteConnections: () => RemoteConnection[] = () => {
    return _.map(
      _.values(this.twilioRemoteParticipantConnections),
      (twilioRemoteParticipantConnection) =>
        twilioRemoteParticipantConnection.toRemoteConnection()
    );
  };

  get localParticipant(): LocalParticipant {
    return this.room.localParticipant;
  }

  get localParticipantVideoTrack(): LocalVideoTrack {
    if (!this.localParticipant) {
      return null;
    }

    return _.get(
      _.find(this.localParticipant.localVideoTracks, (track) =>
        _.startsWith(track.trackName, VIDEO_TRACK_NAME_PREFIX)
      ),
      'localTrack'
    );
  }

  get localParticipantAudioTrack(): LocalAudioTrack {
    if (!this.localParticipant) {
      return null;
    }

    return _.get(_.first(this.localParticipant.localAudioTracks), 'localTrack');
  }

  get localParticipantTwilioParticipantSid(): string {
    return _.get(this.localParticipant, 'sid');
  }
}
