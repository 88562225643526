import React, { useEffect, useRef } from 'react';
import { View, ViewProps } from 'react-native';
import LocalVideoTrack from '../models/LocalVideoTrack';

interface LocalVideoTrackViewProps extends ViewProps {
  scaleType?: 'aspectFill' | 'aspectFit';
  mirror?: boolean;
  localVideoTrack: LocalVideoTrack;
}

export default function LocalVideoTrackView({
  localVideoTrack,
  scaleType,
  mirror,
  style,
}: LocalVideoTrackViewProps) {
  const videoElementRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (localVideoTrack) {
      const videoElement = videoElementRef.current;
      localVideoTrack.twilioLocalVideoTrack.attach(videoElement);
      return () => {
        localVideoTrack.twilioLocalVideoTrack.detach(videoElement);
      };
    }
  }, [localVideoTrack]);

  const transformStyle = mirror ? { transform: 'scaleX(-1)' } : {};

  return (
    <View style={style}>
      {/* eslint-disable react-native/no-inline-styles */}
      <video
        ref={videoElementRef}
        style={{
          width: '100%',
          height: '100%',
          flex: '1 1 auto',
          objectFit: scaleType === 'aspectFill' ? 'cover' : 'contain',
          ...transformStyle,
        }}
      />
    </View>
  );
}
