import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { DEFAULT_SPACING } from '../../config/constants';
import { TopLeftCornerIcon } from '../Icons';

interface Props {
  style: StyleProp<ViewStyle>;
}

export default function DraftProfileCameraContainer({
  style,
  children,
}: PropsWithChildren<Props>) {
  return (
    <View style={style}>
      {children}
      <View style={styles.corners}>
        <View style={[styles.corner, styles.topLeftCorner]}>
          <TopLeftCornerIcon
            height={DEFAULT_SPACING}
            width={DEFAULT_SPACING}
            color="white"
          />
        </View>
        <View style={[styles.corner, styles.topRightCorner]}>
          <TopLeftCornerIcon
            height={DEFAULT_SPACING}
            width={DEFAULT_SPACING}
            color="white"
          />
        </View>
        <View style={[styles.corner, styles.bottomRightCorner]}>
          <TopLeftCornerIcon
            height={DEFAULT_SPACING}
            width={DEFAULT_SPACING}
            color="white"
          />
        </View>
        <View style={[styles.corner, styles.bottomLeftCorner]}>
          <TopLeftCornerIcon
            height={DEFAULT_SPACING}
            width={DEFAULT_SPACING}
            color="white"
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    overflow: 'hidden',
    marginVertical: DEFAULT_SPACING,
  },
  corners: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  corner: {
    position: 'absolute',
  },
  topLeftCorner: {
    top: 0,
    left: 0,
  },
  topRightCorner: {
    top: 0,
    right: 0,
    transform: [{ rotate: '90deg' }],
  },
  bottomRightCorner: {
    bottom: 0,
    right: 0,
    transform: [{ rotate: '180deg' }],
  },
  bottomLeftCorner: {
    bottom: 0,
    left: 0,
    transform: [{ rotate: '270deg' }],
  },
});
