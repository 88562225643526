import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { DEFAULT_SPACING } from '../config/constants';
import { BackIcon, CloseIcon } from './Icons';

interface Props {
  onBackPressed?: () => void;
  onClosePressed?: () => void;
}

export default function NavigationHeader({
  onBackPressed,
  onClosePressed,
}: Props) {
  return (
    <View style={styles.root}>
      {onBackPressed && (
        <Pressable style={styles.button} onPress={onBackPressed}>
          <BackIcon />
        </Pressable>
      )}
      <View style={styles.spacer} />
      {onClosePressed && (
        <Pressable style={styles.button} onPress={onClosePressed}>
          <CloseIcon />
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignContent: 'stretch',
    height: DEFAULT_SPACING * 8 + 18,
    flexGrow: 0,
  },
  button: {
    padding: DEFAULT_SPACING * 4,
  },
  spacer: {
    flex: 1,
  },
});
