import { AxiosResponse } from 'axios';
import Api from './Api';

export interface CreateStripeSubscriptionParams {
  stripePaymentMethodId: string;
  stripePriceId: string;
}

export async function createStripeSubscription(
  stripeSubscription: CreateStripeSubscriptionParams
) {
  const response = await Api.post<
    CreateStripeSubscriptionParams,
    AxiosResponse<{}>
  >('stripe_subscription', { stripeSubscription });

  if (response.status !== 201) {
    console.error(response);
    throw 'something went wrong!';
  }

  return;
}
