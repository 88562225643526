import React from 'react';
import { StatusBar, StyleSheet, View } from 'react-native';
import { WorkflowModalProps } from './WorkflowModal';

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
  },
});

export default function WorkflowModalFullScreen({
  children,
}: WorkflowModalProps) {
  return (
    <View style={[StyleSheet.absoluteFill, styles.root]}>
      <StatusBar barStyle="dark-content" />
      {children}
    </View>
  );
}
