import React, { PropsWithChildren } from 'react';
import ConnectionContext from '../contexts/ConnectionContext';
import useConnection from '../hooks/useConnection';

export default function Connected({ children }: PropsWithChildren<{}>) {
  const consumer = useConnection();

  if (!consumer) {
    return null;
  }

  return (
    <ConnectionContext.Provider value={consumer}>
      {children}
    </ConnectionContext.Provider>
  );
}
