import _ from 'lodash';
import { selectorFamily } from 'recoil';
import remoteMembershipsState from './remoteMembershipsState';

export interface RemoteMembership {
  id: string;
  twilioParticipantSid: string;
  muted: boolean;
  videoMuted: boolean;
  shouting: boolean;
  name: string;
  groupId: string;
  active: boolean;
  avatarPath: string;
  selectedMembershipIds: string[];
  screenSharing: boolean;
}

const remoteMembershipState = selectorFamily<RemoteMembership, string>({
  key: 'remoteMembershipState',
  get: (id) => {
    return ({ get }) => {
      const remoteMemberships = get(remoteMembershipsState);
      return _.find(
        remoteMemberships,
        (remoteMembership) => remoteMembership.id === id
      );
    };
  },
});
export default remoteMembershipState;

export function membershipName({ id, name }: { id: string; name: string }) {
  return name || `User ${_.join(_.slice(id, -4), '')}`;
}
