import _ from 'lodash';
import { selectorFamily } from 'recoil';
import activeRemoteMembershipsState from './activeRemoteMembershipsState';

const groupRemoteMembershipIdsState = selectorFamily<string[], string>({
  key: 'groupRemoteMembershipIdsState',
  get: (id) => {
    return ({ get }) => {
      const activeRemoteMemberships = get(activeRemoteMembershipsState);
      const groupRemoteMemberships = _.filter(
        activeRemoteMemberships,
        (remoteMembership) => remoteMembership.groupId === id
      );
      const groupRemoteMembershipIds = _.map(groupRemoteMemberships, 'id');

      return groupRemoteMembershipIds;
    };
  },
});
export default groupRemoteMembershipIdsState;
