import Video from 'twilio-video';

export default class RemoteAudioTrack {
  twilioRemoteAudioTrack: Video.RemoteAudioTrack;

  constructor(twilioRemoteAudioTrack: Video.RemoteAudioTrack) {
    this.twilioRemoteAudioTrack = twilioRemoteAudioTrack;
  }

  attach(element?: HTMLMediaElement) {
    this.twilioRemoteAudioTrack.attach(element);
  }

  detatch(element?: HTMLMediaElement) {
    this.twilioRemoteAudioTrack.detach(element);
  }
}
