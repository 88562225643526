import Constants from 'expo-constants';

const environment = {
  apiUrl: Constants.manifest?.extra?.apiUrl ?? 'https://www.stoop.video',
  appUrl: Constants.manifest?.extra?.appUrl ?? 'https://app.stoop.video',
  stripePublishableKey:
    Constants.manifest?.extra?.stripePublishableKey ??
    'pk_live_51HpKchLfx2ImaU9Gik3ex6ASlr71BNzFIgL3ZBPulqeN0mZJbAIqPUkFJYtxiU0HlHUpzMVlXfRrqDpDajR8WxoZ00ir0PJCwp',
  sentryDsn:
    Constants.manifest?.extra?.sentryDsn ??
    'https://3e251a67947442b692878212ab3a453f@o480048.ingest.sentry.io/5526105',
};

export default function config() {
  return environment;
}
