import React, { Ref, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, Platform, StyleSheet, View } from 'react-native';
import { DEFAULT_SPACING } from '../../config/constants';
import Camera from '../Camera';
import DraftProfileCameraContainer from './DraftProfileCameraContainer';

export default function DraftProfileCamera({
  innerRef,
  onCameraReady,
  isOnScreen,
}: {
  innerRef: Ref<Camera>;
  onCameraReady: () => void;
  isOnScreen: boolean;
}) {
  const [layoutWidth, setLayoutWidth] = useState(360);

  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setLayoutWidth(width);
    },
    []
  );

  const cameraStyle = useMemo(() => {
    const actualCameraHeight = Platform.select({
      android: layoutWidth * (4 / 3),
      default: layoutWidth,
    });
    const excessActualCameraHeight = actualCameraHeight - layoutWidth;
    return {
      marginTop: -excessActualCameraHeight / 2,
      height: actualCameraHeight,
      width: layoutWidth,
    };
  }, [layoutWidth]);

  const containerSizeStyle = useMemo(() => ({ height: layoutWidth }), [
    layoutWidth,
  ]);

  return (
    <View style={styles.root} onLayout={onLayout}>
      {(isOnScreen || Platform.OS !== 'android') && (
        <DraftProfileCameraContainer style={containerSizeStyle}>
          <Camera
            ref={innerRef}
            type={Camera.Constants.Type.front}
            style={cameraStyle}
            onCameraReady={onCameraReady}
            ratio="4:3"
          />
        </DraftProfileCameraContainer>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    overflow: 'hidden',
    marginVertical: DEFAULT_SPACING * 4,
    alignSelf: 'stretch',
  },
  corners: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  corner: {
    position: 'absolute',
  },
  topLeftCorner: {
    top: 0,
    left: 0,
  },
  topRightCorner: {
    top: 0,
    right: 0,
    transform: [{ rotate: '90deg' }],
  },
  bottomRightCorner: {
    bottom: 0,
    right: 0,
    transform: [{ rotate: '180deg' }],
  },
  bottomLeftCorner: {
    bottom: 0,
    left: 0,
    transform: [{ rotate: '270deg' }],
  },
});
