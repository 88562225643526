import React from 'react';
import { SvgProps } from 'react-native-svg';
import MicrophoneIllustrationSvg from '../../assets/icons/microphoneIllustration.svg';
import Svg from './Svg';

export default function MicrophoneIllustration(props: SvgProps) {
  return (
    <Svg>
      <MicrophoneIllustrationSvg
        {...{
          width: 200,
          height: 200,
          ...props,
        }}
      />
    </Svg>
  );
}
