import { selector } from 'recoil';
import groupIdState from './groupIdState';
import groupState from './groupState';

const videoGridDeparturesState = selector({
  key: 'videoGridDeparturesState',
  get: ({ get }) => {
    return get(groupState(get(groupIdState)))?.departures ?? [];
  },
});
export default videoGridDeparturesState;
