import React from 'react';
import { PropsWithChildren } from 'react';
import { View, ViewStyle } from 'react-native';

export default function KeyboardDismissingBackground({
  style,
  children,
}: PropsWithChildren<{ style?: ViewStyle }>) {
  return <View style={style}>{children}</View>;
}
