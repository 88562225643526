import _ from 'lodash';
import { selectorFamily } from 'recoil';
import departureState from './departureState';
import membershipState, { Membership } from './membershipState';

const departureMembershipsState = selectorFamily<Membership[], string>({
  key: 'departureMembershipsState',
  get: (id) => ({ get }) => {
    const departure = get(departureState(id));
    return _.map(departure.membershipIds, (membershipId) =>
      get(membershipState(membershipId))
    );
  },
});
export default departureMembershipsState;
