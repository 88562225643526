import _ from 'lodash';
import { selectorFamily } from 'recoil';
import departureVideoGridLayoutState from './departureVideoGridLayoutState';
import membershipDepartureState from './membershipDepartureState';
import { Frame } from './roomLayoutState';
import videoGridCellLayoutByCellIndexState from './videoGridCellLayoutByCellIndexState';
import videoGridMembershipIdsState from './videoGridMembershipIdsState';

export const VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE = 720;

const videoGridMembershipCellLayoutState = selectorFamily<Frame, string>({
  key: 'videoGridMembershipCellLayoutState',
  get: (id) => ({ get }) => {
    const departure = get(membershipDepartureState(id));
    if (departure) {
      const departureVideoGridLayout = get(
        departureVideoGridLayoutState(departure.id)
      );
      const departureMembershipIds = departure.membershipIds;
      const cellIndex = _.indexOf(departureMembershipIds, id);
      return departureVideoGridLayout.videoGridCellFrames[cellIndex];
    }
    const videoGridMembershipIds = get(videoGridMembershipIdsState);
    const cellIndex = _.indexOf(videoGridMembershipIds, id);
    return get(videoGridCellLayoutByCellIndexState(cellIndex));
  },
});
export default videoGridMembershipCellLayoutState;
