import _ from 'lodash';
import { selector } from 'recoil';
import groupIdState from './groupIdState';
import groupSortedMembershipIdsState from './groupSortedMembershipIdsState';
import membershipDepartureState from './membershipDepartureState';

const videoGridMembershipCellsCountState = selector({
  key: 'videoGridMembershipCellsCountState',
  get: ({ get }) => {
    const videoGridMembershipIdsIncludingDeparting = get(
      groupSortedMembershipIdsState(get(groupIdState))
    );
    const remainingMembershipIds = _.filter(
      videoGridMembershipIdsIncludingDeparting,
      (membershipId) => {
        const departure = get(membershipDepartureState(membershipId));
        return !departure;
      }
    );
    return _.size(remainingMembershipIds);
  },
});
export default videoGridMembershipCellsCountState;
