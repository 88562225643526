import React from 'react';
import { Pressable } from 'react-native';
import { useRecoilState } from 'recoil';
import roomPreviewButtonStyles from '../../../config/roomPreviewButtonStyles';
import mutedState from '../../../state/mutedState';
import { AudioIcon } from '../../Icons';
import Tooltip from '../../Tooltip';

export default function RoomPreviewMuteButton() {
  const [audioMuted, setAudioMuted] = useRecoilState(mutedState);

  return (
    <Tooltip text={audioMuted ? 'Turn on mic' : 'Turn off mic'}>
      <Pressable
        style={[
          roomPreviewButtonStyles.action,
          audioMuted ? roomPreviewButtonStyles.actionHighlight : null,
        ]}
        onPress={() => setAudioMuted(!audioMuted)}
      >
        <AudioIcon enabled={!audioMuted} />
      </Pressable>
    </Tooltip>
  );
}
