import _ from 'lodash';
import { LayoutRectangle } from 'react-native';
import { DefaultValue, selectorFamily } from 'recoil';
import groupPreviewRelativeLayoutsState from './groupPreviewRelativeLayoutsState';

const DEFAULT_GROUP_PREVIEW_RELATIVE_LAYOUT: LayoutRectangle = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

const groupPreviewRelativeLayoutState = selectorFamily<LayoutRectangle, string>(
  {
    key: 'groupPreviewRelativeLayoutState',
    get: (id) => ({ get }) => {
      const groupPreviewRelativeLayouts = get(groupPreviewRelativeLayoutsState);
      return (
        groupPreviewRelativeLayouts[id] ?? DEFAULT_GROUP_PREVIEW_RELATIVE_LAYOUT
      );
    },
    set: (id) => ({ get, set }, newValue) => {
      const groupPreviewRelativeLayouts = get(groupPreviewRelativeLayoutsState);
      if (newValue instanceof DefaultValue) {
        set(
          groupPreviewRelativeLayoutsState,
          _.omit(groupPreviewRelativeLayouts, id)
        );
      } else {
        set(groupPreviewRelativeLayoutsState, {
          ...groupPreviewRelativeLayouts,
          [id]: newValue,
        });
      }
    },
  }
);
export default groupPreviewRelativeLayoutState;
