import React, { useMemo } from 'react';
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleSheet,
  Text,
} from 'react-native';
import { DEFAULT_SPACING, GREEN } from '../config/constants';

interface Props extends PressableProps {
  title: string;
  isSubmitting?: boolean;
  color?: string;
}

export default function PrimaryButton({
  title,
  style,
  isSubmitting,
  disabled,
  color,
  ...restProps
}: Props) {
  const colorStyle = useMemo(
    () => ({
      backgroundColor: color ?? GREEN,
    }),
    [color]
  );

  const pressableStyle =
    typeof style === 'function'
      ? (state: PressableStateCallbackType) => [
          styles.button,
          colorStyle,
          disabled ? styles.disabled : null,
          style(state),
        ]
      : [styles.button, colorStyle, disabled ? styles.disabled : null, style];

  return (
    <Pressable
      style={pressableStyle}
      {...restProps}
      disabled={disabled || isSubmitting}
    >
      <Text style={styles.buttonText}>{title}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: DEFAULT_SPACING,
    borderRadius: 8,
    minHeight: DEFAULT_SPACING * 5,
    padding: DEFAULT_SPACING,
  },
  disabled: {
    backgroundColor: 'lightgrey',
    borderWidth: 0,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: DEFAULT_SPACING * 2,
  },
});
