import '@expo/match-media';
import { StatusBar } from 'expo-status-bar';
import React, { PropsWithChildren, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, LogBox, StyleSheet, Text } from 'react-native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from 'react-query';
import { RecoilRoot } from 'recoil';
import useAttemptNativeAppRedirect from '../hooks/useAttemptNativeAppRedirect';
import usePurchaseListener from '../hooks/usePurchaseListener';
import Connected from './Connected';
import LoadingLogo from './LoadingLogo';
import RequireProfile from './onboarding/RequireProfile';
import RecommendMobileApp from './RecommendMobileApp';
import StoopScreens from './StoopScreens';

LogBox.ignoreLogs(['Setting a timer']);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      suspense: true,
    },
  },
});

function PurchaseListenerConfig() {
  usePurchaseListener();
  return null;
}

export default function StoopRoot() {
  useAttemptNativeAppRedirect();

  return (
    <SafeAreaProvider>
      <RecoilRoot>
        <ReactQueryConfig>
          <Suspense fallback={<LoadingLogo />}>
            <RecommendMobileApp>
              <StatusBar style="auto" />
              <RequireProfile>
                <Connected>
                  <PurchaseListenerConfig />
                  <StoopScreens />
                </Connected>
              </RequireProfile>
            </RecommendMobileApp>
          </Suspense>
        </ReactQueryConfig>
      </RecoilRoot>
    </SafeAreaProvider>
  );
}

function ReactQueryConfig({ children }: PropsWithChildren<{}>) {
  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <SafeAreaView style={styles.errorView}>
                <Text>There was an error!</Text>
                <Button
                  title="Try again"
                  onPress={() => resetErrorBoundary()}
                />
              </SafeAreaView>
            )}
          >
            {children}
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </QueryClientProvider>
  );
}

const styles = StyleSheet.create({
  errorView: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
