import { AxiosResponse } from 'axios';
import b64toBlob from 'b64-to-blob';
import _ from 'lodash';
import AUTHORIZATION_STORE from '../models/AuthorizationStore';
import Api from './Api';

export interface UpdateProfileAttributes {
  name: string;
  avatarUri: string;
}
export interface Profile {
  name: string;
  avatarPath: string;
  guest: boolean;
  subscribed: boolean;
}

export async function getProfile() {
  const authentication = await AUTHORIZATION_STORE.getAsync();
  if (!authentication) {
    return null;
  }

  const response = await Api.get<{}, AxiosResponse<Profile>>('profile');

  if (response.status !== 200) {
    throw new Error('something went wrong!');
  }

  return response.data;
}

const BASE64_DELIMITER = ';base64,';
export async function updateProfile({
  name,
  avatarUri,
}: UpdateProfileAttributes) {
  const formData = new FormData();
  formData.append('profile[name]', name);

  if (_.includes(avatarUri, BASE64_DELIMITER)) {
    const avatarBase64Data = _.last(_.split(avatarUri, BASE64_DELIMITER));
    formData.append(
      'profile[avatar]',
      b64toBlob(avatarBase64Data, 'image/jpeg'),
      'avatar.jpg'
    );
  } else {
    formData.append('profile[avatar]', ({
      uri: avatarUri,
      type: 'image/jpeg',
      name: 'avatar.jpg',
    } as unknown) as Blob);
  }

  const response = await Api.patch<
    UpdateProfileAttributes,
    AxiosResponse<Profile>
  >('profile', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  const { data } = response;

  if (response.status !== 200) {
    console.error(response);
    throw 'something went wrong!';
  }

  return data;
}
