import React, { useContext } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import videoStyles from '../../../config/videoStyles';
import LocalParticipantVideoTrackContext from '../../../contexts/LocalParticipantVideoTrackContext';
import { videoTrackIsMirrored } from '../../../hooks/useLocalVideoTrack';
import useProfile, { profileAvatarSource } from '../../../hooks/useProfile';
import localMembershipState from '../../../state/localMembershipState';
import { VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE } from '../../../state/videoGridMembershipCellLayoutState';
import videoMutedState from '../../../state/videoMutedState';
import { LocalVideoTrackView } from '../../../twilio';
import RoundedCornersVideoOverlay from './RoundedCornersVideoOverlay';

const styles = StyleSheet.create({
  avatarImage: {
    width: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
    height: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
    borderRadius: DEFAULT_SPACING,
  },
});

export default function SelfVideo() {
  const localMembership = useRecoilValue(localMembershipState);
  const profile = useProfile();
  const videoMuted = useRecoilValue(videoMutedState);
  const videoTrack = useContext(LocalParticipantVideoTrackContext);
  const videoDisabled = videoMuted || !videoTrack;
  const mirror = videoDisabled ? false : videoTrackIsMirrored(videoTrack);

  return (
    <View style={videoStyles.root}>
      <RoundedCornersVideoOverlay membershipId={localMembership.id}>
        {videoDisabled ? (
          <View style={videoStyles.mutedVideoPlaceholder}>
            <Image
              resizeMode="cover"
              style={styles.avatarImage}
              source={profileAvatarSource(profile)}
            />
          </View>
        ) : (
          <LocalVideoTrackView
            localVideoTrack={videoTrack}
            scaleType="aspectFill"
            mirror={mirror}
            style={videoStyles.video}
          />
        )}
      </RoundedCornersVideoOverlay>
    </View>
  );
}
