import _ from 'lodash';
import React from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useRecoilValue } from 'recoil';
import { GRAY_ACTION, DEFAULT_SPACING } from '../../../config/constants';
import useNavigateBackOrHome from '../../../hooks/useNavigateBackOrHome';
import { profileAvatarSource } from '../../../hooks/useProfile';
import useRoomPreview from '../../../hooks/useRoomPreview';
import membershipIdState from '../../../state/membershipIdState';
import { CloseIcon } from '../../Icons';
import RoomPreviewFooter from './RoomPreviewFooter';
import VideoGrid from '../video_grid/VideoGrid';

export default function RoomPreview({
  onPressJoin,
}: {
  onPressJoin: () => void;
}) {
  const membershipId = useRecoilValue(membershipIdState);
  const navigateBackOrHome = useNavigateBackOrHome();

  const roomPreview = useRoomPreview(membershipId);
  const memberships = roomPreview?.memberships || [];
  const hasExtraMemberships = _.size(memberships) > 3;
  const membershipsToRender = hasExtraMemberships
    ? _.slice(memberships, 0, 2)
    : _.slice(memberships, 0, 3);
  const extraMembershipCount = hasExtraMemberships
    ? _.size(memberships) - 2
    : 0;

  return (
    <View style={styles.root}>
      <SafeAreaView edges={['top', 'right', 'left']} style={styles.content}>
        <View style={styles.topRow}>
          <View style={styles.topRowSideAction}>
            <Pressable onPress={navigateBackOrHome} style={styles.closeButton}>
              <CloseIcon color="white" />
            </Pressable>
          </View>
          <View style={styles.avatars}>
            {_.map(membershipsToRender, (membership) => (
              <Image
                key={membership.id}
                resizeMode="cover"
                style={styles.avatar}
                source={profileAvatarSource(membership)}
              />
            ))}
            {hasExtraMemberships && (
              <View style={styles.extraAvatars}>
                <Text style={styles.extraAvatarsText}>
                  +{extraMembershipCount}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.topRowSideAction} />
        </View>
        <Text style={styles.previewText}>
          {roomPreview?.description}. Ready to join?
        </Text>
        <VideoGrid />
      </SafeAreaView>
      <RoomPreviewFooter onPressJoin={onPressJoin} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: 'black',
    alignSelf: 'stretch',
  },
  closeButton: {
    padding: DEFAULT_SPACING * 4,
  },
  content: {
    flex: 1,
    alignItems: 'stretch',
    marginBottom: DEFAULT_SPACING * 2,
  },
  topRow: {
    flexDirection: 'row',
    alignContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  topRowSideAction: {
    width: DEFAULT_SPACING * 3,
  },
  preview: {
    marginVertical: DEFAULT_SPACING,
  },
  avatars: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: DEFAULT_SPACING,
    flex: 1,
  },
  avatar: {
    height: DEFAULT_SPACING * 6,
    width: DEFAULT_SPACING * 6,
    borderRadius: DEFAULT_SPACING,
    backgroundColor: 'white',
    marginHorizontal: DEFAULT_SPACING / 2,
  },
  extraAvatars: {
    height: DEFAULT_SPACING * 6,
    minWidth: DEFAULT_SPACING * 6,
    paddingHorizontal: DEFAULT_SPACING,
    marginHorizontal: DEFAULT_SPACING / 2,
    borderRadius: DEFAULT_SPACING,
    backgroundColor: GRAY_ACTION,
    justifyContent: 'center',
    alignItems: 'center',
  },
  extraAvatarsText: {
    color: 'white',
  },
  previewText: {
    color: 'white',
    fontSize: DEFAULT_SPACING * 2.5,
    fontWeight: 'bold',
    textAlign: 'center',
    marginHorizontal: DEFAULT_SPACING * 10,
    marginBottom: DEFAULT_SPACING * 2,
  },
});
