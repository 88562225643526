import _ from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { StyleSheet, Text } from 'react-native';
import { useRecoilValue } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import localMembershipDepartureState from '../../../state/localMembershipDepartureState';
import matchmakingState from '../../../state/matchmakingState';
import roomState from '../../../state/roomState';
import selectedRemoteMembershipIdsState from '../../../state/selectedRemoteMembershipIdsState';
import TimedProgressBarBackground from '../TimedProgressBarBackground';
import GroupListItem from './GroupListItem';
import GroupListItemActionButton from './GroupListItemActionButton';
import GroupListItemSummarizedMemberships from './GroupListItemSummarizedMemberships';

const styles = StyleSheet.create({
  membership: {
    marginRight: DEFAULT_SPACING,
  },
  membershipText: {
    color: 'white',
  },
});

export default function MatchmakingButton() {
  const roomSubscription = useContext(RoomSubscriptionContext);
  const matchmaking = useRecoilValue(matchmakingState);

  const departure = useRecoilValue(localMembershipDepartureState);
  const selectedRemoteMembershipIds = useRecoilValue(
    selectedRemoteMembershipIdsState
  );

  const {
    matchmakingMembershipsCount,
    matchmakingGracePeriodStartedAt,
    matchmakingWillCompleteAt,
  } = useRecoilValue(roomState);
  const memberships = useMemo(() => _.range(matchmakingMembershipsCount), [
    matchmakingMembershipsCount,
  ]);

  const enabled = !departure && _.isEmpty(selectedRemoteMembershipIds);

  const onPress = useCallback(
    () => roomSubscription.perform('start_matchmaking'),
    [roomSubscription]
  );

  return (
    <GroupListItem
      title="Mix things up"
      onPress={enabled ? onPress : null}
      highlighted={matchmaking}
    >
      {matchmakingGracePeriodStartedAt && matchmakingWillCompleteAt && (
        <TimedProgressBarBackground
          startAt={matchmakingGracePeriodStartedAt}
          endAt={matchmakingWillCompleteAt}
          borderRadius={DEFAULT_SPACING}
        />
      )}
      <GroupListItemSummarizedMemberships
        memberships={memberships}
        render={(index) => (
          <GroupListItemActionButton
            highlighted={matchmaking}
            style={styles.membership}
            key={index}
          >
            <Text style={styles.membershipText}>?</Text>
          </GroupListItemActionButton>
        )}
        after={
          !matchmaking && (
            <GroupListItemActionButton highlighted={matchmaking}>
              <Text>🎲</Text>
            </GroupListItemActionButton>
          )
        }
      />
    </GroupListItem>
  );
}
