import { selector } from 'recoil';
import videoGridDepartureCellsCountState from './videoGridDepartureCellsCountState';
import videoGridMembershipCellsCountState from './videoGridMembershipCellsCountState';

const videoGridCellsCountState = selector({
  key: 'videoGridCellsCountState',
  get: ({ get }) => {
    const videoGridMembershipCellsCount = get(
      videoGridMembershipCellsCountState
    );
    const videoGridDepartureCellsCount = get(videoGridDepartureCellsCountState);

    return videoGridMembershipCellsCount + videoGridDepartureCellsCount;
  },
});
export default videoGridCellsCountState;
