import _ from 'lodash';
import { useCallback } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { Notification } from './nextStableNotificationState';

const pastNotificationsState = atom<Notification[]>({
  key: 'pastNotificationsState',
  default: [],
});
export default pastNotificationsState;

function notificationCanBeDismissed(notification: Notification) {
  return _.some(notification.notificationEvents);
}

export function useDismissNotification() {
  const setPastNotifications = useSetRecoilState(pastNotificationsState);
  return useCallback(
    (notification: Notification) => {
      if (!notificationCanBeDismissed(notification)) {
        return;
      }
      setPastNotifications((pastNotifications) =>
        _.uniqWith([...pastNotifications, notification], _.isEqual)
      );
    },
    [setPastNotifications]
  );
}
