import { findLocalAudioTrack } from './LocalAudioTrack';
import { findLocalVideoTrack } from './LocalVideoTrack';

export function findLocalTrack({
  kind,
  name,
}: {
  kind: 'audio' | 'video' | 'data';
  name: string;
}) {
  if (kind === 'audio') {
    return findLocalAudioTrack(name);
  } else if (kind === 'video') {
    return findLocalVideoTrack(name);
  } else {
    return null;
  }
}
