import { selector } from 'recoil';
import config from '../config/config';
import roomIdState from './roomIdState';

const roomUrlState = selector({
  key: 'roomUrlState',
  get: ({ get }) => {
    return `${config().appUrl}/rooms/${get(roomIdState)}`;
  },
});
export default roomUrlState;
