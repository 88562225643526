import { AxiosResponse } from 'axios';
import Api from './Api';

export async function getRoomExists(roomId: string) {
  try {
    const response = await Api.get<{}, AxiosResponse>(`rooms/${roomId}`);
    return response.status === 200;
  } catch {
    return false;
  }
}
