import _ from 'lodash';
import { selectorFamily } from 'recoil';
import groupListLayoutState from './groupListLayoutState';
import groupListScrollState from './groupListScrollState';
import groupPreviewRelativeLayoutState from './groupPreviewRelativeLayoutState';

interface GroupPreviewEmojiReactionPosition {
  minX: number;
  maxX: number;
  directionCenter?: number;
  directionRange?: number;
}

const groupPreviewEmojiReactionPositionState = selectorFamily<
  GroupPreviewEmojiReactionPosition,
  string
>({
  key: 'groupPreviewEmojiReactionPositionState',
  get: (id) => ({ get }) => {
    const groupListScroll = get(groupListScrollState);
    const groupListLayout = get(groupListLayoutState);
    const groupPreviewRelativeLayout = get(groupPreviewRelativeLayoutState(id));

    const groupPreviewMinX =
      groupPreviewRelativeLayout.x - groupListScroll.contentOffset.x;
    const groupPreviewMaxX =
      groupPreviewMinX + groupPreviewRelativeLayout.width;

    if (groupPreviewMaxX < groupListLayout.x) {
      return {
        minX: 0,
        maxX: 0,
        directionCenter: Math.PI * 1.75,
        directionRange: Math.PI * 0.25,
      };
    }
    if (groupPreviewMinX > groupListLayout.x + groupListLayout.width) {
      const x = groupListLayout.x + groupListLayout.width;
      return {
        minX: x,
        maxX: x,
        directionCenter: Math.PI * 1.25,
        directionRange: Math.PI * 0.25,
      };
    }

    const minX = _.max([groupPreviewMinX, groupListLayout.x]);
    const maxX = _.min([
      groupPreviewMaxX,
      groupListLayout.x + groupListLayout.width,
    ]);

    return {
      minX,
      maxX,
    };
  },
});
export default groupPreviewEmojiReactionPositionState;
