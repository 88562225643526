import _ from 'lodash';
import { DefaultValue, selector } from 'recoil';
import draftProfileState from './draftProfileState';

const draftProfileNameState = selector<string>({
  key: 'draftProfileNameState',
  get: ({ get }) => {
    return get(draftProfileState).name;
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(draftProfileState, _.omit(get(draftProfileState), 'name'));
    } else {
      set(draftProfileState, { ...get(draftProfileState), name: newValue });
    }
  },
});
export default draftProfileNameState;
