import { useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { homeScreenActionsButtonStyles } from '../../config/homeScreenActionsButtonStyles';
import useRoomPreview from '../../hooks/useRoomPreview';
import { JoinRecentRoomIcon } from '../Icons';
import HomeScreenButton from './HomeScreenButton';

const styles = StyleSheet.create({
  nameText: {
    fontWeight: 'bold',
  },
});

function Name({ name }: { name: string }) {
  return <Text style={styles.nameText}>{name}</Text>;
}

export default function SuggestedRoomButton({
  membershipId,
}: {
  membershipId: string;
}) {
  const roomPreview = useRoomPreview(membershipId);
  const { navigate } = useNavigation();

  if (!roomPreview || _.isEmpty(roomPreview.memberships)) {
    return null;
  }

  return (
    <HomeScreenButton
      icon={<JoinRecentRoomIcon />}
      onPress={() => navigate('Room', { roomId: roomPreview.id })}
    >
      <Text style={homeScreenActionsButtonStyles.buttonText}>
        Join the room with{' '}
        {_.size(roomPreview.memberships) === 1 ? (
          <Name name={_.first(roomPreview.memberships).name} />
        ) : _.size(roomPreview.memberships) === 2 ? (
          <>
            <Name name={roomPreview.memberships[0].name} /> and{' '}
            <Name name={roomPreview.memberships[1].name} />
          </>
        ) : _.size(roomPreview.memberships) === 3 ? (
          <>
            <Name name={roomPreview.memberships[0].name} />,{' '}
            <Name name={roomPreview.memberships[1].name} />, and{' '}
            <Name name={roomPreview.memberships[2].name} />
          </>
        ) : (
          <>
            <Name name={roomPreview.memberships[0].name} />,{' '}
            <Name name={roomPreview.memberships[1].name} />, and{' '}
            {pluralize('other', _.size(roomPreview.memberships) - 2, true)}
          </>
        )}
      </Text>
    </HomeScreenButton>
  );
}
