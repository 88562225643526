import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { useMutation } from 'react-query';
import { DEFAULT_SPACING } from '../../config/constants';
import stripePromise from '../../config/stripePromise';
import { createStripeSubscription } from '../../config/stripeSubscriptionApi';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import useAvailableMonthlySubscription from '../../hooks/useAvailableMonthlySubscription';
import { useInvalidateMonthlySubscription } from '../../hooks/useMonthlySubscription';
import { useInvalidateProfile } from '../../hooks/useProfile';
import Header from '../Header';
import PrimaryButton from '../PrimaryButton';
import ResponsiveContainer from '../ResponsiveContainer';
import WorkflowNavigationHeader from '../workflows/WorkflowNavigationHeader';
import SubscriptionCard from './SubscriptionCard';

function SubscriptionFormWithoutElements() {
  const stripe = useStripe();
  const elements = useElements();
  const subscription = useAvailableMonthlySubscription();

  const invalidateProfile = useInvalidateProfile();
  const invalidateMonthlySubscription = useInvalidateMonthlySubscription();

  const { mutateAsync, isLoading } = useMutation(
    async () => {
      const stripePaymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (stripePaymentMethod.error) {
        return Promise.reject(stripePaymentMethod.error);
      }

      return createStripeSubscription({
        stripePaymentMethodId: stripePaymentMethod.paymentMethod.id,
        stripePriceId: subscription.productId,
      });
    },
    {
      onSuccess: () => {
        invalidateProfile();
        invalidateMonthlySubscription();
      },
    }
  );

  if (!subscription) {
    return null;
  }

  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <WorkflowNavigationHeader />
      <View style={workflowScreenStyles.content}>
        <ResponsiveContainer>
          <View style={workflowScreenStyles.group}>
            <Header text="Subscribe to Stoop" />
            <SubscriptionCard />
            <View style={styles.card}>
              <CardElement />
            </View>
          </View>
          <View style={workflowScreenStyles.actions}>
            <PrimaryButton
              title={isLoading ? 'Subscribing...' : 'Subscribe'}
              onPress={() => mutateAsync()}
              isSubmitting={isLoading}
            />
          </View>
        </ResponsiveContainer>
      </View>
    </SafeAreaView>
  );
}

export default function SubscriptionForm() {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionFormWithoutElements />
    </Elements>
  );
}

const styles = StyleSheet.create({
  card: {
    marginTop: DEFAULT_SPACING * 3,
    marginBottom: DEFAULT_SPACING * 2,
    alignSelf: 'stretch',
    borderColor: '#9D9D9D',
    borderRadius: DEFAULT_SPACING,
    borderWidth: 1,
    padding: DEFAULT_SPACING,
  },
});
