import React, { PropsWithChildren } from 'react';
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import {
  BARELY_TRANSPARENT_GRAY,
  DEFAULT_SPACING,
  NEAR_BLACK,
} from '../config/constants';
import { CloseIcon } from './Icons';
import { useHotkeys } from 'react-hotkeys-hook';

interface Props {
  visible?: boolean;
  maxWidth?: number;
  title: string;
  onClose: () => void;
}

export default function WebModal({
  visible,
  title,
  maxWidth,
  onClose,
  children,
}: PropsWithChildren<Props>) {
  useHotkeys('esc', () => onClose(), [onClose]);
  return (
    <Modal
      key={title}
      animationType="fade"
      transparent={true}
      visible={visible}
    >
      <Pressable
        style={styles.centeredView}
        onPress={onClose}
        focusable={false}
      >
        <Pressable
          style={[styles.modalView, maxWidth ? { flexBasis: maxWidth } : null]}
          onPress={(e) => e.stopPropagation()}
          focusable={false}
        >
          <View style={styles.header}>
            <Text style={styles.title}>{title}</Text>
            <TouchableOpacity style={styles.closeButton} onPress={onClose}>
              <CloseIcon />
            </TouchableOpacity>
          </View>
          <View style={styles.line} />
          {children}
        </Pressable>
      </Pressable>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: BARELY_TRANSPARENT_GRAY,
  },
  modalView: {
    margin: DEFAULT_SPACING * 2,
    flexBasis: 600,
    flexShrink: 1,
    backgroundColor: 'white',
    borderRadius: DEFAULT_SPACING,
    padding: DEFAULT_SPACING * 4,
  },
  header: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: NEAR_BLACK,
    fontSize: DEFAULT_SPACING * 2.5,
    fontWeight: 'bold',
  },
  closeButton: {
    padding: DEFAULT_SPACING,
  },
  line: {
    backgroundColor: NEAR_BLACK,
    height: 1,
    width: '100%',
    marginVertical: DEFAULT_SPACING * 2,
  },
});
