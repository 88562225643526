import React, { useRef } from 'react';
import { StyleSheet, Text } from 'react-native';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import pendingEmojiReactionState from '../../../../state/pendingEmojiReactionState';
import videoGridMembershipCellLayoutState from '../../../../state/videoGridMembershipCellLayoutState';
import FloatingEffect, { centeredRandom } from './FloatingEffect';

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  text: {
    fontSize: 24,
  },
});

const INITIAL_POSITION_CENTER = 0.5;
const INITIAL_POSITION_RANGE = 0.5;

export default function MembershipEmojiReaction({ id }: { id: string }) {
  const pendingEmojiReaction = useRecoilValue(pendingEmojiReactionState(id));
  const videoGridMembershipCellLayout = useRecoilValue(
    videoGridMembershipCellLayoutState(pendingEmojiReaction.membershipId)
  );

  const resetEmojiReaction = useResetRecoilState(pendingEmojiReactionState(id));

  const initialOffsetX = useRef(videoGridMembershipCellLayout?.x ?? 0).current;
  const initialOffsetY = useRef(videoGridMembershipCellLayout?.y ?? 0).current;
  const initialWidth = useRef(videoGridMembershipCellLayout?.width ?? 100)
    .current;
  const initialHeight = useRef(videoGridMembershipCellLayout?.height ?? 100)
    .current;

  const fromX = useRef(
    centeredRandom(
      INITIAL_POSITION_CENTER * initialWidth + initialOffsetX,
      INITIAL_POSITION_RANGE * initialWidth
    )
  ).current;

  const fromY = useRef(
    centeredRandom(
      INITIAL_POSITION_CENTER * initialHeight + initialOffsetY,
      INITIAL_POSITION_RANGE * initialHeight
    )
  ).current;

  return (
    <FloatingEffect
      fromX={fromX}
      fromY={fromY}
      meanDistance={initialWidth * 0.5}
      delay={pendingEmojiReaction.pendingDuration * 1000}
      onAnimationFinished={resetEmojiReaction}
      style={styles.root}
    >
      <Text style={styles.text}>{pendingEmojiReaction.emoji}</Text>
    </FloatingEffect>
  );
}
