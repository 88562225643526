import { useFormikContext } from 'formik';
import React, { Ref } from 'react';
import {
  KeyboardTypeOptions,
  ReturnKeyTypeOptions,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { DEFAULT_SPACING, GRAY, isWeb, RED } from '../config/constants';

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  returnKeyType?: ReturnKeyTypeOptions;
  onSubmitEditing?: () => void;
  secureTextEntry?: boolean;
  keyboardType?: KeyboardTypeOptions;
  innerRef?: Ref<TextInput>;
}

export default function TextField({
  name,
  label,
  placeholder,
  returnKeyType,
  onSubmitEditing,
  innerRef,
  secureTextEntry,
  keyboardType,
}: Props) {
  secureTextEntry = secureTextEntry ?? false;

  const { values, errors, handleBlur, handleChange } = useFormikContext();

  return (
    <View style={styles.root}>
      {errors[name] ? (
        <Text style={[styles.label, styles.labelWithError]}>
          {errors[name]}
        </Text>
      ) : (
        <Text style={styles.label}>{label}</Text>
      )}
      <TextInput
        style={[
          styles.input,
          isWeb ? ({ userSelect: 'auto' } as unknown) : null,
        ]}
        placeholder={placeholder}
        placeholderTextColor="#626262"
        value={values[name]}
        onChangeText={handleChange(name)}
        onBlur={handleBlur(name)}
        onSubmitEditing={onSubmitEditing}
        secureTextEntry={secureTextEntry}
        returnKeyType={returnKeyType}
        keyboardType={keyboardType}
        ref={innerRef}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
    marginBottom: DEFAULT_SPACING * 2,
  },
  label: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
    color: GRAY,
    fontSize: 12,
  },
  labelWithError: {
    color: RED,
  },
  input: {
    borderRadius: 8,
    paddingVertical: DEFAULT_SPACING,
    paddingHorizontal: DEFAULT_SPACING * 2,
    marginVertical: DEFAULT_SPACING,
    height: DEFAULT_SPACING * 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'grey',
  },
});
