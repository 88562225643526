import { useEffect, useMemo, useRef } from 'react';
import { Animated } from 'react-native';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { Frame } from '../state/roomLayoutState';
import { VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE } from '../state/videoGridMembershipCellLayoutState';

export default function useVideoGridCellAnimatedLayoutStyle(
  layoutState: RecoilValueReadOnly<Frame>
) {
  const layout = useRecoilValue(layoutState);

  const translate = useRef<Animated.ValueXY>(
    new Animated.ValueXY({ x: 0, y: 0 })
  ).current;
  const scale = useRef<Animated.ValueXY>(new Animated.ValueXY({ x: 1, y: 1 }))
    .current;

  useEffect(() => {
    Animated.parallel([
      Animated.timing(translate, {
        toValue: {
          x:
            layout.x -
            (VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE - layout.width) / 2,
          y:
            layout.y -
            (VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE - layout.height) / 2,
        },
        duration: 200,
        useNativeDriver: true,
      }),
      Animated.timing(scale, {
        toValue: {
          x: layout.width / VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
          y: layout.height / VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
        },
        duration: 200,
        useNativeDriver: true,
      }),
    ]).start();
  }, [layout, translate, scale]);

  const layoutStyle = useMemo(
    () => ({
      transform: [
        { translateX: translate.x },
        { translateY: translate.y },
        { scaleX: scale.x },
        { scaleY: scale.y },
      ],
    }),
    [translate, scale]
  );

  return layoutStyle;
}
