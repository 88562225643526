import React from 'react';
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleSheet,
  Text,
} from 'react-native';
import { DEFAULT_SPACING, GREEN } from '../config/constants';

interface Props extends PressableProps {
  title: string;
}

export default function Link({ title, style, ...restProps }: Props) {
  const pressableStyle =
    typeof style === 'function'
      ? (state: PressableStateCallbackType) => [styles.button, style(state)]
      : [styles.button, style];

  return (
    <Pressable style={pressableStyle} {...restProps}>
      <Text style={styles.buttonText}>{title}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: DEFAULT_SPACING,
    minHeight: DEFAULT_SPACING * 5,
  },
  buttonText: {
    color: GREEN,
    fontWeight: 'bold',
    fontSize: DEFAULT_SPACING * 2,
  },
});
