import _ from 'lodash';
import { selectorFamily } from 'recoil';
import groupSortedMembershipIdsState from './groupSortedMembershipIdsState';
import localMembershipState from './localMembershipState';
import { Membership } from './membershipState';
import remoteMembershipState from './remoteMembershipState';

const groupSortedMembershipsState = selectorFamily<Membership[], string>({
  key: 'groupSortedMembershipsState',
  get: (id: string) => {
    return ({ get }) => {
      const localMembership = get(localMembershipState);
      const sortedMembershipIds = get(groupSortedMembershipIdsState(id));

      return _.map(sortedMembershipIds, (membershipId) => {
        if (membershipId === localMembership.id) {
          return localMembership;
        } else {
          return get(remoteMembershipState(membershipId));
        }
      });
    };
  },
});
export default groupSortedMembershipsState;
