import { selector } from 'recoil';
import roomLayoutState, { Frame } from './roomLayoutState';
import { EMPTY_FRAME } from './videoGridCellLayoutByCellIndexState';

const roomScreenSharingVideoLayoutState = selector<Frame>({
  key: 'roomScreenSharingVideoLayoutState',
  get: ({ get }) => {
    return get(roomLayoutState).screenSharingVideoFrame ?? EMPTY_FRAME;
  },
});
export default roomScreenSharingVideoLayoutState;
