import _ from 'lodash';
import { selectorFamily } from 'recoil';
import departuresState from './departuresState';
import { Departure } from './departureState';
import membershipState from './membershipState';

const membershipDepartureState = selectorFamily<Departure | null, string>({
  key: 'membershipDepartureState',
  get: (id) => ({ get }) => {
    const membership = get(membershipState(id));
    return _.find(
      get(departuresState),
      ({ groupId, membershipIds }) =>
        _.includes(membershipIds, id) && membership.groupId === groupId
    );
  },
});
export default membershipDepartureState;
