import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { Pressable, Text } from 'react-native';
import { useRecoilValue } from 'recoil';
import { isWeb } from '../../../config/constants';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import useVideoGridCellScaledStyle from '../../../hooks/useVideoGridCellScaledStyle';
import groupIdState from '../../../state/groupIdState';
import membershipIdState from '../../../state/membershipIdState';
import { useAddPendingEmojiReaction } from '../../../state/pendingEmojiReactionsState';
import { PendingEmojiReaction } from '../../../state/pendingEmojiReactionState';

let nextLocalEmojiReactionId = 1;

function buildLocalPendingEmojiReaction(
  overrides: Partial<PendingEmojiReaction> & {
    emoji: string;
    membershipId: string;
    groupId: string;
  }
) {
  nextLocalEmojiReactionId += 1;
  return {
    id: `local-${nextLocalEmojiReactionId}`,
    createdAt: moment().toISOString(),
    pendingDuration: 0,
    ...overrides,
  };
}

interface Props {
  emoji: string;
  disabled: boolean;
}

export default function EmojiReactionsMenuButton({ emoji, disabled }: Props) {
  const roomSubscription = useContext(RoomSubscriptionContext);
  const addPendingEmojiReaction = useAddPendingEmojiReaction();
  const membershipId = useRecoilValue(membershipIdState);
  const groupId = useRecoilValue(groupIdState);
  const onPress = useCallback(() => {
    roomSubscription.perform('create_emoji_reaction', {
      emojiReaction: { emoji },
    });
    addPendingEmojiReaction(
      buildLocalPendingEmojiReaction({
        emoji,
        membershipId,
        groupId,
      })
    );
  }, [roomSubscription, emoji, addPendingEmojiReaction, membershipId, groupId]);

  const scaledTextStyle = useVideoGridCellScaledStyle(
    { fontSize: 24 },
    membershipId
  );

  return (
    <Pressable onPress={disabled ? null : onPress}>
      <Text
        style={[
          scaledTextStyle,
          isWeb ? ({ userSelect: 'none' } as unknown) : null,
        ]}
      >
        {emoji}
      </Text>
    </Pressable>
  );
}
