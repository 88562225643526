import React from 'react';
import { Image, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import videoStyles from '../../../config/videoStyles';
import { videoTrackIsMirrored } from '../../../hooks/useLocalVideoTrack';
import { profileAvatarSource } from '../../../hooks/useProfile';
import useRemoteConnection from '../../../hooks/useRemoteConnection';
import remoteMembershipState from '../../../state/remoteMembershipState';
import { VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE } from '../../../state/videoGridMembershipCellLayoutState';
import { RemoteVideoTrackView } from '../../../twilio';
import RoundedCornersVideoOverlay from './RoundedCornersVideoOverlay';

export default function RemoteMembershipVideo({ id }: { id: string }) {
  const remoteConnection = useRemoteConnection(id);
  const remoteMembership = useRecoilValue(remoteMembershipState(id));
  const videoTrack = remoteConnection?.videoTrack;
  const videoDisabled =
    remoteMembership.videoMuted || !videoTrack || videoTrack?.isSwitchedOff;
  const mirror = videoDisabled ? false : videoTrackIsMirrored(videoTrack);

  return (
    <View style={videoStyles.root}>
      <RoundedCornersVideoOverlay membershipId={id}>
        {videoDisabled ? (
          <View style={videoStyles.mutedVideoPlaceholder}>
            <Image
              resizeMode="cover"
              style={{
                width: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
                height: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
              }}
              source={profileAvatarSource(remoteMembership)}
            />
          </View>
        ) : (
          <RemoteVideoTrackView
            remoteVideoTrack={videoTrack}
            scaleType="aspectFill"
            mirror={mirror}
            style={videoStyles.video}
          />
        )}
      </RoundedCornersVideoOverlay>
    </View>
  );
}
