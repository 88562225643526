import { AxiosResponse } from 'axios';
import Api from './Api';

interface AvailableStripeSubscription {
  description: string;
  price: string;
  priceAmountMicros: number;
  priceCurrencyCode: string;
  productId: string;
  title: string;
  subscriptionPeriod: string;
}

export async function indexAvailableStripeSubscriptions() {
  const { data } = await Api.get<
    {},
    AxiosResponse<AvailableStripeSubscription[]>
  >('available_stripe_subscriptions');
  return data;
}
