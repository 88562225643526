import { RouteProp, useRoute } from '@react-navigation/native';
import React, { Suspense, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import useNavigateBackOrHome from '../../hooks/useNavigateBackOrHome';
import roomIdState from '../../state/roomIdState';
import LoadingLogo from '../LoadingLogo';
import RequireCameraPermission from '../RequireCameraPermission';
import RequireMicrophonePermission from '../RequireMicrophonePermission';
import JoinedRoom from './joined_room/JoinedRoom';
import LocalParticipantVideoTrackProvider from './LocalParticipantVideoTrackProvider';
import RoomPreview from './room_preview/RoomPreview';

type RouteParams = {
  RoomScreen: {
    roomId: string;
  };
};

export default function RoomScreen() {
  const route = useRoute<RouteProp<RouteParams, 'RoomScreen'>>();
  const {
    params: { roomId },
  } = route;

  const [recoilRoomId, setRoomId] = useRecoilState(roomIdState);
  const resetRoomId = useResetRecoilState(roomIdState);
  useEffect(() => {
    setRoomId(roomId);
    return resetRoomId;
  }, [roomId, setRoomId, resetRoomId]);

  const [isJoined, setIsJoined] = useState<boolean>(false);

  const navigateBackOrHome = useNavigateBackOrHome();

  if (!recoilRoomId) {
    return <LoadingLogo />;
  }

  return (
    <Suspense fallback={<LoadingLogo />}>
      <RequireCameraPermission onBackPressed={navigateBackOrHome}>
        <LocalParticipantVideoTrackProvider>
          <RequireMicrophonePermission onBackPressed={navigateBackOrHome}>
            {isJoined ? (
              <JoinedRoom />
            ) : (
              <RoomPreview onPressJoin={() => setIsJoined(true)} />
            )}
          </RequireMicrophonePermission>
        </LocalParticipantVideoTrackProvider>
      </RequireCameraPermission>
    </Suspense>
  );
}
