import { Platform } from 'react-native';

export const DEFAULT_SPACING = 8;

export const GREEN = '#3E963C';
export const GREEN_HIGHLIGHT_ACTION = '#65AB63';
export const GREEN_SECONDARY_ACTION = '#256424';

export const NEAR_BLACK = '#141414';
export const GRAY = '#2D2D2D';
export const BARELY_TRANSPARENT_GRAY = 'rgba(56,56,56,0.8)';
export const GRAY_ACTION = '#262626';
export const OFF_WHITE = '#F7F7F7';
export const WHITE_TINT = 'rgba(255,255,255,0.1)';

export const YELLOW = '#F9D887';
export const RED = '#C00101';

export const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';

export const SCREEN_SHARING_TRACK_NAME_PREFIX = 'screen-sharing';
