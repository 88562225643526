import Video from 'twilio-video';
import LocalAudioTrack, { findLocalAudioTrack } from './LocalAudioTrack';

export default class LocalAudioTrackPublication {
  twilioLocalAudioTrackPublication: Video.LocalAudioTrackPublication;
  localTrack: LocalAudioTrack;

  constructor(
    twilioLocalAudioTrackPublication: Video.LocalAudioTrackPublication
  ) {
    this.twilioLocalAudioTrackPublication = twilioLocalAudioTrackPublication;
    this.localTrack = findLocalAudioTrack(
      twilioLocalAudioTrackPublication.trackName
    );
  }

  get trackName(): string {
    return this.twilioLocalAudioTrackPublication.trackName;
  }
}
