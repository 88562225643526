import copy from 'copy-to-clipboard';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import roomUrlState from '../../../state/roomUrlState';
import { CopyIcon } from '../../Icons';
import Tooltip from '../../Tooltip';
import WebModal from '../../WebModal';

export default function WebShareModal({
  visible = false,
  onClose,
}: {
  visible?: boolean;
  onClose: () => void;
}) {
  const roomUrl = useRecoilValue(roomUrlState);

  return (
    <WebModal title="Share" visible={visible} onClose={onClose}>
      <View style={styles.buttonContainer}>
        <Tooltip text="Copy">
          <TouchableOpacity
            style={styles.copyButton}
            onPress={() => copy(roomUrl)}
          >
            <Text style={styles.copyButtonText}>Room link</Text>
            <CopyIcon />
          </TouchableOpacity>
        </Tooltip>
      </View>
      <Text style={styles.urlText}>{roomUrl}</Text>
    </WebModal>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginRight: 'auto',
  },
  copyButton: {
    flexDirection: 'row',
  },
  copyButtonText: {
    marginRight: DEFAULT_SPACING,
    fontSize: DEFAULT_SPACING * 2,
    fontWeight: 'bold',
  },
  urlText: {
    marginTop: DEFAULT_SPACING,
    fontSize: DEFAULT_SPACING * 2,
  },
});
