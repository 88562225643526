import { Formik } from 'formik';
import React, { useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import draftProfileNameState from '../../state/draftProfileNameState';
import Header from '../Header';
import KeyboardDismissingBackground from '../KeyboardDismissingBackground';
import PrimaryButton from '../PrimaryButton';
import ResponsiveContainer from '../ResponsiveContainer';
import TextField from '../TextField';
import WorkflowNavigationHeader from '../workflows/WorkflowNavigationHeader';

interface FormData {
  name: string;
}

const nameValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
});

export default function DraftProfileNameForm({
  onBackPressed,
}: {
  onBackPressed?: () => void;
}) {
  const [name, setName] = useState('');
  const setDraftProfileName = useSetRecoilState(draftProfileNameState);

  const onSubmit = (formData: FormData) => {
    setName(formData.name);
    setDraftProfileName(formData.name);
  };

  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <WorkflowNavigationHeader onBackPressed={onBackPressed} />
      <KeyboardDismissingBackground style={workflowScreenStyles.content}>
        <Formik<FormData>
          validationSchema={nameValidationSchema}
          validateOnBlur={false}
          initialValues={{ name }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <React.Fragment>
              <ResponsiveContainer>
                <View style={workflowScreenStyles.group}>
                  <Header text="What should we call you?" />
                  <TextField
                    name="name"
                    label="Name"
                    onSubmitEditing={handleSubmit}
                    returnKeyType="done"
                  />
                </View>
                <View style={workflowScreenStyles.actions}>
                  <PrimaryButton
                    title="Continue"
                    onPress={() => handleSubmit()}
                  />
                </View>
              </ResponsiveContainer>
            </React.Fragment>
          )}
        </Formik>
      </KeyboardDismissingBackground>
    </SafeAreaView>
  );
}
