import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import AUTHORIZATION_STORE from '../models/AuthorizationStore';
import useRerender from './useRerender';

export default function useAuthorization() {
  const rerender = useRerender();
  useEffect(() => {
    const subscription = AUTHORIZATION_STORE.subscribe(rerender);
    return () => AUTHORIZATION_STORE.unsubscribe(subscription);
  }, [rerender]);

  AUTHORIZATION_STORE.throwSuspenseIfLoading();
  return AUTHORIZATION_STORE.authorization;
}

export function useClearAuthorization() {
  const queryClient = useQueryClient();
  return () => {
    AUTHORIZATION_STORE.delete();
    queryClient.invalidateQueries();
  };
}
