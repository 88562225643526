import { Formik } from 'formik';
import React, { useRef } from 'react';
import { SafeAreaView, Text, TextInput, View } from 'react-native';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useInvalidateApiQueries } from '../../config/Api';
import {
  createGuestUserUpgrade,
  GuestUserUpgradeCreateParams,
} from '../../config/guestUserUpgradeApi';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import useIsKeyboardVisible from '../../hooks/useIsKeyboardVisible';
import Header from '../Header';
import KeyboardDismissingBackground from '../KeyboardDismissingBackground';
import PrimaryButton from '../PrimaryButton';
import ResponsiveContainer from '../ResponsiveContainer';
import SecondaryButton from '../SecondaryButton';
import TextField from '../TextField';
import WorkflowNavigationHeader from '../workflows/WorkflowNavigationHeader';

const guestUserUpgradeValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(10, 'Password is too short'),
  passwordConfirmation: yup
    .string()
    .required('Password confirmation is required')
    .equals([yup.ref('password')], 'Password confirmation must match password'),
});

interface Props {
  onSignInPressed: () => void;
}

export default function GuestUserUpgradeForm({ onSignInPressed }: Props) {
  const invalidateApiQueries = useInvalidateApiQueries();
  const { mutateAsync, isLoading, isError, error } = useMutation(
    async (params: GuestUserUpgradeCreateParams) => {
      return createGuestUserUpgrade(params);
    },
    {
      onSuccess: invalidateApiQueries,
    }
  );

  const passwordRef = useRef<TextInput>();
  const passwordConfirmationRef = useRef<TextInput>();

  const isKeyboardVisible = useIsKeyboardVisible();

  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <WorkflowNavigationHeader />
      <KeyboardDismissingBackground style={workflowScreenStyles.content}>
        <Formik<GuestUserUpgradeCreateParams>
          validationSchema={guestUserUpgradeValidationSchema}
          validateOnBlur={false}
          initialValues={{
            email: '',
            password: '',
            passwordConfirmation: '',
          }}
          onSubmit={(values) => mutateAsync(values)}
        >
          {({ handleSubmit }) => (
            <ResponsiveContainer>
              <View style={workflowScreenStyles.group}>
                {!isKeyboardVisible && <Header text="Sign Up" />}
                {isError && (
                  <Text style={workflowScreenStyles.errorText}>{error}</Text>
                )}
                <TextField
                  name="email"
                  label="Email"
                  keyboardType="email-address"
                  returnKeyType="next"
                  onSubmitEditing={() => passwordRef.current.focus()}
                />
                <TextField
                  innerRef={passwordRef}
                  name="password"
                  label="Password"
                  secureTextEntry={true}
                  returnKeyType="next"
                  onSubmitEditing={() =>
                    passwordConfirmationRef.current.focus()
                  }
                />
                <TextField
                  innerRef={passwordConfirmationRef}
                  name="passwordConfirmation"
                  label="Password confirmation"
                  secureTextEntry={true}
                  returnKeyType="done"
                  onSubmitEditing={handleSubmit}
                />
              </View>
              <View style={workflowScreenStyles.actions}>
                <PrimaryButton
                  title={isLoading ? 'Signing up...' : 'Sign Up'}
                  isSubmitting={isLoading}
                  onPress={() => handleSubmit()}
                />
                {!isKeyboardVisible && (
                  <SecondaryButton title="Log in" onPress={onSignInPressed} />
                )}
              </View>
            </ResponsiveContainer>
          )}
        </Formik>
      </KeyboardDismissingBackground>
    </SafeAreaView>
  );
}
