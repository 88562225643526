import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  LayoutChangeEvent,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSetRecoilState } from 'recoil';
import { isMobile } from '../../../config/constants';
import notificationStyles from '../../../config/notificationStyles';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import proposedEmptyGroupDepartureExistsState from '../../../state/proposedEmptyGroupDepartureExistsState';

export default function ProposedEmptyGroupDepartureNotification() {
  const [layoutWidth, setLayoutWidth] = useState(isMobile ? 500 : 1000);
  const [contentDirectionStyle, setContentDirectionStyle] = useState<{
    flexDirection: 'row' | 'column';
  }>(notificationStyles.column);

  const roomSubscription = useContext(RoomSubscriptionContext);
  const setProposedEmptyGroupDepartureExists = useSetRecoilState(
    proposedEmptyGroupDepartureExistsState
  );

  const onLayout = ({
    nativeEvent: {
      layout: { width },
    },
  }: LayoutChangeEvent) => {
    setLayoutWidth(width);
  };

  useEffect(() => {
    setContentDirectionStyle(
      layoutWidth < 1024 ? notificationStyles.column : notificationStyles.row
    );
  }, [layoutWidth]);

  const onPressCancel = useCallback(() => {
    setProposedEmptyGroupDepartureExists(false);
  }, [setProposedEmptyGroupDepartureExists]);

  const onPressConfirm = useCallback(() => {
    setProposedEmptyGroupDepartureExists(false);
    roomSubscription.perform('create_departure', {
      departure: {
        membershipIds: [],
      },
    });
  }, [setProposedEmptyGroupDepartureExists, roomSubscription]);

  return (
    <View onLayout={onLayout}>
      <SafeAreaView edges={['top', 'left', 'right']}>
        <View style={[notificationStyles.content, contentDirectionStyle]}>
          <Text style={notificationStyles.text}>
            You're alone. Tap on friends to bring them with you.
          </Text>
          <View style={notificationStyles.spacer} />
          <View style={notificationStyles.actions}>
            <TouchableHighlight
              style={notificationStyles.actionButton}
              onPress={onPressCancel}
            >
              <Text style={notificationStyles.actionButtonText}>Cancel</Text>
            </TouchableHighlight>
            <TouchableHighlight
              style={notificationStyles.actionButton}
              onPress={onPressConfirm}
            >
              <Text style={notificationStyles.actionButtonText}>
                ✌️ No, I want to party alone
              </Text>
            </TouchableHighlight>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}
