import { selectorFamily } from 'recoil';
import videoGridMembershipCellLayoutState, {
  VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
} from './videoGridMembershipCellLayoutState';

const videoGridMembershipCellScaleState = selectorFamily<number, string>({
  key: 'videoGridMembershipCellScaleState',
  get: (id) => ({ get }) => {
    return (
      get(videoGridMembershipCellLayoutState(id)).width /
      VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE
    );
  },
});
export default videoGridMembershipCellScaleState;
