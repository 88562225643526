import listify from 'listify';
import _ from 'lodash';
import { selector } from 'recoil';
import matchmakingState from './matchmakingState';
import {
  ActiveRemoteMembershipCreatedEvent,
  NotificationEvent,
  NotificationEventType,
} from './notificationEventsState';
import pendingNotificationEventsState from './pendingNotificationEventsState';
import proposedEmptyGroupDepartureExistsState from './proposedEmptyGroupDepartureExistsState';
import remoteMembershipState from './remoteMembershipState';
import upcomingDepartureNotificationDataState, {
  UpcomingDepartureNotificationData,
} from './upcomingDepartureNotificationDataState';

export enum NotificationType {
  WELCOME = 'welcome',
  ACTIVE_REMOTE_MEMBERSHIPS_CREATED = 'active_remote_memberships_created',
  UPCOMING_DEPARTURE = 'upcoming_departure',
  PROPOSED_EMPTY_GROUP_DEPARTURE = 'proposed_empty_group_departure',
  FREE_TRIAL_ROOM_EXPIRING = 'free_trial_room_expiring',
  MATCHMAKING = 'matchmaking',
}

interface AbstractNotification {
  type: NotificationType;
  notificationEvents: NotificationEvent[];
}

interface WelcomeNotification extends AbstractNotification {
  type: NotificationType.WELCOME;
  description: string;
}

interface ActiveRemoteMembershipsCreatedNotification
  extends AbstractNotification {
  type: NotificationType.ACTIVE_REMOTE_MEMBERSHIPS_CREATED;
  description: string;
}

interface SimpleNotification extends AbstractNotification {
  type:
    | NotificationType.PROPOSED_EMPTY_GROUP_DEPARTURE
    | NotificationType.FREE_TRIAL_ROOM_EXPIRING
    | NotificationType.MATCHMAKING;
}

export type Notification =
  | WelcomeNotification
  | ActiveRemoteMembershipsCreatedNotification
  | UpcomingDepartureNotificationData
  | SimpleNotification;

const nextStableNotificationState = selector<Notification | null>({
  key: 'nextStableNotificationState',
  get: ({ get }) => {
    const pendingNotificationEvents = get(pendingNotificationEventsState);

    const upcomingDepartureNotification = get(
      upcomingDepartureNotificationDataState
    );
    if (upcomingDepartureNotification) {
      return upcomingDepartureNotification;
    }

    const proposedEmptyGroupDepartureExists = get(
      proposedEmptyGroupDepartureExistsState
    );
    if (proposedEmptyGroupDepartureExists) {
      return {
        type: NotificationType.PROPOSED_EMPTY_GROUP_DEPARTURE,
        notificationEvents: [],
      };
    }

    const matchmaking = get(matchmakingState);
    if (matchmaking) {
      return {
        type: NotificationType.MATCHMAKING,
        notificationEvents: [],
      };
    }

    const pendingWelcomeEvent = _.find(
      pendingNotificationEvents,
      ({ type }) => type === NotificationEventType.WELCOME
    );
    const pendingActiveRemoteMembershipsCreatedEvents = _.filter(
      pendingNotificationEvents,
      ({ type }) =>
        type === NotificationEventType.ACTIVE_REMOTE_MEMBERSHIP_CREATED
    ) as ActiveRemoteMembershipCreatedEvent[];

    const pendingActiveRemoteMembershipCreatedEventsWithNames = _.slice(
      pendingActiveRemoteMembershipsCreatedEvents,
      0,
      2
    );
    const membershipsWithNames = _.map(
      pendingActiveRemoteMembershipCreatedEventsWithNames,
      (event) => get(remoteMembershipState(event.membershipId))
    );
    const names = _.map(membershipsWithNames, 'name');

    const namesCount = _.size(names);
    const eventsWithoutNames = _.slice(
      pendingActiveRemoteMembershipsCreatedEvents,
      2
    );
    const eventsWithoutNamesCount = _.size(eventsWithoutNames);

    const joinedRemoteParticipantsSummary =
      namesCount > 1
        ? eventsWithoutNamesCount > 0
          ? `${listify(names, {
              finalWord: false,
            })}, and ${eventsWithoutNamesCount} more`
          : listify(names)
        : _.first(names);

    if (pendingWelcomeEvent) {
      const description = `👋 Welcome to the party! ${
        joinedRemoteParticipantsSummary
          ? `${joinedRemoteParticipantsSummary} ${
              namesCount > 1 ? 'are' : 'is'
            } here.`
          : ''
      }`;

      return {
        type: NotificationType.WELCOME,
        notificationEvents: [
          pendingWelcomeEvent,
          ...pendingActiveRemoteMembershipsCreatedEvents,
        ],
        description,
      };
    }

    if (_.some(pendingActiveRemoteMembershipsCreatedEvents)) {
      return {
        type: NotificationType.ACTIVE_REMOTE_MEMBERSHIPS_CREATED,
        notificationEvents: pendingActiveRemoteMembershipsCreatedEvents,
        description: `👋 ${joinedRemoteParticipantsSummary} joined!`,
      };
    }
    return null;
  },
});
export default nextStableNotificationState;
