import { ActionCable } from '@kesha-antonov/react-native-action-cable';
import camelize from 'camelize';
import { useEffect, useState } from 'react';
import snakeize from 'snakeize';
import config from '../config/config';
import useAuthorization from './useAuthorization';

interface Mixin {
  connected?: () => void;
  disconnected?: () => void;
  received: (data: any) => void;
}

export interface ConnectionSubscription {
  perform: (action: string, attributes: any) => void;
  unsubscribe: () => void;
}

export interface Connection {
  subscriptions: {
    create: (params: any, mixin: Mixin) => ConnectionSubscription;
  };
}

export default function useConnection(): Connection {
  const authorization = useAuthorization();
  const [connection, setConnection] = useState<Connection>();

  useEffect(() => {
    if (!authorization) {
      return;
    }

    const httpAccessToken = encodeURIComponent(authorization);
    const baseConnection = ActionCable.createConsumer(
      `${config().apiUrl}/cable?access_token=${httpAccessToken}`
    );
    baseConnection.connect();

    const interval = setInterval(() => {
      if (baseConnection.connection.isOpen()) {
        setConnection({
          subscriptions: {
            create: (params: any, mixin: Mixin) => {
              const snakeizedParams = snakeize(params);

              const subscription = baseConnection.subscriptions.create(
                snakeizedParams
              );
              const onConnected = () => {
                if (mixin.connected) {
                  mixin.connected();
                }
              };
              subscription.on('connected', onConnected);
              const onDisconnected = () => {
                if (mixin.disconnected) {
                  mixin.disconnected();
                }
              };
              subscription.on('disconnected', onDisconnected);
              const onReceived = (data: any) => {
                const camelizedData = camelize(data);
                mixin.received(camelizedData);
              };
              subscription.on('received', onReceived);

              return {
                perform: (action: string, attributes: any) => {
                  const snakeizedAttributes = snakeize(attributes);
                  subscription.perform(action, snakeizedAttributes);
                },
                unsubscribe: () => {
                  subscription.removeListener('connected', onConnected);
                  subscription.removeListener('disconnected', onDisconnected);
                  subscription.removeListener('recieved', onReceived);
                  subscription.unsubscribe();
                },
              };
            },
          },
        });
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
      setConnection(null);
      baseConnection.disconnect();
    };
  }, [authorization]);

  return connection;
}
