import { selector } from 'recoil';
import membershipDepartureState from './membershipDepartureState';
import membershipIdState from './membershipIdState';

const localMembershipDepartureState = selector({
  key: 'localMembershipDepartureState',
  get: ({ get }) => {
    return get(membershipDepartureState(get(membershipIdState)));
  },
});
export default localMembershipDepartureState;
