import _ from 'lodash';
import { useMemo } from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import { useRecoilValue } from 'recoil';
import videoGridCellScaleState from '../state/videoGridCellScaleState';
import videoGridMembershipCellScaleState from '../state/videoGridMembershipCellScaleState';

const SCALED_STYLE_ATTRIBUTES = [
  'borderBottomEndRadius',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderBottomStartRadius',
  'borderBottomWidth',
  'borderEndWidth',
  'borderLeftWidth',
  'borderRadius',
  'borderRightWidth',
  'borderStartWidth',
  'borderTopEndRadius',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderTopStartRadius',
  'borderTopWidth',
  'borderWidth',
  'bottom',
  'end',
  'fontSize',
  'height',
  'left',
  'margin',
  'marginBottom',
  'marginEnd',
  'marginHorizontal',
  'marginLeft',
  'marginRight',
  'marginStart',
  'marginTop',
  'marginVertical',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'padding',
  'paddingBottom',
  'paddingEnd',
  'paddingHorizontal',
  'paddingLeft',
  'paddingRight',
  'paddingStart',
  'paddingTop',
  'paddingVertical',
  'right',
  'shadowRadius',
  'start',
  'top',
  'width',
];

export default function useVideoGridCellScaledStyle<
  T extends Partial<ViewStyle | TextStyle>
>(style: T, membershipId?: string): T {
  const videoGridCellScale = useRecoilValue(
    membershipId
      ? videoGridMembershipCellScaleState(membershipId)
      : videoGridCellScaleState
  );

  return useMemo(() => {
    const scaledStyle = {} as T;

    _.each(_.entries(style), ([key, value]) => {
      if (
        _.includes(SCALED_STYLE_ATTRIBUTES, key) &&
        typeof value === 'number'
      ) {
        scaledStyle[key] = value / videoGridCellScale;
      } else {
        scaledStyle[key] = value;
      }
    });

    return scaledStyle;
  }, [style, videoGridCellScale]);
}
