import { CAMERA, usePermissions } from 'expo-permissions';
import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import VolatileResourceStore from '../models/VolatileResourceStore';
import videoMutedState from '../state/videoMutedState';
import {
  LocalVideoTrack,
  LocalVideoTrackCreateParams,
  RemoteVideoTrack,
} from '../twilio';
import useCurrentVideoDevice from './useCurrentVideoDevice';
import useRerender from './useRerender';

export const VIDEO_TRACK_NAME_PREFIX = 'video';

const MIRROR_ENABLED_SEGMENT = 'mirror';
const MIRROR_DISABLED_SEGMENT = 'nomirror';

const VIDEO_TRACK_MIRROR_DISABLED_PREFIX = `${VIDEO_TRACK_NAME_PREFIX}-${MIRROR_DISABLED_SEGMENT}`;

export function videoTrackIsMirrored({
  name,
}: LocalVideoTrack | RemoteVideoTrack) {
  return !_.startsWith(name, VIDEO_TRACK_MIRROR_DISABLED_PREFIX);
}

const LOCAL_VIDEO_TRACK_STORE = new VolatileResourceStore({
  create: (createParams: LocalVideoTrackCreateParams) => {
    return LocalVideoTrack.create(createParams);
  },
  destroy: (localVideoTrack: LocalVideoTrack) => {
    return localVideoTrack.destroy();
  },
});

export default function useLocalVideoTrack(): LocalVideoTrack {
  const [permission] = usePermissions(CAMERA);
  const cameraPermissionGranted = permission?.granted ?? false;
  const currentVideoDevice = useCurrentVideoDevice();
  const videoMuted = useRecoilValue(videoMutedState);
  const rerender = useRerender();

  useEffect(() => {
    if (!cameraPermissionGranted || videoMuted) {
      return;
    }

    const subscription = LOCAL_VIDEO_TRACK_STORE.subscribe(rerender);
    return () => {
      LOCAL_VIDEO_TRACK_STORE.unsubscribe(subscription);
    };
  }, [rerender, cameraPermissionGranted, videoMuted]);

  useEffect(() => {
    const name = _.join(
      [
        VIDEO_TRACK_NAME_PREFIX,
        currentVideoDevice?.position === 'back'
          ? MIRROR_DISABLED_SEGMENT
          : MIRROR_ENABLED_SEGMENT,
        Date.now(),
      ],
      '-'
    );
    LOCAL_VIDEO_TRACK_STORE.setCreateParams({
      name,
      deviceId: currentVideoDevice?.id,
      format: {
        dimensions: {
          width: 720,
          height: 720,
        },
        framerate: 24,
      },
    });
  }, [currentVideoDevice]);

  return LOCAL_VIDEO_TRACK_STORE.get();
}
