import { ImageType } from 'expo-camera/build/Camera.types';
import React, { useCallback, useRef, useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { useSetRecoilState } from 'recoil';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import draftProfileAvatarUriState from '../../state/draftProfileAvatarUriState';
import BodyText from '../BodyText';
import Camera from '../Camera';
import Header from '../Header';
import PrimaryButton from '../PrimaryButton';
import RequireCameraPermission from '../RequireCameraPermission';
import ResponsiveContainer from '../ResponsiveContainer';
import WorkflowNavigationHeader from '../workflows/WorkflowNavigationHeader';
import DraftProfileCamera from './DraftProfileCamera';

export default function DraftProfileAvatarUriForm({
  onBackPressed,
  isOnScreen,
}: {
  onBackPressed: () => void;
  isOnScreen: boolean;
}) {
  const setDraftProfileAvatarUri = useSetRecoilState(
    draftProfileAvatarUriState
  );

  const camera = useRef<Camera>(null);
  const [capturing, setCapturing] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);

  const onCameraReady = () => {
    setCameraReady(true);
  };

  const onTake = useCallback(async () => {
    if (!cameraReady) {
      return;
    }
    setCapturing(true);
    const data = await camera.current.takePictureAsync({
      imageType: ImageType.jpg,
      quality: 0.8,
    });
    setDraftProfileAvatarUri(data.uri);
    setCapturing(false);
  }, [cameraReady, camera, setDraftProfileAvatarUri]);

  return (
    <RequireCameraPermission onBackPressed={onBackPressed}>
      <SafeAreaView style={workflowScreenStyles.screen}>
        <WorkflowNavigationHeader onBackPressed={onBackPressed} />
        <View style={workflowScreenStyles.content}>
          <ResponsiveContainer>
            <View style={workflowScreenStyles.group}>
              <Header text="Take a selfie 📸" />
              <BodyText text="We'll use this as your profile pic." />
              <DraftProfileCamera
                innerRef={camera}
                onCameraReady={onCameraReady}
                isOnScreen={isOnScreen}
              />
            </View>
            <View style={workflowScreenStyles.actions}>
              <PrimaryButton
                onPress={onTake}
                title={capturing ? 'Capturing...' : 'Take photo'}
                disabled={!cameraReady}
                isSubmitting={capturing}
              />
            </View>
          </ResponsiveContainer>
        </View>
      </SafeAreaView>
    </RequireCameraPermission>
  );
}
