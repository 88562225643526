import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  LayoutChangeEvent,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useRecoilValue } from 'recoil';
import { isMobile } from '../../../config/constants';
import notificationStyles from '../../../config/notificationStyles';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import roomState from '../../../state/roomState';
import TimedProgressBarBackground from '../TimedProgressBarBackground';
import pluralize from 'pluralize';

export default function MatchmakingNotification() {
  const [layoutWidth, setLayoutWidth] = useState(isMobile ? 500 : 1000);
  const [contentDirectionStyle, setContentDirectionStyle] = useState<{
    flexDirection: 'row' | 'column';
  }>(notificationStyles.column);

  const onLayout = ({
    nativeEvent: {
      layout: { width },
    },
  }: LayoutChangeEvent) => {
    setLayoutWidth(width);
  };

  useEffect(() => {
    setContentDirectionStyle(
      layoutWidth < 1024 ? notificationStyles.column : notificationStyles.row
    );
  }, [layoutWidth]);

  const {
    matchmakingMembershipsCount,
    matchmakingGracePeriodStartedAt,
    matchmakingWillCompleteAt,
  } = useRecoilValue(roomState);

  const roomSubscription = useContext(RoomSubscriptionContext);
  const onPressCancel = useCallback(() => {
    roomSubscription.perform('leave_matchmaking');
  }, [roomSubscription]);

  return (
    <View onLayout={onLayout}>
      {matchmakingGracePeriodStartedAt && matchmakingWillCompleteAt && (
        <TimedProgressBarBackground
          borderRadius={0}
          startAt={matchmakingGracePeriodStartedAt}
          endAt={matchmakingWillCompleteAt}
        />
      )}
      <SafeAreaView edges={['top', 'left', 'right']}>
        <View style={[notificationStyles.content, contentDirectionStyle]}>
          <Text style={notificationStyles.text}>
            {matchmakingMembershipsCount === 1
              ? `You're mixing things up! Once another guest taps the "Mix things up" button, we'll introduce you to each other.`
              : `You and ${pluralize(
                  'guest',
                  matchmakingMembershipsCount - 1,
                  true
                )} are mixing it up! We'll introduce you in a few seconds.`}
          </Text>
          <View style={notificationStyles.spacer} />
          <View style={notificationStyles.actions}>
            <TouchableHighlight
              style={notificationStyles.actionButton}
              onPress={onPressCancel}
            >
              <Text style={notificationStyles.actionButtonText}>Cancel</Text>
            </TouchableHighlight>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}
