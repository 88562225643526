import React from 'react';
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSetRecoilState } from 'recoil';
import { DEFAULT_SPACING, GREEN } from '../../config/constants';
import useProfile, { profileAvatarSource } from '../../hooks/useProfile';
import editProfileFlowVisibleState from '../../state/editProfileFlowVisibleState';
import subscriberModalVisibleState from '../../state/subscriberModalVisibleState';
import { EditProfileIcon } from '../Icons';
import ResponsiveContainer from '../ResponsiveContainer';

const styles = StyleSheet.create({
  root: {
    marginTop: -1000,
    paddingTop: 1000,
    backgroundColor: GREEN,
    alignItems: 'stretch',
  },
  content: {
    alignSelf: 'stretch',
    padding: DEFAULT_SPACING * 3,
    paddingTop: Platform.select({
      ios: 0,
      default: DEFAULT_SPACING * 3,
    }),
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 78,
    height: 20,
  },
  topBarRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subscribedText: {
    color: 'white',
    textAlignVertical: 'center',
    marginTop: -4,
  },
  topBarButton: {
    padding: DEFAULT_SPACING,
  },
  avatar: {
    width: DEFAULT_SPACING * 14,
    height: DEFAULT_SPACING * 14,
    borderRadius: DEFAULT_SPACING * 7,
    alignSelf: 'flex-start',
    marginTop: 150,
  },
  welcomeText: {
    marginTop: DEFAULT_SPACING * 2,
    alignSelf: 'flex-start',
    textAlign: 'left',
    color: 'white',
    fontSize: 32,
    lineHeight: 37,
    fontWeight: '700',
  },
});

export default function HomeScreenHero() {
  const profile = useProfile();
  const setEditProfileFlowVisible = useSetRecoilState(
    editProfileFlowVisibleState
  );
  const setSubscriberModalVisible = useSetRecoilState(
    subscriberModalVisibleState
  );

  return (
    <View style={styles.root}>
      <SafeAreaView edges={['top', 'left', 'right']}>
        <ResponsiveContainer>
          <View style={styles.content}>
            <View style={styles.topBar}>
              <Image
                source={require('../../../assets/stoop-logo-white.png')}
                style={styles.logo}
              />
              <View style={styles.topBarRight}>
                {profile.subscribed && (
                  <Pressable onPress={() => setSubscriberModalVisible(true)}>
                    <Text style={styles.subscribedText}>💘 Subscriber</Text>
                  </Pressable>
                )}
                <Pressable
                  style={styles.topBarButton}
                  onPress={() => setEditProfileFlowVisible(true)}
                >
                  <EditProfileIcon />
                </Pressable>
              </View>
            </View>
            <Image
              style={styles.avatar}
              source={profileAvatarSource(profile)}
            />
            <Text style={styles.welcomeText}>
              Welcome back, {profile.name}.
            </Text>
          </View>
        </ResponsiveContainer>
      </SafeAreaView>
    </View>
  );
}
