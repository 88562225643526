import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import localMembershipDepartureState from '../../../state/localMembershipDepartureState';
import proposedEmptyGroupDepartureExistsState from '../../../state/proposedEmptyGroupDepartureExistsState';
import selectedRemoteMembershipIdsState from '../../../state/selectedRemoteMembershipIdsState';
import someRemoteMembershipSelectedState from '../../../state/someRemoteMembershipSelectedState';
import GroupListItem from './GroupListItem';
import GroupListItemActionButton from './GroupListItemActionButton';

export default function NewGroupButton() {
  const roomSubscription = useContext(RoomSubscriptionContext);
  const selectedRemoteMembershipIds = useRecoilValue(
    selectedRemoteMembershipIdsState
  );
  const setProposedEmptyGroupDepartureExists = useSetRecoilState(
    proposedEmptyGroupDepartureExistsState
  );

  const onPress = useCallback(() => {
    if (_.isEmpty(selectedRemoteMembershipIds)) {
      setProposedEmptyGroupDepartureExists(true);
    } else {
      roomSubscription.perform('create_departure', {
        departure: {
          membershipIds: selectedRemoteMembershipIds,
        },
      });
    }
  }, [
    setProposedEmptyGroupDepartureExists,
    selectedRemoteMembershipIds,
    roomSubscription,
  ]);

  const highlighted = useRecoilValue(someRemoteMembershipSelectedState);

  const departure = useRecoilValue(localMembershipDepartureState);
  const enabled = !departure;

  return (
    <GroupListItem
      title="Create Room"
      onPress={enabled ? onPress : null}
      highlighted={highlighted}
    >
      <GroupListItemActionButton highlighted={highlighted} />
    </GroupListItem>
  );
}
