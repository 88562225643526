import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';

const FLOATING_HEART_DURATION_MS = 2000;

export function centeredRandom(center: number, range: number) {
  return Math.random() * range + center - range / 2;
}

function radiansStandardized(nonstandardRadians: number) {
  const modNonstandardRadians = nonstandardRadians % (Math.PI * 2);
  if (modNonstandardRadians < 0) {
    return modNonstandardRadians + Math.PI * 2;
  }
  return modNonstandardRadians;
}

type Props = PropsWithChildren<{
  fromX: number;
  fromY: number;
  meanDistance: number;
  delay: number;
  directionCenter?: number;
  directionRange?: number;
  onAnimationFinished: () => void;
  style: StyleProp<ViewStyle>;
}>;

const DEFAULT_DIRECTION_CENTER = Math.PI * 1.5;
const DEFAULT_DIRECTION_RANGE = Math.PI * 0.5;

const DISTANCE_RELATIVE_RANGE = 0.125;

const ROTATION_CENTER = 0;
const ROTATION_RANGE = Math.PI * 0.75;

const MAX_SCALE_CENTER = 2;
const MAX_SCALE_RANGE = 0.5;

export default function FloatingEffect({
  children,
  delay,
  directionCenter,
  directionRange,
  fromX,
  fromY,
  meanDistance,
  onAnimationFinished,
  style,
}: Props) {
  const direction = useRef(
    centeredRandom(
      directionCenter ?? DEFAULT_DIRECTION_CENTER,
      directionRange ?? DEFAULT_DIRECTION_RANGE
    )
  ).current;
  const distance = useRef(
    centeredRandom(meanDistance, meanDistance * DISTANCE_RELATIVE_RANGE)
  ).current;
  const toRotation = useRef(
    radiansStandardized(centeredRandom(ROTATION_CENTER, ROTATION_RANGE))
  ).current;
  const maxScale = useRef(centeredRandom(MAX_SCALE_CENTER, MAX_SCALE_RANGE))
    .current;

  const translateX = useRef<Animated.Value>(new Animated.Value(fromX)).current;
  const translateY = useRef<Animated.Value>(new Animated.Value(fromY)).current;
  const rotateWithoutUnits = useRef<Animated.Value>(
    new Animated.Value(toRotation < Math.PI ? 0 : Math.PI * 1.99)
  ).current;
  const scale = useRef<Animated.Value>(new Animated.Value(1)).current;
  const opacity = useRef<Animated.Value>(new Animated.Value(0)).current;

  useEffect(() => {
    const dx = Math.cos(direction) * distance;
    const toX = fromX + dx;
    const dy = Math.sin(direction) * distance;
    const toY = fromY + dy;

    Animated.sequence([
      Animated.timing(opacity, {
        delay,
        duration: 0,
        toValue: 1,
        useNativeDriver: true,
      }),
      Animated.parallel([
        Animated.timing(translateX, {
          toValue: toX,
          duration: FLOATING_HEART_DURATION_MS,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: toY,
          duration: FLOATING_HEART_DURATION_MS,
          useNativeDriver: true,
        }),
        Animated.timing(rotateWithoutUnits, {
          toValue: toRotation,
          duration: FLOATING_HEART_DURATION_MS,
          useNativeDriver: true,
        }),
        Animated.sequence([
          Animated.timing(scale, {
            toValue: maxScale,
            duration: FLOATING_HEART_DURATION_MS * 0.5,
            useNativeDriver: true,
          }),
          Animated.parallel([
            Animated.timing(scale, {
              toValue: 0.5,
              duration: FLOATING_HEART_DURATION_MS * 0.5,
              useNativeDriver: true,
            }),
            Animated.timing(opacity, {
              toValue: 0,
              duration: FLOATING_HEART_DURATION_MS * 0.5,
              useNativeDriver: true,
            }),
          ]),
        ]),
      ]),
    ]).start((result) => {
      if (result.finished) {
        onAnimationFinished();
      }
    });
  }, [
    direction,
    distance,
    fromX,
    fromY,
    maxScale,
    opacity,
    rotateWithoutUnits,
    scale,
    toRotation,
    translateX,
    translateY,
    delay,
    onAnimationFinished,
  ]);

  const animationStyle = useMemo(
    () => ({
      transform: [
        { translateX },
        { translateY },
        { scaleX: scale },
        { scaleY: scale },
        {
          rotate: rotateWithoutUnits.interpolate({
            inputRange: [0, 2 * Math.PI],
            outputRange: ['0rad', `${2 * Math.PI}rad`],
          }),
        },
      ],
      opacity,
    }),
    [opacity, rotateWithoutUnits, scale, translateX, translateY]
  );

  return (
    <Animated.View style={[animationStyle, style]}>{children}</Animated.View>
  );
}
