import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import Video from 'twilio-video';
import { SCREEN_SHARING_TRACK_NAME_PREFIX } from '../config/constants';
import VolatileResourceStore from '../models/VolatileResourceStore';
import screenSharingState from '../state/screenSharingState';
import LocalAudioTrack from '../twilio/web/models/LocalAudioTrack';
import LocalVideoTrack from '../twilio/web/models/LocalVideoTrack';
import useRerender from './useRerender';

interface MediaDevicesWithScreenCapture {
  getDisplayMedia(constraints?: any): Promise<MediaStream>;
}

interface LocalScreenSharingTracksCreateParams {
  video: Video.LocalTrackOptions;
  audio: Video.LocalTrackOptions;
}

const LOCAL_SCREEN_SHARING_TRACKS_STORE = new VolatileResourceStore({
  create: async (params: LocalScreenSharingTracksCreateParams | null) => {
    if (!params) {
      return Promise.resolve(null);
    }

    const { video, audio } = params;

    const mediaDevicesWithScreenCapture = (navigator.mediaDevices as unknown) as MediaDevicesWithScreenCapture;

    try {
      const mediaStream = await mediaDevicesWithScreenCapture.getDisplayMedia({
        video: true,
        audio: true,
      });

      const mediaStreamVideoTrack = _.first(mediaStream.getVideoTracks());
      const twilioLocalVideoTrack = new Video.LocalVideoTrack(
        mediaStreamVideoTrack,
        video
      );
      const videoTrack = new LocalVideoTrack(twilioLocalVideoTrack);

      const mediaStreamAudioTrack = _.first(mediaStream.getAudioTracks());
      const twilioLocalAudioTrack = mediaStreamAudioTrack
        ? new Video.LocalAudioTrack(mediaStreamAudioTrack, audio)
        : null;
      const audioTrack = twilioLocalAudioTrack
        ? new LocalAudioTrack(twilioLocalAudioTrack)
        : null;

      return { videoTrack, audioTrack };
    } catch (error) {
      return Promise.resolve(null);
    }
  },
  destroy: async (localScreenSharingTracks) => {
    if (!localScreenSharingTracks) {
      return Promise.resolve();
    }

    const { videoTrack, audioTrack } = localScreenSharingTracks;
    await Promise.all([videoTrack.destroy(), audioTrack?.destroy()]);
  },
});

export default function useLocalScreenSharingTracks() {
  const [screenSharing, setScreenSharing] = useRecoilState(screenSharingState);

  const rerender = useRerender();
  useEffect(() => {
    const subscription = LOCAL_SCREEN_SHARING_TRACKS_STORE.subscribe(rerender);
    return () => LOCAL_SCREEN_SHARING_TRACKS_STORE.unsubscribe(subscription);
  }, [rerender]);

  let localScreenSharingTracks = null;
  let hasError = false;
  try {
    localScreenSharingTracks = LOCAL_SCREEN_SHARING_TRACKS_STORE.get();
  } catch (error) {
    hasError = true;
  }

  useEffect(() => {
    if (hasError) {
      setScreenSharing(false);
    }
  }, [hasError, setScreenSharing]);

  useEffect(() => {
    const now = Date.now();

    const createParams = screenSharing
      ? {
          video: {
            name: `${SCREEN_SHARING_TRACK_NAME_PREFIX}-video-${now}`,
            logLevel: 'warn',
          } as Video.LocalTrackOptions,
          audio: {
            name: `${SCREEN_SHARING_TRACK_NAME_PREFIX}-audio-${now}`,
            logLevel: 'warn',
          } as Video.LocalTrackOptions,
        }
      : null;

    LOCAL_SCREEN_SHARING_TRACKS_STORE.setCreateParams(createParams);
  }, [screenSharing]);

  useEffect(() => {
    const audioMediaStreamTrack =
      localScreenSharingTracks?.audioTrack?.twilioLocalAudioTrack
        ?.mediaStreamTrack;
    if (audioMediaStreamTrack) {
      audioMediaStreamTrack.onended = () => setScreenSharing(false);
    }
    const videoMediaStreamTrack =
      localScreenSharingTracks?.videoTrack?.twilioLocalVideoTrack
        ?.mediaStreamTrack;
    if (videoMediaStreamTrack) {
      videoMediaStreamTrack.onended = () => setScreenSharing(false);
    }
  }, [localScreenSharingTracks, setScreenSharing]);

  return {
    localVideoTrack: localScreenSharingTracks?.videoTrack,
    localAudioTrack: localScreenSharingTracks?.audioTrack,
  };
}
