import React from 'react';
import { SvgProps } from 'react-native-svg';
import PhoneGroupIllustrationSvg from '../../assets/icons/phoneGroupIllustration.svg';
import Svg from './Svg';

export default function PhoneGroupIllustration(props: SvgProps) {
  return (
    <Svg>
      <PhoneGroupIllustrationSvg
        {...{
          width: 200,
          height: 200,
          ...props,
        }}
      />
    </Svg>
  );
}
