import {
  lockPlatformAsync,
  Orientation,
  unlockAsync,
  WebOrientationLock,
} from 'expo-screen-orientation';
import { useEffect } from 'react';

const lockToPotrait = async () => {
  try {
    await lockPlatformAsync({
      screenOrientationLockWeb: WebOrientationLock.PORTRAIT,
      screenOrientationArrayIOS: [Orientation.PORTRAIT_UP],
      screenOrientationConstantAndroid: Orientation.PORTRAIT_UP,
    });
  } catch {
    // on web devices that don't support rotation, this will error
    // we don't care :)
  }
};

const unlock = async () => {
  try {
    await unlockAsync();
  } catch {
    // on web devices that don't support rotation, this will error
    // we don't care :)
  }
};

export default function usePortraitOrientation() {
  useEffect(() => {
    lockToPotrait();
    return () => unlock();
  }, []);
}
