import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useHotkeys } from 'react-hotkeys-hook';
import WebModal from '../../WebModal';
import { useRecoilState } from 'recoil';
import mutedState from '../../../state/mutedState';
import videoMutedState from '../../../state/videoMutedState';
import { DEFAULT_SPACING, NEAR_BLACK } from '../../../config/constants';

export default function Hotkeys() {
  const [hotkeysVisible, setHotkeysVisible] = useState(false);
  const [muted, setMuted] = useRecoilState(mutedState);
  const [videoMuted, setVideoMuted] = useRecoilState(videoMutedState);

  useHotkeys('shift+/', () => setHotkeysVisible(!hotkeysVisible), [
    hotkeysVisible,
  ]);
  useHotkeys('m', () => setMuted(!muted), [muted]);
  useHotkeys('v', () => setVideoMuted(!videoMuted), [videoMuted]);

  return (
    <WebModal
      title="Keyboard shortcuts"
      visible={hotkeysVisible}
      onClose={() => setHotkeysVisible(false)}
    >
      <View>
        <View style={styles.row}>
          <Text style={styles.key}>Shift + /</Text>
          <Text style={styles.action}>Toggle shortcuts</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.key}>m</Text>
          <Text style={styles.action}>Toggle mute</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.key}>v</Text>
          <Text style={styles.action}>Toggle video</Text>
        </View>
      </View>
    </WebModal>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: DEFAULT_SPACING,
  },
  key: {
    borderRadius: DEFAULT_SPACING / 2,
    borderColor: NEAR_BLACK,
    backgroundColor: '#f5f5f5',
    padding: DEFAULT_SPACING,
    fontWeight: 'bold',
    fontFamily: 'monospace',
    marginRight: DEFAULT_SPACING,
  },
  action: {
    padding: DEFAULT_SPACING,
  },
});
