import React, { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import localMembershipDepartureState from '../../../state/localMembershipDepartureState';
import membershipDepartureState from '../../../state/membershipDepartureState';
import proposedEmptyGroupDepartureExistsState from '../../../state/proposedEmptyGroupDepartureExistsState';
import remoteMembershipIsSelectedState from '../../../state/remoteMembershipIsSelectedState';
import remoteMembershipMutedState from '../../../state/remoteMembershipMutedState';
import MutedVideoOverlay from './MutedVideoOverlay';
import RemoteMembershipVideo from './RemoteMembershipVideo';
import RemoteMembershipVideoActionsModal from './RemoteMembershipVideoActionsModal';
import SelectableVideoOverlay from './SelectableVideoOverlay';

export default function RemoteMembershipVideoGridCell({ id }: { id: string }) {
  const setProposedEmptyGroupDepartureExists = useSetRecoilState(
    proposedEmptyGroupDepartureExistsState
  );
  const [selected, setSelected] = useRecoilState(
    remoteMembershipIsSelectedState(id)
  );
  const [modalVisible, setModalVisible] = useState(false);

  const localMembershipDeparture = useRecoilValue(
    localMembershipDepartureState
  );
  const localMembershipDepartureExists = !!localMembershipDeparture;
  const remoteMembershipDeparture = useRecoilValue(
    membershipDepartureState(id)
  );
  const remoteMembershipDepartureExists = !!remoteMembershipDeparture;
  const remoteMembershipMuted = useRecoilValue(remoteMembershipMutedState(id));

  const onPress = useCallback(() => {
    setProposedEmptyGroupDepartureExists(false);

    if (!localMembershipDepartureExists && !remoteMembershipDepartureExists) {
      setSelected(!selected);
    }
  }, [
    selected,
    setSelected,
    setProposedEmptyGroupDepartureExists,
    localMembershipDepartureExists,
    remoteMembershipDepartureExists,
  ]);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  return (
    <>
      <RemoteMembershipVideo id={id} />
      <RemoteMembershipVideoActionsModal
        id={id}
        visible={modalVisible}
        onClose={hideModal}
      />
      <MutedVideoOverlay muted={remoteMembershipMuted} id={id} />
      <SelectableVideoOverlay
        selected={selected}
        onPress={onPress}
        onLongPress={showModal}
        membershipId={id}
      />
    </>
  );
}
