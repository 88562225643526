import React from 'react';
import { Linking, Modal, StyleSheet, View } from 'react-native';
import { useRecoilState } from 'recoil';
import {
  BARELY_TRANSPARENT_GRAY,
  DEFAULT_SPACING,
} from '../../config/constants';
import subscriberModalVisibleState from '../../state/subscriberModalVisibleState';
import BodyText from '../BodyText';
import Link from '../Link';
import SubscribeIllustration from '../SubscribeIllustration';

export default function SubscriberModal() {
  const [subscriberModalVisible, setSubscriberModalVisible] = useRecoilState(
    subscriberModalVisibleState
  );

  return (
    <Modal
      visible={subscriberModalVisible}
      transparent={true}
      animationType="fade"
    >
      <View style={styles.centeredView}>
        <View style={styles.content}>
          <View style={styles.illustration}>
            <SubscribeIllustration />
          </View>
          <BodyText text="💘 Subscriber" style={styles.header} />
          <BodyText
            text="If you want to make any changes to your subscription, contact us."
            style={styles.bodyText}
          />
          <Link
            title="Contact us"
            onPress={() => {
              Linking.openURL('mailto:support@stoop.video');
            }}
            style={styles.contactUsButton}
          />
          <Link
            title="Got it"
            onPress={() => setSubscriberModalVisible(false)}
          />
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: BARELY_TRANSPARENT_GRAY,
  },
  content: {
    alignSelf: 'center',
    alignItems: 'center',
    padding: DEFAULT_SPACING * 2,
    backgroundColor: 'white',
    borderRadius: DEFAULT_SPACING,
    width: 300,
  },
  illustration: {
    marginVertical: DEFAULT_SPACING * 2,
  },
  header: {
    fontWeight: 'bold',
    marginBottom: DEFAULT_SPACING,
  },
  bodyText: {
    marginBottom: DEFAULT_SPACING,
  },
  contactUsButton: {
    marginBottom: 0,
  },
});
