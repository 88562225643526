import { selector } from 'recoil';
import localMembershipState from './localMembershipState';
import { Membership } from './membershipState';
import remoteMembershipsState from './remoteMembershipsState';

const membershipsState = selector<Membership[]>({
  key: 'membershipsState',
  get: ({ get }) => {
    const localMembership = get(localMembershipState);
    const remoteMemberships = get(remoteMembershipsState);
    return [localMembership, ...remoteMemberships];
  },
});
export default membershipsState;
