import React, { useContext, useEffect, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { isMobile } from '../../../config/constants';
import notificationStyles from '../../../config/notificationStyles';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import useIsSmallMediaQuery from '../../../hooks/useIsSmallMediaQuery';
import { UpcomingDepartureNotificationData } from '../../../state/upcomingDepartureNotificationDataState';
import TimedProgressBarBackground from '../TimedProgressBarBackground';

export default function UpcomingDepartureNotification({
  notification,
}: {
  notification: UpcomingDepartureNotificationData;
}) {
  const {
    departureId,
    createdAt,
    departingAt,
    currentUserCreatedDeparture,
    actionDescription,
    departureFollowersDescription,
  } = notification;

  const isSmall = useIsSmallMediaQuery();
  const [contentDirectionStyle, setContentDirectionStyle] = useState<{
    flexDirection: 'row' | 'column';
  }>(notificationStyles.column);

  useEffect(() => {
    setContentDirectionStyle(
      isMobile || isSmall ? notificationStyles.column : notificationStyles.row
    );
  }, [isSmall]);

  const roomSubscription = useContext(RoomSubscriptionContext);
  const onPressAccelerateDeparture = () => {
    roomSubscription.perform('accelerate_departure', {});
  };

  const onPressLeaveDeparture = () => {
    roomSubscription.perform('leave_departure', {});
  };

  const onPressCancelDeparture = () => {
    roomSubscription.perform('cancel_departure', {
      departure: { id: departureId },
    });
  };

  return (
    <View>
      <TimedProgressBarBackground
        borderRadius={0}
        startAt={createdAt}
        endAt={departingAt}
      />
      <SafeAreaView edges={['top', 'left', 'right']}>
        <View style={[notificationStyles.content, contentDirectionStyle]}>
          <Text style={notificationStyles.text}>
            👋 {actionDescription} {departureFollowersDescription} to another
            room...
          </Text>
          <View style={notificationStyles.spacer} />
          <View style={notificationStyles.actions}>
            {!currentUserCreatedDeparture && (
              <Pressable
                style={notificationStyles.actionButton}
                onPress={onPressAccelerateDeparture}
              >
                <Text style={notificationStyles.actionButtonText}>
                  Let's go now
                </Text>
              </Pressable>
            )}
            {currentUserCreatedDeparture && (
              <Pressable
                style={notificationStyles.actionButton}
                onPress={onPressCancelDeparture}
              >
                <Text style={notificationStyles.actionButtonText}>
                  Cancel move
                </Text>
              </Pressable>
            )}
            {!currentUserCreatedDeparture && (
              <Pressable
                style={notificationStyles.actionButton}
                onPress={onPressLeaveDeparture}
              >
                <Text style={notificationStyles.actionButtonText}>
                  No thanks
                </Text>
              </Pressable>
            )}
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}
