import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import { Image, LayoutChangeEvent, StyleSheet } from 'react-native';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import { profileAvatarSource } from '../../../hooks/useProfile';
import groupIdState from '../../../state/groupIdState';
import groupPreviewRelativeLayoutState from '../../../state/groupPreviewRelativeLayoutState';
import groupSortedMembershipsState from '../../../state/groupSortedMembershipsState';
import groupState from '../../../state/groupState';
import localMembershipDepartureState from '../../../state/localMembershipDepartureState';
import selectedRemoteMembershipIdsState from '../../../state/selectedRemoteMembershipIdsState';
import someRemoteMembershipSelectedState from '../../../state/someRemoteMembershipSelectedState';
import GroupListItem from './GroupListItem';
import GroupListItemActionButton from './GroupListItemActionButton';
import GroupListItemSummarizedMemberships from './GroupListItemSummarizedMemberships';

const styles = StyleSheet.create({
  avatar: {
    height: DEFAULT_SPACING * 4,
    width: DEFAULT_SPACING * 4,
    borderRadius: DEFAULT_SPACING,
    backgroundColor: 'white',
    marginRight: DEFAULT_SPACING,
  },
});

export default function GroupPreview({ id }: { id: string }) {
  const name = useRecoilValue(groupState(id))?.name ?? 'Room';
  const memberships = useRecoilValue(groupSortedMembershipsState(id));
  const setGroupId = useSetRecoilState(groupIdState);

  const roomSubscription = useContext(RoomSubscriptionContext);
  const selectedRemoteMembershipIds = useRecoilValue(
    selectedRemoteMembershipIdsState
  );
  const onPressJoin = () => {
    if (_.isEmpty(selectedRemoteMembershipIds)) {
      setGroupId(id);
    } else {
      roomSubscription.perform('create_departure', {
        departure: {
          membershipIds: selectedRemoteMembershipIds,
          destinationGroupId: id,
        },
      });
    }
  };

  const groupId = useRecoilValue(groupIdState);
  const departure = useRecoilValue(localMembershipDepartureState);
  const isCurrentGroup = groupId === id;

  const disabled = !!departure || isCurrentGroup;

  const highlighted =
    useRecoilValue(someRemoteMembershipSelectedState) && !disabled;

  const setGroupPreviewRelativeLayout = useSetRecoilState(
    groupPreviewRelativeLayoutState(id)
  );
  const onLayout = useCallback(
    ({ nativeEvent: { layout } }: LayoutChangeEvent) => {
      setGroupPreviewRelativeLayout(layout);
    },
    [setGroupPreviewRelativeLayout]
  );

  return (
    <GroupListItem
      title={name}
      highlighted={highlighted}
      onPress={isCurrentGroup ? null : onPressJoin}
      onLayout={onLayout}
    >
      <GroupListItemSummarizedMemberships
        memberships={memberships}
        render={(membership) => (
          <Image
            key={membership.id}
            resizeMode="cover"
            style={styles.avatar}
            source={profileAvatarSource(membership)}
          />
        )}
        after={
          !disabled && <GroupListItemActionButton highlighted={highlighted} />
        }
      />
    </GroupListItem>
  );
}
