import { Platform, StyleSheet } from 'react-native';
import { DEFAULT_SPACING, RED } from './constants';

const workflowScreenStyles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Platform.select({
      web: 'transparent',
      default: 'white',
    }),
    alignItems: 'stretch',
    alignContent: 'stretch',
    paddingTop: Platform.select({
      android: 30,
      default: 0,
    }),
  },
  content: {
    flex: 1,
    alignContent: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: Platform.select({
      web: DEFAULT_SPACING * 10,
      default: DEFAULT_SPACING * 4,
    }),
    paddingBottom: Platform.select({
      web: DEFAULT_SPACING * 4,
      default: 0,
    }),
  },
  group: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flexGrow: 1,
  },
  actions: {
    alignSelf: 'stretch',
    marginBottom: DEFAULT_SPACING * 2,
  },
  illustration: {
    marginVertical: DEFAULT_SPACING * 6,
  },
  errorText: {
    fontWeight: 'bold',
    color: RED,
    marginTop: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING * 2,
    alignSelf: 'flex-start',
  },
});

export default workflowScreenStyles;
