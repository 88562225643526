import { AxiosResponse } from 'axios';
import Api from './Api';

interface SuggestedMembership {
  id: string;
}

export async function indexSuggestedMemberships() {
  const { data } = await Api.get<{}, AxiosResponse<SuggestedMembership[]>>(
    'suggested_memberships'
  );
  return data;
}
