import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import currentVideoDeviceIdState from '../state/currentVideoDeviceIdState';
import useAvailableVideoDevices from './useAvailableVideoDevices';

export default function useCurrentVideoDevice() {
  const currentVideoDeviceId = useRecoilValue(currentVideoDeviceIdState);
  const availableVideoDevices = useAvailableVideoDevices();

  return currentVideoDeviceId
    ? _.find(
        availableVideoDevices,
        (videoDevice) => videoDevice.id === currentVideoDeviceId
      )
    : null;
}
