import _ from 'lodash';
import { selectorFamily } from 'recoil';
import departuresState from './departuresState';

export interface Departure {
  id: string;
  groupId: string;
  membershipId: string;
  destinationGroupId: string;
  createdAt: string;
  departingAt: string;
  membershipIds: string[];
}

const departureState = selectorFamily<Departure, string>({
  key: 'departureState',
  get: (id) => ({ get }) => {
    return _.find(get(departuresState), (departure) => departure.id === id);
  },
});
export default departureState;
