import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import screenStyles from '../../config/screenStyles';
import usePortraitOrientation from '../../hooks/usePortraitOrientation';
import { Screen, ScreenStack } from '../../react-native-screens';
import draftProfileAvatarUriState from '../../state/draftProfileAvatarUriState';
import draftProfileNameState from '../../state/draftProfileNameState';
import draftProfileState from '../../state/draftProfileState';
import SignInForm from '../SignInForm';
import ConfirmDraftProfile from './ConfirmDraftProfile';
import DraftProfileAvatarUriForm from './DraftProfileAvatarUriForm';
import DraftProfileNameForm from './DraftProfileNameForm';
import Welcome from './Welcome';

enum Strategy {
  SIGN_UP,
  SIGN_IN,
}

export default function Onboarding() {
  const [strategy, setStrategy] = useState<Strategy | null>(null);
  const draftProfileName = useRecoilValue(draftProfileNameState);
  const resetDraftProfileName = useResetRecoilState(draftProfileNameState);
  const draftProfileAvatarUri = useRecoilValue(draftProfileAvatarUriState);
  const resetDraftProfileAvatarUri = useResetRecoilState(
    draftProfileAvatarUriState
  );
  const resetDraftProfile = useResetRecoilState(draftProfileState);

  useEffect(() => resetDraftProfile, [resetDraftProfile]);

  usePortraitOrientation();

  const screens = useMemo(
    () =>
      _.compact(
        _.flattenDeep([
          <Screen key="welcome" style={screenStyles.screen}>
            <Welcome
              onSignUpPressed={() => setStrategy(Strategy.SIGN_UP)}
              onSignInPressed={() => setStrategy(Strategy.SIGN_IN)}
            />
          </Screen>,
          strategy === Strategy.SIGN_IN && (
            <Screen
              key="sign_in"
              style={screenStyles.screen}
              onDismissed={() => setStrategy(null)}
            >
              <SignInForm onBackPressed={() => setStrategy(null)} />
            </Screen>
          ),
          strategy === Strategy.SIGN_UP && [
            <Screen
              key="draft_profile_name_form"
              style={screenStyles.screen}
              onDismissed={() => setStrategy(null)}
            >
              <DraftProfileNameForm onBackPressed={() => setStrategy(null)} />
            </Screen>,
            draftProfileName && [
              <Screen
                key="draft_profile_avatar_base_64_form"
                style={screenStyles.screen}
                onDismissed={resetDraftProfileName}
              >
                <DraftProfileAvatarUriForm
                  onBackPressed={resetDraftProfileName}
                  isOnScreen={!draftProfileAvatarUri}
                />
              </Screen>,
              draftProfileAvatarUri && (
                <Screen
                  key="confirm"
                  style={screenStyles.screen}
                  onDismissed={resetDraftProfileAvatarUri}
                >
                  <ConfirmDraftProfile
                    onBackPressed={resetDraftProfileAvatarUri}
                  />
                </Screen>
              ),
            ],
          ],
        ])
      ),
    [
      strategy,
      draftProfileName,
      resetDraftProfileName,
      draftProfileAvatarUri,
      resetDraftProfileAvatarUri,
    ]
  );

  return <ScreenStack style={screenStyles.root}>{screens}</ScreenStack>;
}
