import React, { useState } from 'react';
import roomPreviewButtonStyles from '../../../config/roomPreviewButtonStyles';
import { SettingsIcon } from '../../Icons';
import ChangeAudioVideoDevicesModal from '../ChangeAudioVideoDevicesModal';
import Tooltip from '../../Tooltip';
import { Pressable } from 'react-native';

export default function RoomPreviewInputSelectionButton() {
  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <Tooltip text="Settings">
        <Pressable
          style={roomPreviewButtonStyles.action}
          onPress={() => setVisible(!visible)}
        >
          <SettingsIcon />
        </Pressable>
      </Tooltip>
      <ChangeAudioVideoDevicesModal
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </React.Fragment>
  );
}
