import _ from 'lodash';
import { selectorFamily } from 'recoil';
import { Departure } from './departureState';
import groupsState from './groupsState';

export interface Group {
  id: string;
  name: string;
  departures: Departure[];
}

const groupState = selectorFamily<Group, string>({
  key: 'groupState',
  get: (id) => ({ get }) => {
    return _.find(get(groupsState), (group) => group.id === id);
  },
});
export default groupState;
