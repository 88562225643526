import React, { useCallback, useState } from 'react';
import { TouchableHighlight } from 'react-native';
import buttonStyles from '../../../config/buttonStyles';
import { ShareIcon } from '../../Icons';
import Tooltip from '../../Tooltip';
import WebShareModal from './WebShareModal';

export default function ShareButton() {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = useCallback(() => setModalVisible(true), []);
  const hideModal = useCallback(() => setModalVisible(false), []);

  return (
    <React.Fragment>
      <Tooltip text="Share room" position="bottom-start">
        <TouchableHighlight
          onPress={showModal}
          style={buttonStyles.roomHeaderButton}
        >
          <ShareIcon />
        </TouchableHighlight>
      </Tooltip>
      <WebShareModal visible={modalVisible} onClose={hideModal} />
    </React.Fragment>
  );
}
