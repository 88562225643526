import React, { useRef } from 'react';
import { StyleSheet, Text } from 'react-native';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import groupPreviewEmojiReactionPositionState from '../../../../state/groupPreviewEmojiReactionPositionState';
import membershipState from '../../../../state/membershipState';
import pendingEmojiReactionState from '../../../../state/pendingEmojiReactionState';
import FloatingEffect from './FloatingEffect';

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  text: {
    fontSize: 18,
  },
});

const MEAN_DISTANCE = 50;

export default function GroupEmojiReaction({ id }: { id: string }) {
  const pendingEmojiReaction = useRecoilValue(pendingEmojiReactionState(id));

  const membership = useRecoilValue(
    membershipState(pendingEmojiReaction.membershipId)
  );
  const groupPreviewEmojiReactionPosition = useRecoilValue(
    groupPreviewEmojiReactionPositionState(membership.groupId)
  );

  const resetEmojiReaction = useResetRecoilState(pendingEmojiReactionState(id));

  const fromX = useRef(
    Math.random() *
      (groupPreviewEmojiReactionPosition.maxX -
        groupPreviewEmojiReactionPosition.minX) +
      groupPreviewEmojiReactionPosition.minX
  ).current;

  return (
    <FloatingEffect
      fromX={fromX}
      fromY={0}
      directionCenter={groupPreviewEmojiReactionPosition.directionCenter}
      directionRange={groupPreviewEmojiReactionPosition.directionRange}
      meanDistance={MEAN_DISTANCE}
      delay={pendingEmojiReaction.pendingDuration * 1000}
      onAnimationFinished={resetEmojiReaction}
      style={styles.root}
    >
      <Text style={styles.text}>{pendingEmojiReaction.emoji}</Text>
    </FloatingEffect>
  );
}
