import _ from 'lodash';
import { selector } from 'recoil';
import activeRemoteMembershipsState from './activeRemoteMembershipsState';

export enum NotificationEventType {
  WELCOME = 'welcome',
  ACTIVE_REMOTE_MEMBERSHIP_CREATED = 'active_remote_membership_created',
}

interface WelcomeNotificationEvent {
  type: NotificationEventType.WELCOME;
}
const WELCOME_NOTIFICATION_EVENT: WelcomeNotificationEvent = {
  type: NotificationEventType.WELCOME,
};
export interface ActiveRemoteMembershipCreatedEvent {
  type: NotificationEventType.ACTIVE_REMOTE_MEMBERSHIP_CREATED;
  membershipId: string;
}

export type NotificationEvent =
  | WelcomeNotificationEvent
  | ActiveRemoteMembershipCreatedEvent;

const notificationEventsState = selector<NotificationEvent[]>({
  key: 'notificationEventsState',
  get: ({ get }) => {
    const activeRemoteMemberships = get(activeRemoteMembershipsState);
    const activeRemoteMembershipNotificationEvents = _.map(
      activeRemoteMemberships,
      ({ id }) => ({
        type: NotificationEventType.ACTIVE_REMOTE_MEMBERSHIP_CREATED,
        membershipId: id,
      })
    );

    return [
      WELCOME_NOTIFICATION_EVENT,
      ...activeRemoteMembershipNotificationEvents,
    ];
  },
});
export default notificationEventsState;
