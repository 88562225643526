import Video from 'twilio-video';
import RemoteAudioTrack from './RemoteAudioTrack';

export default class RemoteAudioTrackPublication {
  twilioRemoteAudioTrackPublication: Video.RemoteAudioTrackPublication;
  remoteTrack?: RemoteAudioTrack;

  constructor(
    twilioRemoteAudioTrackPublication: Video.RemoteAudioTrackPublication
  ) {
    this.twilioRemoteAudioTrackPublication = twilioRemoteAudioTrackPublication;
    if (twilioRemoteAudioTrackPublication.isSubscribed) {
      this.onSubscribed();
    }
    twilioRemoteAudioTrackPublication.on('subscribed', this.onSubscribed);
  }

  get track(): RemoteAudioTrack {
    return this.remoteTrack;
  }

  get trackSid(): string {
    return this.twilioRemoteAudioTrackPublication.trackSid;
  }

  get trackName(): string {
    return this.twilioRemoteAudioTrackPublication.trackName;
  }

  onSubscribed = () => {
    this.remoteTrack = new RemoteAudioTrack(
      this.twilioRemoteAudioTrackPublication.track
    );
  };
}
