import md5 from 'md5';
import moment from 'moment';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import nextStableNotificationState, {
  Notification,
  NotificationType,
} from '../state/nextStableNotificationState';
import roomState from '../state/roomState';
import useRerender from './useRerender';

export default function useNextNotification(): Notification | null {
  const nextNotificationWithoutFreeTrialRoomExpiring = useRecoilValue(
    nextStableNotificationState
  );

  const room = useRecoilValue(roomState);
  const rerender = useRerender();
  const showFreeTrialRoomExpiringNotificationAt = room.willExpireAt
    ? moment(room.willExpireAt).subtract(5, 'minutes').valueOf()
    : null;
  useEffect(() => {
    if (showFreeTrialRoomExpiringNotificationAt) {
      const timeout = setTimeout(
        rerender,
        showFreeTrialRoomExpiringNotificationAt
      );
      return () => clearTimeout(timeout);
    }
  }, [showFreeTrialRoomExpiringNotificationAt, rerender]);

  if (nextNotificationWithoutFreeTrialRoomExpiring) {
    return nextNotificationWithoutFreeTrialRoomExpiring;
  }
  if (
    showFreeTrialRoomExpiringNotificationAt &&
    moment().valueOf() >= showFreeTrialRoomExpiringNotificationAt
  ) {
    return {
      type: NotificationType.FREE_TRIAL_ROOM_EXPIRING,
      notificationEvents: [],
    };
  }
  return null;
}

export function notificationId(notification: Notification | null) {
  if (!notification) {
    return null;
  }

  switch (notification.type) {
    case NotificationType.WELCOME:
    case NotificationType.ACTIVE_REMOTE_MEMBERSHIPS_CREATED:
      return md5(`${notification.type}-${notification.description}`);
    case NotificationType.FREE_TRIAL_ROOM_EXPIRING:
    case NotificationType.UPCOMING_DEPARTURE:
    case NotificationType.PROPOSED_EMPTY_GROUP_DEPARTURE:
      return md5(`${notification.type}`);
  }
}
