import _ from 'lodash';
import { selector } from 'recoil';
import { Departure } from './departureState';
import groupsState from './groupsState';

const departuresState = selector<Departure[]>({
  key: 'departuresState',
  get: ({ get }) => {
    return _.flatMap(get(groupsState), 'departures');
  },
});
export default departuresState;
