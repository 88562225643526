import React from 'react';
import { SafeAreaView, Text, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { isMobile } from '../../config/constants';
import { UpdateProfileAttributes } from '../../config/profileApi';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import { useUpdateProfileMutation } from '../../hooks/useProfile';
import draftProfileState from '../../state/draftProfileState';
import BodyText from '../BodyText';
import Header from '../Header';
import PrimaryButton from '../PrimaryButton';
import ResponsiveContainer from '../ResponsiveContainer';
import SecondaryButton from '../SecondaryButton';
import WorkflowNavigationHeader from '../workflows/WorkflowNavigationHeader';
import DraftProfileAvatarUriPreview from './DraftProfileAvatarUriPreview';

interface Props {
  onBackPressed: () => void;
  onConfirmed?: () => void;
}

export default function ConfirmDraftProfile({
  onBackPressed,
  onConfirmed,
}: Props) {
  const draftProfile = useRecoilValue(draftProfileState);
  const { mutateAsync, isLoading, isError, error } = useUpdateProfileMutation();

  const onSavePressed = async () => {
    await mutateAsync(draftProfile as UpdateProfileAttributes);
    if (onConfirmed) {
      onConfirmed();
    }
  };

  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <WorkflowNavigationHeader onBackPressed={onBackPressed} />
      <View style={workflowScreenStyles.content}>
        <ResponsiveContainer>
          <View style={workflowScreenStyles.group}>
            <Header text="How does this look?" />
            <BodyText
              text={`Looking sharp, ${draftProfile?.name}. You can always edit this later.`}
            />
            <DraftProfileAvatarUriPreview avatarUri={draftProfile?.avatarUri} />
            {isError && (
              <Text style={workflowScreenStyles.errorText}>{error}</Text>
            )}
          </View>
          <View style={workflowScreenStyles.actions}>
            {isMobile && (
              <SecondaryButton title="Retake" onPress={onBackPressed} />
            )}
            <PrimaryButton
              title={isLoading ? 'Saving...' : 'Looks good!'}
              onPress={onSavePressed}
              isSubmitting={isLoading}
            />
            {!isMobile && (
              <SecondaryButton title="Retake" onPress={onBackPressed} />
            )}
          </View>
        </ResponsiveContainer>
      </View>
    </SafeAreaView>
  );
}
