import React from 'react';
import { useRecoilState } from 'recoil';
import videoButtonStyles from '../../../config/videoButtonStyles';
import mutedState from '../../../state/mutedState';
import BottomBarTouchableHighlight from './BottomBarTouchableHighlight';
import { AudioIcon } from '../../Icons';
import Tooltip from '../../Tooltip';

export default function BottomBarMuteButton() {
  const [audioMuted, setAudioMuted] = useRecoilState(mutedState);

  return (
    <Tooltip text={audioMuted ? 'Turn on mic' : 'Turn off mic'}>
      <BottomBarTouchableHighlight
        style={[
          videoButtonStyles.action,
          audioMuted ? videoButtonStyles.actionHighlight : null,
        ]}
        onPress={() => setAudioMuted(!audioMuted)}
      >
        <AudioIcon enabled={!audioMuted} />
      </BottomBarTouchableHighlight>
    </Tooltip>
  );
}
