import _ from 'lodash';
import { selector } from 'recoil';
import { NotificationEvent } from './notificationEventsState';
import pastNotificationsState from './pastNotificationsState';

const pastNotificationEventsState = selector<NotificationEvent[]>({
  key: 'pastNotificationEventsState',
  get: ({ get }) => {
    return _.flatMap(get(pastNotificationsState), 'notificationEvents');
  },
});
export default pastNotificationEventsState;
