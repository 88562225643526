import _ from 'lodash';
import { DefaultValue, selector } from 'recoil';
import draftProfileState from './draftProfileState';

const draftProfileAvatarUriState = selector<string>({
  key: 'draftProfileAvatarUriState',
  get: ({ get }) => {
    return get(draftProfileState).avatarUri;
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(draftProfileState, _.omit(get(draftProfileState), 'avatarUri'));
    } else {
      set(draftProfileState, {
        ...get(draftProfileState),
        avatarUri: newValue,
      });
    }
  },
});
export default draftProfileAvatarUriState;
