import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_QUERY_KEY } from '../config/Api';
import config from '../config/config';
import { createGuestSession } from '../config/guestUserSessionApi';
import {
  getProfile,
  Profile,
  updateProfile,
  UpdateProfileAttributes,
} from '../config/profileApi';
import AUTHORIZATION_STORE from '../models/AuthorizationStore';

const PROFILE_QUERY_KEY = [API_QUERY_KEY, 'PROFILE_QUERY_KEY'];

export default function useProfile() {
  const profileQuery = useProfileQuery();
  if (!profileQuery.data) {
    throw 'something went wrong!';
  }
  return profileQuery.data;
}

export function useProfileQuery() {
  return useQuery(PROFILE_QUERY_KEY, getProfile);
}

export function useInvalidateProfile() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(PROFILE_QUERY_KEY);
}

export function useUpdateProfileMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async (profile: UpdateProfileAttributes) => {
      const existingAuthentication = await AUTHORIZATION_STORE.getAsync();
      if (!existingAuthentication) {
        await createGuestSession();
      }
      return updateProfile(profile);
    },
    {
      onSuccess: (profile) => {
        queryClient.setQueryData(PROFILE_QUERY_KEY, profile);
      },
    }
  );
}

export function profileIsGuest(profile: Profile) {
  return !profile || profile?.guest;
}

export function profileAvatarSource({ avatarPath }: { avatarPath?: string }) {
  if (!avatarPath) {
    return { uri: null };
  }
  return { uri: `${config().apiUrl}${avatarPath}` };
}
