import Lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';

interface Props {
  loop: boolean;
  autoPlay: boolean;
  source: any;
}

export default function LottieView({ source, autoPlay, loop }: Props) {
  const ref = useRef();

  useEffect(() => {
    Lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop,
      autoplay: autoPlay,
      animationData: source,
    });
  }, [source, autoPlay, loop]);

  return <div ref={ref} />;
}
