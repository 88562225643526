import _ from 'lodash';

export interface VolatileResourceFactory<Resource, CreateParams> {
  create: (params: CreateParams) => Promise<Resource>;
  destroy: (resource: Resource) => Promise<void>;
  shouldRecreateResource?: (
    oldCreateParams: CreateParams,
    newCreateParams: CreateParams
  ) => boolean;
}

class VolatileResourceOperation<Resource, CreateParams> {
  previous?: VolatileResourceOperation<Resource, CreateParams>;
  createParams: CreateParams;

  volatileResourceFactory: VolatileResourceFactory<Resource, CreateParams>;

  resolve: (result: Resource) => void;
  reject: (reason: any) => void;
  promise: Promise<Resource>;
  isLoading = true;

  constructor({
    volatileResourceFactory,
    createParams,
    previous,
  }: {
    createParams: CreateParams;
    volatileResourceFactory: VolatileResourceFactory<Resource, CreateParams>;
    previous?: VolatileResourceOperation<Resource, CreateParams>;
  }) {
    this.volatileResourceFactory = volatileResourceFactory;
    this.createParams = createParams;
    this.previous = previous;

    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });

    this.start();
  }

  start() {
    const createResource = () =>
      this.volatileResourceFactory
        .create(this.createParams)
        .then(this.resolve, this.reject)
        .finally(() => (this.isLoading = false));
    if (this.previous) {
      this.previous
        .destroy()
        .then(() => {
          delete this.previous;
        })
        .then(createResource);
    } else {
      createResource();
    }
  }

  async destroy() {
    try {
      const resource = await this.promise;
      await this.volatileResourceFactory.destroy(resource);
    } catch {}
  }
}

export default class VolatileResourceStore<Resource, CreateParams> {
  createParams?: CreateParams;
  currentOperation?: VolatileResourceOperation<Resource, CreateParams>;

  isSubscribed: boolean = false;
  unsubscribedCleanupPromise?: Promise<void>;

  volatileResourceFactory: VolatileResourceFactory<Resource, CreateParams>;

  contents?: Resource;
  error?: any;

  subscribers = new Map<number, () => void>();
  lastSubscriberId = 0;

  constructor(
    volatileResourceFactory: VolatileResourceFactory<Resource, CreateParams>
  ) {
    this.volatileResourceFactory = volatileResourceFactory;
  }

  setCreateParams(createParams: CreateParams) {
    const oldCreateParams = this.createParams;
    this.createParams = createParams;

    if (!this.hasSubscribers() || this.unsubscribedCleanupPromise) {
      return;
    }

    if (
      this.shouldRecreateResource(oldCreateParams, createParams) &&
      !this.currentOperation?.isLoading
    ) {
      this.enqueueOperation();
    }
  }

  get = () => {
    if (!this.createParams) {
      return;
    }
    if (this.contents) {
      return this.contents;
    }
    if (this.error) {
      throw this.error;
    }
  };

  subscribe(fn: () => void) {
    const id = this.lastSubscriberId + 1;
    this.lastSubscriberId = id;
    this.subscribers.set(id, fn);

    this.syncSubscriptionStatus();

    return id;
  }

  unsubscribe(id: number) {
    this.subscribers.delete(id);
    this.syncSubscriptionStatus();
  }

  syncSubscriptionStatus = () => {
    if (this.currentOperation?.isLoading || this.unsubscribedCleanupPromise) {
      return;
    }

    if (!this.hasSubscribers() && this.isSubscribed) {
      this.unsubscribedCleanupPromise = this.currentOperation
        ?.destroy()
        .then(() => {
          delete this.unsubscribedCleanupPromise;
          delete this.currentOperation;
          delete this.contents;
          delete this.error;

          this.syncSubscriptionStatus();
        });

      this.isSubscribed = false;
      return;
    }

    if (this.hasSubscribers() && !this.isSubscribed) {
      this.isSubscribed = true;
      if (!this.currentOperation) {
        this.enqueueOperation();
      }
      return;
    }
  };

  enqueueOperation() {
    const operation = new VolatileResourceOperation<Resource, CreateParams>({
      createParams: this.createParams,
      previous: this.currentOperation,
      volatileResourceFactory: this.volatileResourceFactory,
    });
    this.currentOperation = operation;
    operation.promise
      .then(
        this.onOperationResolve(operation),
        this.onOperationError(operation)
      )
      .then(this.syncSubscriptionStatus);
  }

  onOperationResolve = (
    operation: VolatileResourceOperation<Resource, CreateParams>
  ) => (contents: Resource) => {
    if (
      this.shouldRecreateResource(operation.createParams, this.createParams)
    ) {
      this.enqueueOperation();
    } else {
      this.contents = contents;
      delete this.error;
      this.subscribers.forEach((fn) => fn());
    }
  };

  onOperationError = (
    operation: VolatileResourceOperation<Resource, CreateParams>
  ) => (error: any) => {
    if (
      this.shouldRecreateResource(operation.createParams, this.createParams)
    ) {
      this.enqueueOperation();
    } else {
      delete this.contents;
      this.error = error;
      this.subscribers.forEach((fn) => fn());
    }
  };

  shouldRecreateResource = (
    oldCreateParams: CreateParams,
    newCreateParams: CreateParams
  ) => {
    if (!this.hasSubscribers()) {
      return false;
    }

    if (_.has(this.volatileResourceFactory, 'shouldRecreateResource')) {
      return this.volatileResourceFactory.shouldRecreateResource(
        oldCreateParams,
        newCreateParams
      );
    } else {
      return !_.isEqual(oldCreateParams, newCreateParams);
    }
  };

  hasSubscribers = () => this.subscribers.size !== 0;
}
