import { useEffect } from 'react';
import { NATIVE_APP_URI_SCHEME } from '../components/StoopScreens';

export default function useAttemptNativeAppRedirect() {
  useEffect(() => {
    const isMobileUserAgent = /android|ipad|iphone|ipod/i.test(
      navigator.userAgent
    );
    if (!isMobileUserAgent) {
      return;
    }

    const url = new URL(window.location.href);
    const nativeAppUriSchemeUrl = `${NATIVE_APP_URI_SCHEME}${url.pathname}${url.search}`;
    const iframe = document.createElement('iframe');
    iframe.src = nativeAppUriSchemeUrl;
    document.body.appendChild(iframe);
    iframe.remove();
  }, []);
}
