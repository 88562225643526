import React from 'react';
import { Text } from 'react-native';
import { homeScreenActionsButtonStyles } from '../../config/homeScreenActionsButtonStyles';
import { useShowMonetizationFlow } from '../../state/monetizationFlowVisibleState';
import { PaymentIcon } from '../Icons';
import HomeScreenButton from './HomeScreenButton';

export default function SubscribeButton() {
  const showMonetizationFlow = useShowMonetizationFlow();

  return (
    <HomeScreenButton icon={<PaymentIcon />} onPress={showMonetizationFlow}>
      <Text style={homeScreenActionsButtonStyles.buttonText}>
        Subscribe for limitless rooms
      </Text>
    </HomeScreenButton>
  );
}
