import _ from 'lodash';
import { selectorFamily } from 'recoil';
import {
  UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_HEIGHT,
  UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_MARGIN,
} from '../components/room/video_grid/DepartureVideoGridCell';
import { DEFAULT_SPACING } from '../config/constants';
import departureMembershipsState from './departureMembershipsState';
import { Frame, videoGridCellFramesWithinFrame } from './roomLayoutState';
import { layoutSizeCandidatesForCellsCount } from './roomVideoGridLayoutSizeCandidatesState';
import videoGridDepartureCellLayoutState from './videoGridDepartureCellLayoutState';

interface DepartureLayout {
  cellSize: number;
  videoGridCellFrames: Frame[];
}

const departureVideoGridLayoutState = selectorFamily<DepartureLayout, string>({
  key: 'departureVideoGridLayoutState',
  get: (id) => ({ get }) => {
    const videoGridDepartureCellLayout = get(
      videoGridDepartureCellLayoutState(id)
    );
    const departureMembershipsSize = _.size(get(departureMembershipsState(id)));
    const unscaledLayoutCandidates = layoutSizeCandidatesForCellsCount(
      departureMembershipsSize
    );

    const departureVideoGridFrame = {
      x:
        videoGridDepartureCellLayout.x +
        UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_MARGIN,
      y:
        videoGridDepartureCellLayout.y +
        UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_HEIGHT,
      width:
        videoGridDepartureCellLayout.width -
        UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_MARGIN * 2,
      height:
        videoGridDepartureCellLayout.height -
        UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_HEIGHT -
        UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_MARGIN,
    };

    const layoutCandidates = _.map(
      unscaledLayoutCandidates,
      (unscaledLayoutCandidate) => {
        const columnsCount = unscaledLayoutCandidate.maxRowSize;
        const rowsCount = unscaledLayoutCandidate.rowsCount;

        const horizontalMargin = (columnsCount - 1) * DEFAULT_SPACING;
        const verticalMargin = (rowsCount - 1) * DEFAULT_SPACING;

        const cellSizeConstrainedByWidth =
          (departureVideoGridFrame.width - horizontalMargin) / columnsCount;
        const cellSizeConstrainedByHeight =
          (departureVideoGridFrame.height - verticalMargin) / rowsCount;

        return {
          videoGridLayout: {
            ...unscaledLayoutCandidate,
          },
          cellSize: _.min([
            cellSizeConstrainedByWidth,
            cellSizeConstrainedByHeight,
          ]),
        };
      }
    );

    const optimalCandidate = _.maxBy(layoutCandidates, 'cellSize');

    return {
      cellSize: optimalCandidate.cellSize,
      videoGridCellFrames: videoGridCellFramesWithinFrame(
        optimalCandidate.videoGridLayout,
        optimalCandidate.cellSize,
        departureVideoGridFrame
      ),
    };
  },
});
export default departureVideoGridLayoutState;
