import { selector } from 'recoil';
import roomLayoutState from './roomLayoutState';
import roomState from './roomState';
import { VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE } from './videoGridMembershipCellLayoutState';

const videoGridCellScaleState = selector({
  key: 'videoGridCellScaleState',
  get: ({ get }) => {
    if (!get(roomState)) {
      return 1;
    }
    return (
      get(roomLayoutState).cellSize /
      VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE
    );
  },
});
export default videoGridCellScaleState;
