import { selectorFamily } from 'recoil';
import roomLayoutState, { Frame } from './roomLayoutState';

export const EMPTY_FRAME = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

const videoGridCellLayoutByCellIndexState = selectorFamily<Frame, number>({
  key: 'videoGridCellLayoutByCellIndexState',
  get: (cellIndex) => ({ get }) => {
    const roomLayout = get(roomLayoutState);
    return roomLayout.videoGridCellFrames[cellIndex];
  },
});
export default videoGridCellLayoutByCellIndexState;
