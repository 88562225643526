import React, { useMemo } from 'react';
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleSheet,
  Text,
} from 'react-native';
import { DEFAULT_SPACING, GREEN } from '../config/constants';

interface Props extends PressableProps {
  title: string;
  color?: string;
}

export default function SecondaryButton({
  title,
  style,
  color,
  ...restProps
}: Props) {
  const borderColorStyle = useMemo(
    () => ({
      borderColor: color ?? GREEN,
    }),
    [color]
  );
  const textColorStyle = useMemo(
    () => ({
      color: color ?? GREEN,
    }),
    [color]
  );

  const pressableStyle =
    typeof style === 'function'
      ? (state: PressableStateCallbackType) => [
          styles.button,
          borderColorStyle,
          style(state),
        ]
      : [styles.button, borderColorStyle, style];

  return (
    <Pressable style={pressableStyle} {...restProps}>
      <Text style={[styles.buttonText, textColorStyle]}>{title}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: DEFAULT_SPACING,
    minHeight: DEFAULT_SPACING * 5,
    borderRadius: DEFAULT_SPACING,
    borderWidth: 2,
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: DEFAULT_SPACING * 2,
  },
});
