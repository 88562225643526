import { selectorFamily } from 'recoil';
import groupIdState from './groupIdState';
import remoteMembershipLocallyMutedState from './remoteMembershipLocallyMutedState';
import remoteMembershipState from './remoteMembershipState';

const remoteMembershipAudioEnabledState = selectorFamily<boolean, string>({
  key: 'remoteMembershipAudioEnabledState',
  get: (id) => {
    return ({ get }) => {
      const remoteMembership = get(remoteMembershipState(id));
      const mutedLocally = get(remoteMembershipLocallyMutedState(id));
      const groupId = get(groupIdState);

      return (
        !mutedLocally &&
        (remoteMembership.groupId === groupId || remoteMembership.shouting)
      );
    };
  },
});
export default remoteMembershipAudioEnabledState;
