import { selector } from 'recoil';
import roomState from './roomState';

const roomScreenSharingMembershipExistsState = selector({
  key: 'roomScreenSharingMembershipExistsState',
  get: ({ get }) => {
    return !!get(roomState)?.screenSharingMembershipId;
  },
});
export default roomScreenSharingMembershipExistsState;
