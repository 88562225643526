import _ from 'lodash';
import { selector } from 'recoil';
import selectedRemoteMembershipIdsState from './selectedRemoteMembershipIdsState';

const someRemoteMembershipSelectedState = selector<boolean>({
  key: 'someRemoteMembershipSelectedState',
  get: ({ get }) => {
    return _.some(get(selectedRemoteMembershipIdsState));
  },
});
export default someRemoteMembershipSelectedState;
