import React, { PropsWithChildren } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { GRAY_ACTION, GREEN_SECONDARY_ACTION } from '../../../config/constants';
import videoButtonStyles from '../../../config/videoButtonStyles';
import { PlusIcon } from '../../Icons';

type Props = PropsWithChildren<{
  highlighted: boolean;
  style?: ViewStyle;
}>;

const styles = StyleSheet.create({
  unhighlighted: {
    backgroundColor: GRAY_ACTION,
  },
  highlighted: {
    backgroundColor: GREEN_SECONDARY_ACTION,
  },
});

export default function GroupListItemActionButton({
  children,
  highlighted,
  style,
}: Props) {
  return (
    <View
      style={[
        videoButtonStyles.join,
        highlighted ? styles.highlighted : styles.unhighlighted,
        style,
      ]}
    >
      {children || <PlusIcon />}
    </View>
  );
}
