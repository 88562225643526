import { selector } from 'recoil';
import { Group } from './groupState';
import roomState from './roomState';

const groupsState = selector<Group[]>({
  key: 'groupsState',
  get: ({ get }) => {
    return get(roomState)?.groups ?? [];
  },
});
export default groupsState;
