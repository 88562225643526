import { StyleSheet } from 'react-native';
import { DEFAULT_SPACING } from './constants';

const roomScreenSharingStyles = StyleSheet.create({
  root: {
    paddingTop: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING,
    flex: 1,
    alignSelf: 'stretch',
  },
  video: {
    alignSelf: 'center',
  },
  avatarImage: {
    position: 'absolute',
    bottom: DEFAULT_SPACING,
    left: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING,
    width: 44,
    height: 44,
  },
});
export default roomScreenSharingStyles;
