import _ from 'lodash';
import { indexAvailableStripeSubscriptions } from '../config/availableStripeSubscriptionsApi';

class InAppPurchases {
  getProductsAsync = async (productIds: string[]) => {
    const allAvailableStripeSubscriptions = await indexAvailableStripeSubscriptions();
    const availableStripeSubscriptions = _.compact(
      _.map(productIds, (productId) =>
        _.find(
          allAvailableStripeSubscriptions,
          (availableStripeSubscription) =>
            availableStripeSubscription.productId === productId
        )
      )
    );

    return {
      responseCode: IAPResponseCode.OK,
      results: availableStripeSubscriptions,
    };
  };
}

const INSTANCE = new InAppPurchases();
function get() {
  return Promise.resolve(INSTANCE);
}
const InAppPurchasesStore = { get };
export default InAppPurchasesStore;

export interface IAPQueryResponse {
  responseCode: IAPResponseCode;
  results?: (InAppPurchase | IAPItemDetails)[];
}

export enum IAPResponseCode {
  OK = 0,
  USER_CANCELED = 1,
  ERROR = 2,
  DEFERRED = 3,
}

export interface InAppPurchase {
  acknowledged: boolean;
  productId: string;
  purchaseState: number;
  purchaseTime: number;
  orderId: string;
  packageName?: string;
  purchaseToken?: string;
  originalOrderId?: string;
  originalPurchaseTime?: string;
  transactionReceipt?: string;
}
export interface IAPItemDetails {
  description: string;
  price: string;
  priceAmountMicros: number;
  priceCurrencyCode: string;
  productId: string;
  title: string;
  subscriptionPeriod?: string;
}
