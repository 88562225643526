import _ from 'lodash';
import { selector } from 'recoil';
import groupIdState from './groupIdState';
import groupSortedMembershipIdsState from './groupSortedMembershipIdsState';
import membershipDepartureState from './membershipDepartureState';

const videoGridMembershipIdsState = selector({
  key: 'videoGridMembershipIdsState',
  get: ({ get }) => {
    const videoGridMembershipIdsIncludingDeparting = get(
      groupSortedMembershipIdsState(get(groupIdState))
    );
    const remainingMembershipIds = _.filter(
      videoGridMembershipIdsIncludingDeparting,
      (membershipId) => {
        return !get(membershipDepartureState(membershipId));
      }
    );
    return remainingMembershipIds;
  },
});
export default videoGridMembershipIdsState;
