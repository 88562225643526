import EventEmitter from 'eventemitter3';
import _ from 'lodash';
import Video from 'twilio-video';
import LocalAudioTrack from './LocalAudioTrack';
import LocalParticipant from './LocalParticipant';
import LocalVideoTrack from './LocalVideoTrack';
import RemoteParticipant from './RemoteParticipant';

const rooms: Room[] = [];

export function getRooms(): Room[] {
  return rooms;
}

interface RoomConnectOptions {
  roomName?: string;
  audioTracks?: LocalAudioTrack[];
  videoTracks?: LocalVideoTrack[];
  isAutomaticSubscriptionEnabled?: boolean;
  isNetworkQualityEnabled?: boolean;
  isInsightsEnabled?: boolean;
  networkQualityConfiguration?: {
    local: 'minimal' | 'none';
    remote: 'minimal' | 'none';
  };
  isDominantSpeakerEnabled?: boolean;
  encodingParameters?: {
    audioBitrate: number;
    videoBitrate: number;
  };
  bandwidthProfile?: Video.BandwidthProfileOptions;
  preferredVideoCodecs?: Video.VideoCodecSettings[];
}

export default class Room {
  eventEmitter: EventEmitter = new EventEmitter();
  twilioRoomPromise: Promise<Video.Room>;
  twilioRoom?: Video.Room;
  twilioRoomConnectError?: any;
  localParticipant?: LocalParticipant;
  remoteParticipantsBySid = new Map<string, RemoteParticipant>();

  constructor(twilioRoomPromise: Promise<Video.Room>) {
    this.twilioRoomPromise = twilioRoomPromise;
    twilioRoomPromise.then(
      (twilioRoom) => {
        this.setTwilioRoom(twilioRoom);
      },
      (error: any) => {
        this.twilioRoomConnectError = error;
        this.eventEmitter.emit('failedToConnect', { room: this, error });
      }
    );
  }

  setTwilioRoom = (twilioRoom: Video.Room) => {
    this.twilioRoom = twilioRoom;
    this.localParticipant = new LocalParticipant(twilioRoom.localParticipant);
    twilioRoom.participants.forEach((twilioRemoteParticipant) => {
      const remoteParticipant = new RemoteParticipant(twilioRemoteParticipant);
      this.remoteParticipantsBySid.set(
        remoteParticipant.sid,
        remoteParticipant
      );
    });

    twilioRoom.on('disconnected', this.onDisconnected);
    twilioRoom.on('reconnecting', this.onReconnecting);
    twilioRoom.on('reconnected', this.onReconnected);
    twilioRoom.on('participantConnected', this.onParticipantConnected);
    twilioRoom.on('participantDisconnected', this.onParticipantDisconnected);
    twilioRoom.on('recordingStarted', this.onRecordingStarted);
    twilioRoom.on('recordingStopped', this.onRecordingStopped);
    twilioRoom.on('dominantSpeakerChanged', this.onDominantSpeakerChanged);

    this.eventEmitter.emit('connected', { room: this });
  };

  on(event: string, fn: (...args: any[]) => void) {
    this.eventEmitter.on(event, fn);
  }

  off(event: string, fn: (...args: any[]) => void) {
    this.eventEmitter.off(event, fn);
  }

  onDisconnected = (_room: Video.Room, error: Video.TwilioError) => {
    this.eventEmitter.emit('disconnected', { room: this, error });
  };

  onReconnecting = (error: any) => {
    this.eventEmitter.emit('reconnecting', { room: this, error });
  };

  onReconnected = () => {
    this.eventEmitter.emit('reconnected', { room: this });
  };

  onParticipantConnected = (
    twilioRemoteParticipant: Video.RemoteParticipant
  ) => {
    const participant = new RemoteParticipant(twilioRemoteParticipant);
    this.remoteParticipantsBySid.set(participant.sid, participant);
    this.eventEmitter.emit('participantConnected', { room: this, participant });
  };

  onParticipantDisconnected = (
    twilioRemoteParticipant: Video.RemoteParticipant
  ) => {
    const participant = this.remoteParticipantsBySid.get(
      twilioRemoteParticipant.sid
    );
    if (!participant) {
      console.error(
        'Participant disconnected, but was never connected to begin with',
        twilioRemoteParticipant
      );
      return;
    }
    this.eventEmitter.emit('participantDisconnected', {
      room: this,
      participant,
    });
    this.remoteParticipantsBySid.delete(twilioRemoteParticipant.sid);
  };

  onRecordingStarted = () => {
    this.eventEmitter.emit('recordingStarted', { room: this });
  };

  onRecordingStopped = () => {
    this.eventEmitter.emit('recordingStopped', { room: this });
  };

  onDominantSpeakerChanged = (
    dominantSpeaker: Video.RemoteParticipant | null
  ) => {
    const participant = dominantSpeaker
      ? this.remoteParticipantsBySid.get(dominantSpeaker.sid)
      : null;
    this.eventEmitter.emit('dominantSpeakerChanged', {
      room: this,
      participant,
    });
  };

  static connect = async (token: string, options: RoomConnectOptions) => {
    const twilioOptions: Video.ConnectOptions = { audio: false, video: false };

    if (_.has(options, 'roomName')) {
      twilioOptions.name = options.roomName;
    }
    if (_.has(options, 'audioTracks') || _.has(options, 'videoTracks')) {
      twilioOptions.tracks = [
        ...(_.map(options.audioTracks, 'twilioLocalAudioTrack') || []),
        ...(_.map(options.videoTracks, 'twilioLocalVideoTrack') || []),
      ];
    }
    if (_.has(options, 'isAutomaticSubscriptionEnabled')) {
      twilioOptions.automaticSubscription =
        options.isAutomaticSubscriptionEnabled;
    }
    if (_.has(options, 'isNetworkQualityEnabled')) {
      if (_.has(options, 'networkQualityConfiguration')) {
        const { networkQualityConfiguration } = options;

        twilioOptions.networkQuality = {
          local: networkQualityConfiguration.local === 'minimal' ? 1 : 0,
          remote: networkQualityConfiguration.remote === 'minimal' ? 1 : 0,
        };
      } else {
        twilioOptions.networkQuality = true;
      }
    }
    if (_.has(options, 'isInsightsEnabled')) {
      twilioOptions.insights = options.isInsightsEnabled;
    }
    if (_.has(options, 'isDominantSpeakerEnabled')) {
      twilioOptions.dominantSpeaker = options.isDominantSpeakerEnabled;
    }
    if (_.has(options, 'encodingParameters')) {
      twilioOptions.maxAudioBitrate = options.encodingParameters.audioBitrate;
      twilioOptions.maxVideoBitrate = options.encodingParameters.videoBitrate;
    }
    if (_.has(options, 'bandwidthProfile')) {
      twilioOptions.bandwidthProfile = options.bandwidthProfile;
    }
    if (_.has(options, 'preferredVideoCodecs')) {
      twilioOptions.preferredVideoCodecs = options.preferredVideoCodecs;
    }

    const room = new Room(Video.connect(token, twilioOptions));
    rooms.push(room);
    return room;
  };

  get sid(): string {
    return this.twilioRoom?.sid;
  }

  get name(): string {
    return this.twilioRoom?.name;
  }

  get state(): string {
    if (this.twilioRoomConnectError) {
      return 'disconnected';
    } else if (!this.twilioRoom) {
      return 'connecting';
    } else {
      return this.twilioRoom.state;
    }
  }

  get isRecording(): boolean {
    return this.twilioRoom?.isRecording;
  }

  get remoteParticipants(): RemoteParticipant[] {
    return Array.from(this.remoteParticipantsBySid.values());
  }

  disconnect() {
    this.twilioRoom?.disconnect();
  }
}
