import React from 'react';
import { TouchableHighlight } from 'react-native';
import buttonStyles from '../../../config/buttonStyles';
import { useShowMonetizationFlow } from '../../../state/monetizationFlowVisibleState';
import { TimerIcon } from '../../Icons';
import Tooltip from '../../Tooltip';

export default function TimerButon() {
  const showMonetizationFlow = useShowMonetizationFlow();

  return (
    <Tooltip text="Free trial will expire" position="bottom-end">
      <TouchableHighlight
        style={buttonStyles.roomHeaderButton}
        onPress={showMonetizationFlow}
      >
        <TimerIcon />
      </TouchableHighlight>
    </Tooltip>
  );
}
