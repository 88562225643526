import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import activeRemoteMembershipsState from '../../../state/activeRemoteMembershipsState';
import RemoteMembershipAudioSubscription from './RemoteMembershipAudioSubscription';

export default function RemoteMembershipAudioSubscriptions() {
  const remoteMemberships = useRecoilValue(activeRemoteMembershipsState);
  return (
    <React.Fragment>
      {_.map(remoteMemberships, ({ id }) => (
        <RemoteMembershipAudioSubscription id={id} key={id} />
      ))}
    </React.Fragment>
  );
}
