import React, { PropsWithChildren } from 'react';
import { LayoutChangeEvent, Pressable, StyleSheet, Text } from 'react-native';
import { DEFAULT_SPACING, GRAY, GREEN } from '../../../config/constants';

type Props = PropsWithChildren<{
  title: string;
  highlighted: boolean;
  onPress: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}>;

const styles = StyleSheet.create({
  root: {
    padding: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING,
    marginRight: DEFAULT_SPACING,
    alignItems: 'flex-start',
  },
  unhighlightedRoot: {
    backgroundColor: GRAY,
  },
  highlightedRoot: {
    backgroundColor: GREEN,
  },
  title: {
    color: 'white',
    marginBottom: DEFAULT_SPACING,
  },
});

export default function GroupListItem({
  title,
  children,
  highlighted,
  onPress,
  onLayout,
}: Props) {
  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.root,
        highlighted ? styles.highlightedRoot : styles.unhighlightedRoot,
      ]}
      onLayout={onLayout}
    >
      <Text style={styles.title}>{title}</Text>
      {children}
    </Pressable>
  );
}
