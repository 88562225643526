import _ from 'lodash';
import React, { useCallback } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import groupIdState from '../../../state/groupIdState';
import groupSortedMembershipIdsState from '../../../state/groupSortedMembershipIdsState';
import pendingEmojiReactionsState from '../../../state/pendingEmojiReactionsState';
import videoGridDeparturesState from '../../../state/videoGridDeparturesState';
import videoGridSizeState from '../../../state/videoGridSizeState';
import DepartureVideoGridCell from './DepartureVideoGridCell';
import EmojiReaction from './emoji_reactions/EmojiReaction';
import MembershipVideoGridCell from './MembershipVideoGridCell';
import RoomScreenSharingVideo from './RoomScreenSharingVideo';

export default function VideoGrid() {
  const groupId = useRecoilValue(groupIdState);
  const videoGridMembershipIds = useRecoilValue(
    groupSortedMembershipIdsState(groupId)
  );
  const videoGridDepartures = useRecoilValue(videoGridDeparturesState);
  const pendingEmojiReactions = useRecoilValue(pendingEmojiReactionsState);

  const setVideoGridSize = useSetRecoilState(videoGridSizeState);
  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width, height },
      },
    }: LayoutChangeEvent) => {
      setVideoGridSize({
        width,
        height,
      });
    },
    [setVideoGridSize]
  );

  return (
    <SafeAreaView edges={['left', 'right']} style={styles.root}>
      <View style={styles.videos} onLayout={onLayout}>
        {_.map(videoGridDepartures, ({ id }) => (
          <DepartureVideoGridCell key={id} id={id} />
        ))}
        {_.map(videoGridMembershipIds, (id) => (
          <MembershipVideoGridCell key={id} id={id} />
        ))}
        <RoomScreenSharingVideo />
        {_.map(pendingEmojiReactions, (pendingEmojiReaction) => (
          <EmojiReaction
            id={pendingEmojiReaction.id}
            key={pendingEmojiReaction.id}
          />
        ))}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignSelf: 'stretch',
    overflow: 'hidden',
  },
  videos: {
    flex: 1,
    alignSelf: 'stretch',
    overflow: 'hidden',
    margin: DEFAULT_SPACING,
  },
});
