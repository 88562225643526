import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import screenStyles from '../../config/screenStyles';
import useProfile from '../../hooks/useProfile';
import { Screen, ScreenStack } from '../../react-native-screens';
import SignInForm from '../SignInForm';
import GuestUserUpgradeForm from './GuestUserUpgradeForm';
import SubscriptionForm from './SubscriptionForm';
import Success from './Success';

export default function MonetizationScreens() {
  const profile = useProfile();
  const [signInSelected, setSignInSelected] = useState(false);

  const screens = useMemo(
    () =>
      _.compact(
        _.flattenDeep([
          profile.guest && [
            <Screen
              key="guest_user_upgrade_form"
              style={screenStyles.screen}
              replaceAnimation="push"
            >
              <GuestUserUpgradeForm
                onSignInPressed={() => setSignInSelected(true)}
              />
            </Screen>,
            signInSelected && (
              <Screen
                key="sign_in_form"
                onDismissed={() => setSignInSelected(false)}
                style={screenStyles.screen}
                replaceAnimation="push"
              >
                <SignInForm onBackPressed={() => setSignInSelected(false)} />
              </Screen>
            ),
          ],
          !profile.guest && !profile.subscribed && (
            <Screen
              key="subscription_form"
              style={screenStyles.screen}
              replaceAnimation="push"
            >
              <SubscriptionForm />
            </Screen>
          ),
          !profile.guest && profile.subscribed && (
            <Screen
              key="success"
              style={screenStyles.screen}
              replaceAnimation="push"
            >
              <Success />
            </Screen>
          ),
        ])
      ),
    [profile, signInSelected]
  );

  return <ScreenStack style={screenStyles.root}>{screens}</ScreenStack>;
}
