import _ from 'lodash';
import { SCREEN_SHARING_TRACK_NAME_PREFIX } from '../config/constants';
import { RemoteConnection } from '../contexts/RemoteConnectionsContext';
import { Connection, ConnectionSubscription } from '../hooks/useConnection';
import { AUDIO_TRACK_NAME_PREFIX } from '../hooks/useLocalAudioTrack';
import { VIDEO_TRACK_NAME_PREFIX } from '../hooks/useLocalVideoTrack';
import { RemoteMembership } from '../state/remoteMembershipState';
import {
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteVideoTrack,
} from '../twilio';

export default class TwilioRemoteParticipantConnection {
  remoteParticipant: RemoteParticipant;
  remoteMembership: RemoteMembership;
  subscription: ConnectionSubscription;

  onChange: (
    twilioRemoteParticipantConnection: TwilioRemoteParticipantConnection
  ) => void;

  constructor(
    remoteParticipant: RemoteParticipant,
    membershipId: string,
    connection: Connection
  ) {
    this.remoteParticipant = remoteParticipant;
    this.remoteMembership = null;

    remoteParticipant.on('audioTrackDisabled', this.onRemoteParticipantChange);
    remoteParticipant.on('audioTrackEnabled', this.onRemoteParticipantChange);
    remoteParticipant.on('audioTrackPublished', this.onRemoteParticipantChange);
    remoteParticipant.on(
      'audioTrackPublishPriorityChanged',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'audioTrackSubscribed',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'audioTrackSubscriptionFailed',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'audioTrackUnpublished',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'audioTrackUnsubscribed',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on('dataTrackPublished', this.onRemoteParticipantChange);
    remoteParticipant.on(
      'dataTrackPublishPriorityChanged',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on('dataTrackSubscribed', this.onRemoteParticipantChange);
    remoteParticipant.on(
      'dataTrackSubscriptionFailed',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'dataTrackUnpublished',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'dataTrackUnsubscribed',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'networkQualityLevelChanged',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on('videoTrackDisabled', this.onRemoteParticipantChange);
    remoteParticipant.on('videoTrackEnabled', this.onRemoteParticipantChange);
    remoteParticipant.on('videoTrackPublished', this.onRemoteParticipantChange);
    remoteParticipant.on(
      'videoTrackPublishPriorityChanged',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'videoTrackSubscribed',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'videoTrackSubscriptionFailed',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'videoTrackSwitchedOff',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'videoTrackSwitchedOn',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'videoTrackUnpublished',
      this.onRemoteParticipantChange
    );
    remoteParticipant.on(
      'videoTrackUnsubscribed',
      this.onRemoteParticipantChange
    );

    const subscriptionParams = {
      channel: 'RemoteMembershipChannel',
      twilioParticipantSid: remoteParticipant.sid,
      membershipId,
    };
    this.subscription = connection.subscriptions.create(subscriptionParams, {
      received: (data: any) => {
        if (_.includes(['connected', 'updated'], data.event)) {
          this.remoteMembership = data.payload;
          if (this.onChange) {
            this.onChange(this);
          }
        } else {
          console.error(
            `${subscriptionParams} received unknown event ${data.event}`
          );
        }
      },
    });
  }

  onRemoteParticipantChange = () => {
    if (this.onChange) {
      this.onChange(this);
    }
  };

  unsubscribe = () => {
    this.subscription.unsubscribe();

    this.remoteParticipant.off(
      'audioTrackDisabled',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'audioTrackEnabled',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'audioTrackPublished',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'audioTrackPublishPriorityChanged',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'audioTrackSubscribed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'audioTrackSubscriptionFailed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'audioTrackUnpublished',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'audioTrackUnsubscribed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'dataTrackPublished',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'dataTrackPublishPriorityChanged',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'dataTrackSubscribed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'dataTrackSubscriptionFailed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'dataTrackUnpublished',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'dataTrackUnsubscribed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'networkQualityLevelChanged',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackDisabled',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackEnabled',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackPublished',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackPublishPriorityChanged',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackSubscribed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackSubscriptionFailed',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackSwitchedOff',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackSwitchedOn',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackUnpublished',
      this.onRemoteParticipantChange
    );
    this.remoteParticipant.off(
      'videoTrackUnsubscribed',
      this.onRemoteParticipantChange
    );
  };

  toRemoteConnection: () => RemoteConnection = () => {
    return {
      membershipId: _.get(this.remoteMembership, 'id'),
      audioTrack: this.audioTrack,
      videoTrack: this.videoTrack,
      screenSharingVideoTrack: this.screenSharingVideoTrack,
      screenSharingAudioTrack: this.screenSharingAudioTrack,
      subscription: this.subscription,
    };
  };

  get audioTrack(): RemoteAudioTrack {
    return _.get(
      _.find(this.remoteParticipant.remoteAudioTracks, (track) =>
        _.startsWith(track.trackName, AUDIO_TRACK_NAME_PREFIX)
      ),
      'remoteTrack'
    );
  }

  get videoTrack(): RemoteVideoTrack {
    return _.get(
      _.find(this.remoteParticipant.remoteVideoTracks, (track) =>
        _.startsWith(track.trackName, VIDEO_TRACK_NAME_PREFIX)
      ),
      'remoteTrack'
    );
  }

  get screenSharingVideoTrack(): RemoteVideoTrack {
    return _.get(
      _.find(this.remoteParticipant.remoteVideoTracks, (track) =>
        _.startsWith(track.trackName, SCREEN_SHARING_TRACK_NAME_PREFIX)
      ),
      'remoteTrack'
    );
  }

  get screenSharingAudioTrack(): RemoteAudioTrack {
    return _.get(
      _.find(this.remoteParticipant.remoteAudioTracks, (track) =>
        _.startsWith(track.trackName, SCREEN_SHARING_TRACK_NAME_PREFIX)
      ),
      'remoteTrack'
    );
  }
}
