import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet } from 'react-native';
import { OFF_WHITE } from '../../config/constants';
import usePortraitOrientation from '../../hooks/usePortraitOrientation';
import ScrollView from '../ScrollView';
import HomeScreenActions from './HomeScreenActions';
import HomeScreenHero from './HomeScreenHero';
import SubscriberModal from './SubscriberModal';

const styles = StyleSheet.create({
  root: {
    backgroundColor: OFF_WHITE,
  },
});

export default function HomeScreen() {
  usePortraitOrientation();
  return (
    <ScrollView style={[styles.root, StyleSheet.absoluteFill]}>
      <StatusBar style="auto" />
      <HomeScreenHero />
      <HomeScreenActions />
      <SubscriberModal />
    </ScrollView>
  );
}
