import { StackActions, useNavigation } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { SafeAreaView, View } from 'react-native';
import * as yup from 'yup';
import { getRoomExists } from '../config/roomsApi';
import workflowScreenStyles from '../config/workflowScreenStyles';
import useNavigateBackOrHome from '../hooks/useNavigateBackOrHome';
import usePortraitOrientation from '../hooks/usePortraitOrientation';
import Header from './Header';
import KeyboardDismissingBackground from './KeyboardDismissingBackground';
import NavigationHeader from './NavigationHeader';
import PrimaryButton from './PrimaryButton';
import ResponsiveContainer from './ResponsiveContainer';
import TextField from './TextField';

const URL_PATTERN = /^((https?:\/\/)?(www\.|app\.)?stoop\.video\/rooms\/)?([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})$/i;

interface FormData {
  roomQuery: string;
}

const findValidationSchema = yup.object().shape({
  roomQuery: yup
    .string()
    .required('Required')
    .matches(URL_PATTERN, 'Must be a valid room URL or ID')
    .test('room-must-exist', 'Room does not exist', async (roomQuery) => {
      const roomId = _.last((roomQuery ?? '').match(URL_PATTERN));
      if (!roomId) {
        return false;
      }
      return await getRoomExists(roomId);
    }),
});

export default function FindRoomScreen() {
  const { dispatch } = useNavigation();

  const onSubmit = async (values: FormData) => {
    dispatch(
      StackActions.replace('Room', {
        roomId: _.last(values.roomQuery.match(URL_PATTERN)),
      })
    );
  };

  const navigateBackOrHome = useNavigateBackOrHome();

  usePortraitOrientation();

  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <StatusBar style="dark" />
      <NavigationHeader onBackPressed={navigateBackOrHome} />
      <KeyboardDismissingBackground style={workflowScreenStyles.content}>
        <Formik<FormData>
          validationSchema={findValidationSchema}
          validateOnBlur={false}
          initialValues={{ roomQuery: '' }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <React.Fragment>
              <ResponsiveContainer>
                <View style={workflowScreenStyles.group}>
                  <Header text="Find a room by URL or ID" />
                  <TextField
                    name="roomQuery"
                    label="Room URL or ID"
                    placeholder="https://app.stoop.video/rooms/???"
                    onSubmitEditing={handleSubmit}
                    returnKeyType="go"
                  />
                </View>
                <View style={[workflowScreenStyles.actions]}>
                  <PrimaryButton title="Join" onPress={() => handleSubmit()} />
                </View>
              </ResponsiveContainer>
            </React.Fragment>
          )}
        </Formik>
      </KeyboardDismissingBackground>
    </SafeAreaView>
  );
}
