import React, { PropsWithChildren } from 'react';
import { isMobile } from '../../config/constants';
import useIsSmallMediaQuery from '../../hooks/useIsSmallMediaQuery';
import WorkflowModalCentered from './WorkflowModalCentered';
import WorkflowModalFullScreen from './WorkflowModalFullScreen';
import { WorkflowNavigationHeaderOnClosePressedContext } from './WorkflowNavigationHeader';

export type WorkflowModalProps = PropsWithChildren<{
  onClose: () => void;
}>;

export default function WorkflowModal(props: WorkflowModalProps) {
  const isSmall = useIsSmallMediaQuery();
  return (
    <WorkflowNavigationHeaderOnClosePressedContext.Provider
      value={props.onClose}
    >
      {isSmall || isMobile ? (
        <WorkflowModalFullScreen {...props} />
      ) : (
        <WorkflowModalCentered {...props} />
      )}
    </WorkflowNavigationHeaderOnClosePressedContext.Provider>
  );
}
