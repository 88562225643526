import _ from 'lodash';
import { selector } from 'recoil';
import departureMembershipsState from './departureMembershipsState';
import membershipDepartureState from './membershipDepartureState';
import membershipIdState from './membershipIdState';
import membershipState from './membershipState';
import { NotificationType } from './nextStableNotificationState';

export interface UpcomingDepartureNotificationData {
  type: NotificationType.UPCOMING_DEPARTURE;
  departureId: string;
  createdAt: string;
  departingAt: string;
  notificationEvents: [];
  currentUserCreatedDeparture: boolean;
  actionDescription: string;
  departureFollowersDescription: string;
}

const upcomingDepartureNotificationDataState = selector<UpcomingDepartureNotificationData | null>(
  {
    key: 'upcomingDepartureNotificationDataState',
    get: ({ get }) => {
      const membershipId = get(membershipIdState);
      const departure = get(membershipDepartureState(membershipId));
      if (!departure) {
        return null;
      }

      const departureMembership = get(membershipState(departure.membershipId));
      const departureMemberships = get(departureMembershipsState(departure.id));

      const currentUserCreatedDeparture =
        membershipId === departure.membershipId;
      const actionDescription = currentUserCreatedDeparture
        ? 'You are'
        : `${departureMembership?.name ?? 'Someone'} is`;
      const departureFollowers = _.difference(departureMemberships, [
        departureMembership,
      ]);
      const departureFollowersDescription = _.isEmpty(departureFollowers)
        ? 'going'
        : _.size(departureFollowers) === 1
        ? currentUserCreatedDeparture
          ? `bringing ${_.first(departureFollowers).name ?? 'someone'}`
          : 'bringing you'
        : currentUserCreatedDeparture
        ? `bringing ${_.size(departureFollowers)} others`
        : _.size(departureFollowers) === 2
        ? `bringing you and ${
            _.find(
              departureFollowers,
              (departureFollower) => departureFollower.id !== membershipId
            ).name ?? 'someone else'
          }`
        : `bringing you and ${_.size(departureFollowers) - 1} others`;

      return {
        type: NotificationType.UPCOMING_DEPARTURE,
        departureId: departure.id,
        createdAt: departure.createdAt,
        departingAt: departure.departingAt,
        notificationEvents: [],
        currentUserCreatedDeparture,
        actionDescription,
        departureFollowersDescription,
      };
    },
  }
);
export default upcomingDepartureNotificationDataState;
