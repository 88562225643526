import Video from 'twilio-video';
import LocalVideoTrack, { findLocalVideoTrack } from './LocalVideoTrack';

export default class LocalVideoTrackPublication {
  twilioLocalVideoTrackPublication: Video.LocalVideoTrackPublication;
  localTrack: LocalVideoTrack;

  constructor(
    twilioLocalVideoTrackPublication: Video.LocalVideoTrackPublication
  ) {
    this.twilioLocalVideoTrackPublication = twilioLocalVideoTrackPublication;
    this.localTrack = findLocalVideoTrack(
      twilioLocalVideoTrackPublication.trackName
    );
  }

  get trackName(): string {
    return this.twilioLocalVideoTrackPublication.trackName;
  }
}
