import { selectorFamily } from 'recoil';
import membershipIdState from './membershipIdState';

const membershipIdIsLocalMembershipIdState = selectorFamily<boolean, string>({
  key: 'membershipIdIsLocalMembershipIdState',
  get: (id) => {
    return ({ get }) => {
      return get(membershipIdState) === id;
    };
  },
});
export default membershipIdIsLocalMembershipIdState;
