import { StyleSheet } from 'react-native';
import { VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE } from '../state/videoGridMembershipCellLayoutState';

const videoStyles = StyleSheet.create({
  root: {
    flex: 1,
    alignSelf: 'stretch',
  },
  video: {
    width: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
    height: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
  },
  mutedVideoPlaceholder: {
    alignContent: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    width: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
    height: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
    backgroundColor: 'black',
  },
});

export default videoStyles;
