import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ConnectionContext from '../contexts/ConnectionContext';
import { RemoteConnection } from '../contexts/RemoteConnectionsContext';
import TwilioRoomConnection, {
  TwilioRoomConnectionCreateParams,
} from '../models/TwilioRoomConnection';
import VolatileResourceStore from '../models/VolatileResourceStore';
import localAudioTrackEnabledState from '../state/localAudioTrackEnabledState';
import membershipIdState from '../state/membershipIdState';
import { RemoteMembership } from '../state/remoteMembershipState';
import videoMutedState from '../state/videoMutedState';
import useLocalAudioTrack from './useLocalAudioTrack';
import useLocalScreenSharingTracks from './useLocalScreenSharingTracks';
import useLocalVideoTrack from './useLocalVideoTrack';
import useRerender from './useRerender';

function durableTwilioRoomConnectionCreateParams(
  createParams: TwilioRoomConnectionCreateParams
): Partial<TwilioRoomConnectionCreateParams> {
  return _.omit(createParams, ['token']);
}

const TWILIO_ROOM_CONNECTION_STORE = new VolatileResourceStore({
  create: (createParams: TwilioRoomConnectionCreateParams) => {
    return TwilioRoomConnection.create(createParams);
  },
  destroy: (twilioRoomConnection: TwilioRoomConnection) => {
    twilioRoomConnection.destroy();
    return Promise.resolve();
  },
  shouldRecreateResource: (oldCreateParams, newCreateParams) => {
    return !_.isEqual(
      durableTwilioRoomConnectionCreateParams(oldCreateParams),
      durableTwilioRoomConnectionCreateParams(newCreateParams)
    );
  },
});

function useTwilioRoomConnection(
  createParams: TwilioRoomConnectionCreateParams
) {
  const rerender = useRerender();

  useEffect(() => {
    const subscription = TWILIO_ROOM_CONNECTION_STORE.subscribe(rerender);
    return () => {
      TWILIO_ROOM_CONNECTION_STORE.unsubscribe(subscription);
    };
  }, [rerender]);

  useEffect(() => {
    TWILIO_ROOM_CONNECTION_STORE.setCreateParams(createParams);
  }, [createParams]);

  return TWILIO_ROOM_CONNECTION_STORE.get();
}

export default function useTwilioRoom({
  twilioRoomSid,
  token,
}: {
  twilioRoomSid: string;
  token: string;
}) {
  const membershipId = useRecoilValue(membershipIdState);
  const connection = useContext(ConnectionContext);

  const twilioRoomConnectionCreateParams = useMemo(
    () => ({
      twilioRoomSid,
      token,
      membershipId,
      connection,
    }),
    [twilioRoomSid, token, membershipId, connection]
  );

  const twilioRoomConnection = useTwilioRoomConnection(
    twilioRoomConnectionCreateParams
  );

  const localAudioTrackEnabled = useRecoilValue(localAudioTrackEnabledState);
  const videoMuted = useRecoilValue(videoMutedState);

  const localVideoTrack = useLocalVideoTrack();
  const localAudioTrack = useLocalAudioTrack();
  const localScreenSharingTracks = useLocalScreenSharingTracks();

  const [remoteMemberships, setRemoteMemberships] = useState<
    RemoteMembership[]
  >([]);
  const [remoteConnections, setRemoteConnections] = useState<
    RemoteConnection[]
  >([]);

  const [
    localParticipantTwilioParticipantSid,
    setLocalParticipantTwilioParticipantSid,
  ] = useState<string>(null);

  const onChange = useCallback(
    (changedTwilioRoomConnection: TwilioRoomConnection) => {
      setRemoteMemberships(changedTwilioRoomConnection.toRemoteMemberships());
      setRemoteConnections(changedTwilioRoomConnection.toRemoteConnections());

      setLocalParticipantTwilioParticipantSid(
        changedTwilioRoomConnection.localParticipantTwilioParticipantSid
      );
    },
    [
      setRemoteMemberships,
      setRemoteConnections,
      setLocalParticipantTwilioParticipantSid,
    ]
  );

  useEffect(() => {
    if (twilioRoomConnection) {
      twilioRoomConnection.onChange = onChange;
    }
  }, [twilioRoomConnection, onChange]);

  useEffect(() => {
    if (
      !twilioRoomConnection ||
      !twilioRoomConnection.localParticipant ||
      !localVideoTrack
    ) {
      return;
    }

    twilioRoomConnection.localParticipant.publishLocalVideoTrack(
      localVideoTrack
    );
    return async () => {
      try {
        await twilioRoomConnection.localParticipant.unpublishLocalVideoTrack(
          localVideoTrack
        );
      } catch (e) {}
    };
  }, [
    twilioRoomConnection,
    twilioRoomConnection?.localParticipant,
    localVideoTrack,
  ]);

  useEffect(() => {
    if (
      !twilioRoomConnection ||
      !twilioRoomConnection.localParticipant ||
      !localAudioTrack
    ) {
      return;
    }

    twilioRoomConnection.localParticipant.publishLocalAudioTrack(
      localAudioTrack
    );
    return async () => {
      try {
        await twilioRoomConnection.localParticipant.unpublishLocalAudioTrack(
          localAudioTrack
        );
      } catch (e) {}
    };
  }, [
    twilioRoomConnection,
    twilioRoomConnection?.localParticipant,
    localAudioTrack,
  ]);

  const localVideoScreenSharingTrack =
    localScreenSharingTracks?.localVideoTrack;
  useEffect(() => {
    if (
      !twilioRoomConnection ||
      !twilioRoomConnection.localParticipant ||
      !localVideoScreenSharingTrack
    ) {
      return;
    }

    twilioRoomConnection.localParticipant.publishLocalVideoTrack(
      localVideoScreenSharingTrack
    );
    return async () => {
      try {
        await twilioRoomConnection.localParticipant.unpublishLocalVideoTrack(
          localVideoScreenSharingTrack
        );
      } catch (e) {}
    };
  }, [
    twilioRoomConnection,
    twilioRoomConnection?.localParticipant,
    localVideoScreenSharingTrack,
  ]);

  const localAudioScreenSharingTrack =
    localScreenSharingTracks?.localAudioTrack;
  useEffect(() => {
    if (
      !twilioRoomConnection ||
      !twilioRoomConnection.localParticipant ||
      !localAudioScreenSharingTrack
    ) {
      return;
    }

    twilioRoomConnection.localParticipant.publishLocalAudioTrack(
      localAudioScreenSharingTrack
    );
    return async () => {
      try {
        await twilioRoomConnection.localParticipant.unpublishLocalAudioTrack(
          localAudioScreenSharingTrack
        );
      } catch (e) {}
    };
  }, [
    twilioRoomConnection,
    twilioRoomConnection?.localParticipant,
    localAudioScreenSharingTrack,
  ]);

  useEffect(() => {
    if (!localAudioTrack) {
      return;
    }

    if (localAudioTrack.isEnabled !== localAudioTrackEnabled) {
      localAudioTrack.setIsEnabled(localAudioTrackEnabled);
    }
  }, [localAudioTrack, localAudioTrackEnabled]);

  useEffect(() => {
    if (!localVideoTrack) {
      return;
    }

    const videoEnabled = !videoMuted;
    if (localVideoTrack.isEnabled !== videoEnabled) {
      localVideoTrack.setIsEnabled(videoEnabled);
    }
  }, [localVideoTrack, videoMuted]);

  return {
    remoteMemberships,
    remoteConnections,
    localParticipantVideoTrack: localVideoTrack,
    localParticipantAudioTrack: localAudioTrack,
    localParticipantScreenSharingVideoTrack:
      localScreenSharingTracks?.localVideoTrack,
    localParticipantTwilioParticipantSid,
  };
}
