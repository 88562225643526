import React, { PropsWithChildren } from 'react';
import { StyleSheet, Text } from 'react-native';
import { Tooltip as Tippy, Position } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

interface Props {
  text: string;
  position?: Position;
}

export default function Tooltip({
  text,
  position,
  children,
}: PropsWithChildren<Props>) {
  position = position ?? 'top';

  return (
    <Tippy
      theme="dark"
      html={<Text style={styles.text}>{text}</Text>}
      position={position}
      trigger="mouseenter"
    >
      {children}
    </Tippy>
  );
}

const styles = StyleSheet.create({
  text: {
    color: 'white',
  },
});
