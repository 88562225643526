import '@expo/match-media';
import { NavigationContainer } from '@react-navigation/native';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Screen, ScreenStack } from '../react-native-screens';
import { useRecoilValue } from 'recoil';
import screenStyles from '../config/screenStyles';
import createStackNavigator from '../models/createStackNavigator';
import editProfileFlowVisibleState, {
  useHideEditProfileFlow,
} from '../state/editProfileFlowVisibleState';
import monetizationFlowVisibleState, {
  useHideMonetizationFlow,
} from '../state/monetizationFlowVisibleState';
import FindRoomScreen from './FindRoomScreen';
import HomeScreen from './home/HomeScreen';
import Monetization from './monetization/Monetization';
import EditProfile from './onboarding/EditProfile';
import RoomScreen from './room/RoomScreen';
import { WorkflowNavigationHeaderOnClosePressedContext } from './workflows/WorkflowNavigationHeader';
import { StyleSheet } from 'react-native';
import { isMobile } from '../config/constants';

export const NATIVE_APP_URI_SCHEME = 'stoop-video:';

const Stack = createStackNavigator();

export default function StoopScreens() {
  const monetizationFlowVisible = useRecoilValue(monetizationFlowVisibleState);
  const hideMonetizationFlow = useHideMonetizationFlow();
  const editProfileFlowVisible = useRecoilValue(editProfileFlowVisibleState);
  const hideEditProfileFlow = useHideEditProfileFlow();

  const screens = useMemo(
    () =>
      _.compact([
        <Screen key="navigation" style={screenStyles.screen}>
          <Stack.Navigator initialRouteName="Home">
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={{
                headerShown: false,
                gestureEnabled: false,
                replaceAnimation: 'push',
              }}
            />
            <Stack.Screen
              name="Room"
              component={RoomScreen}
              options={{
                headerShown: false,
                gestureEnabled: false,
                replaceAnimation: 'push',
              }}
            />
            <Stack.Screen
              name="FindRoom"
              component={FindRoomScreen}
              options={{ headerShown: false, replaceAnimation: 'push' }}
            />
          </Stack.Navigator>
        </Screen>,
        monetizationFlowVisible && (
          <Screen
            key="monetization"
            style={[isMobile && StyleSheet.absoluteFill, screenStyles.screen]}
            stackPresentation="modal"
            onDismissed={hideMonetizationFlow}
          >
            <WorkflowNavigationHeaderOnClosePressedContext.Provider
              value={hideMonetizationFlow}
            >
              <Monetization />
            </WorkflowNavigationHeaderOnClosePressedContext.Provider>
          </Screen>
        ),
        editProfileFlowVisible && (
          <Screen
            key="edit_profile"
            style={[isMobile && StyleSheet.absoluteFill, screenStyles.screen]}
            stackPresentation="modal"
            onDismissed={hideEditProfileFlow}
          >
            <WorkflowNavigationHeaderOnClosePressedContext.Provider
              value={hideEditProfileFlow}
            >
              <EditProfile />
            </WorkflowNavigationHeaderOnClosePressedContext.Provider>
          </Screen>
        ),
      ]),
    [
      monetizationFlowVisible,
      editProfileFlowVisible,
      hideMonetizationFlow,
      hideEditProfileFlow,
    ]
  );

  return (
    <NavigationContainer
      linking={{
        prefixes: [
          'https://www.stoop.video',
          'http://www.stoop.video',
          'https://app.stoop.video',
          'http://app.stoop.video',
          NATIVE_APP_URI_SCHEME,
        ],
        config: {
          screens: {
            Home: '',
            FindRoom: '/find',
            Room: '/rooms/:roomId',
          },
        },
      }}
    >
      <ScreenStack style={screenStyles.root}>{screens}</ScreenStack>
    </NavigationContainer>
  );
}
