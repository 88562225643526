import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Animated,
  Easing,
  LayoutChangeEvent,
  StyleSheet,
  View,
} from 'react-native';
import { WHITE_TINT } from '../../config/constants';

interface Props {
  startAt: string;
  endAt: string;
  borderRadius?: number;
}

export default function TimedProgressBarBackground({
  borderRadius,
  startAt,
  endAt,
}: Props) {
  const [rootWidth, setRootWidth] = useState(200);
  const [animationFinished, setAnimationFinished] = useState(false);

  const onLayout = ({
    nativeEvent: {
      layout: { width },
    },
  }: LayoutChangeEvent) => {
    setRootWidth(width);
  };

  const progress = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const now = moment();
    const startAtMoment = moment(startAt);
    const endAtMoment = moment(endAt);

    const elapsedMs = now.diff(startAtMoment);
    const remainingMs = endAtMoment.diff(now);
    const totalDurationMs = endAtMoment.diff(startAtMoment);

    const currentProgress =
      totalDurationMs === 0 ? 1 : elapsedMs / totalDurationMs;

    setAnimationFinished(remainingMs <= 0);
    progress.setValue(currentProgress);
    Animated.timing(progress, {
      duration: remainingMs,
      toValue: 1,
      useNativeDriver: true,
      isInteraction: false,
      easing: Easing.linear,
    }).start(({ finished }) => {
      setAnimationFinished(finished);
    });
  }, [progress, rootWidth, startAt, endAt]);

  const progressBarWidth = useMemo(
    () => ({
      width: Animated.multiply(progress, rootWidth),
    }),
    [rootWidth, progress]
  );

  const progressBarStyle = useMemo(
    () =>
      _.compact([
        styles.progressBar,
        borderRadius ? { borderRadius } : null,
        progressBarWidth,
        animationFinished ? null : styles.incompleteProgressBar,
      ]),
    [borderRadius, progressBarWidth, animationFinished]
  );

  return (
    <View style={styles.root} onLayout={onLayout}>
      <Animated.View style={[progressBarStyle]} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: 'flex-start',
  },
  progressBar: {
    flex: 1,
    backgroundColor: WHITE_TINT,
  },
  incompleteProgressBar: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});
