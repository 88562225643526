import { NativeScrollEvent } from 'react-native';
import { atom } from 'recoil';

const DEFAULT_NATIVE_SCROLL_EVENT: NativeScrollEvent = {
  contentInset: {
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  contentOffset: {
    x: 0,
    y: 0,
  },
  contentSize: {
    width: 0,
    height: 0,
  },
  layoutMeasurement: {
    width: 0,
    height: 0,
  },
  zoomScale: 1,
};

const groupListScrollState = atom<NativeScrollEvent>({
  key: 'groupListScrollState',
  default: DEFAULT_NATIVE_SCROLL_EVENT,
});
export default groupListScrollState;
