import { useCallback, useState } from 'react';

export default function useRerender() {
  const [, setRerenderFlag] = useState(false);
  const rerender = useCallback(() => {
    setRerenderFlag((f) => !f);
  }, []);

  return rerender;
}
