import React, { Context, createContext, useContext } from 'react';
import NavigationHeader from '../NavigationHeader';

export const WorkflowNavigationHeaderOnClosePressedContext = createContext(
  undefined
) as Context<() => void>;

export default function WorkflowNavigationHeader({
  onBackPressed,
}: {
  onBackPressed?: () => void;
}) {
  const onClosePressed = useContext(
    WorkflowNavigationHeaderOnClosePressedContext
  );

  return (
    <NavigationHeader
      onBackPressed={onBackPressed}
      onClosePressed={onClosePressed}
    />
  );
}
