import { selector } from 'recoil';
import Api from '../config/Api';
import roomIdState from './roomIdState';

const membershipIdState = selector<string>({
  key: 'membershipIdState',
  get: async ({ get }) => {
    const roomId = get(roomIdState);
    if (!roomId) {
      return null;
    }

    const {
      data: {
        membership: { id },
      },
    } = await Api.post(`rooms/${roomId}/memberships`);
    return id;
  },
});
export default membershipIdState;
