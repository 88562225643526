import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

export default function useNavigateBackOrHome() {
  const { canGoBack, goBack, reset } = useNavigation();

  const goBackOrHome = useCallback(() => {
    if (canGoBack()) {
      goBack();
    } else {
      reset({ index: 0, routes: [{ name: 'Home' }] });
    }
  }, [canGoBack, goBack, reset]);

  return goBackOrHome;
}
