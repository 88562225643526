import React from 'react';
import { Animated, StyleSheet, Text, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { GRAY, DEFAULT_SPACING, GREEN } from '../../../config/constants';
import useVideoGridCellAnimatedLayoutStyle from '../../../hooks/useVideoGridCellAnimatedLayoutStyle';
import useVideoGridCellScaledStyle from '../../../hooks/useVideoGridCellScaledStyle';
import departureDestinationGroupState from '../../../state/departureDestinationGroupState';
import departureState from '../../../state/departureState';
import membershipDepartureState from '../../../state/membershipDepartureState';
import membershipIdState from '../../../state/membershipIdState';
import videoGridDepartureCellLayoutState from '../../../state/videoGridDepartureCellLayoutState';
import { VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE } from '../../../state/videoGridMembershipCellLayoutState';
import JoinDepartureButton from './JoinDepartureButton';
import TimedProgressBarBackground from '../TimedProgressBarBackground';

const UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_FONT_SIZE = 16;
export const UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_MARGIN = 4 * DEFAULT_SPACING;
export const UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_HEIGHT =
  2 * UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_MARGIN +
  UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_FONT_SIZE;

export default function DepartureVideoGridCell({ id }: { id: string }) {
  const departure = useRecoilValue(departureState(id));
  const destinationGroup = useRecoilValue(departureDestinationGroupState(id));

  const membershipId = useRecoilValue(membershipIdState);
  const membershipDeparture = useRecoilValue(
    membershipDepartureState(membershipId)
  );
  const includesCurrentUser = membershipDeparture === departure;

  const layoutStyle = useVideoGridCellAnimatedLayoutStyle(
    videoGridDepartureCellLayoutState(id)
  );

  const scaledBorderRadiusStyle = useVideoGridCellScaledStyle({
    borderRadius: DEFAULT_SPACING,
  });

  const scaledHeaderStyle = useVideoGridCellScaledStyle({
    height: UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_HEIGHT,
    marginHorizontal: UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_MARGIN,
  });

  const scaledTitleStyle = useVideoGridCellScaledStyle({
    fontSize: UNSCALED_DEPARTURE_VIDEO_GRID_HEADER_FONT_SIZE,
  });

  return (
    <Animated.View
      style={[
        styles.root,
        scaledBorderRadiusStyle,
        includesCurrentUser
          ? styles.includingCurrentUser
          : styles.excludingCurrentUser,
        layoutStyle,
      ]}
    >
      {!includesCurrentUser && (
        <TimedProgressBarBackground
          {...scaledBorderRadiusStyle}
          startAt={departure.createdAt}
          endAt={departure.departingAt}
        />
      )}
      <View style={[styles.header, scaledHeaderStyle]}>
        <Text style={[styles.title, scaledTitleStyle]}>
          Leaving for {destinationGroup.name}
        </Text>
        {!includesCurrentUser && <JoinDepartureButton id={id} />}
      </View>
      <View style={styles.videos} />
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
    height: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
  },
  includingCurrentUser: {
    backgroundColor: GREEN,
  },
  excludingCurrentUser: {
    backgroundColor: GRAY,
  },
  header: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    textAlign: 'left',
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  videos: {
    flex: 1,
    alignSelf: 'stretch',
  },
});
