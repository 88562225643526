import React from 'react';
import { SvgProps } from 'react-native-svg';
import HangUpWhite from '../../assets/icons/hangUpWhite.svg';
import MicrophoneWhite from '../../assets/icons/microphoneWhite.svg';
import MicrophoneOffBlack from '../../assets/icons/microphoneOffBlack.svg';
import MicrophoneOffRed from '../../assets/icons/microphoneOffRed.svg';
import VideoWhite from '../../assets/icons/videoWhite.svg';
import VideoOffBlack from '../../assets/icons/videoOffBlack.svg';
import FlipCameraWhite from '../../assets/icons/flipCameraWhite.svg';
import CloseBlack from '../../assets/icons/closeBlack.svg';
import CloseWhite from '../../assets/icons/closeWhite.svg';
import ShareRoomWhite from '../../assets/icons/shareRoomWhite.svg';
import CheckWhite from '../../assets/icons/checkWhite.svg';
import CopyBlack from '../../assets/icons/copyBlack.svg';
import PlusWhite from '../../assets/icons/plusWhite.svg';
import TopLeftCornerBlack from '../../assets/icons/topLeftCornerBlack.svg';
import TopLeftCornerWhite from '../../assets/icons/topLeftCornerWhite.svg';
import TopLeftCornerNearBlack from '../../assets/icons/topLeftCornerNearBlack.svg';
import TopLeftCornerGray from '../../assets/icons/topLeftCornerGray.svg';
import TopLeftCornerGreen from '../../assets/icons/topLeftCornerGreen.svg';
import HotdogWhite from '../../assets/icons/hotdogWhite.svg';
import ScreenShareWhite from '../../assets/icons/screenShareWhite.svg';
import TimerWhite from '../../assets/icons/timerWhite.svg';
import SpeakerOnWhite from '../../assets/icons/speakerOnWhite.svg';
import SpeakerOffWhite from '../../assets/icons/speakerOffWhite.svg';
import EditProfileWhite from '../../assets/icons/editProfileWhite.svg';
import BackBlack from '../../assets/icons/backBlack.svg';
import Settings from '../../assets/icons/settings.svg';
import CreateRoom from '../../assets/icons/createRoom.svg';
import FindRoom from '../../assets/icons/findRoom.svg';
import Payment from '../../assets/icons/payment.svg';
import JoinRecentRoom from '../../assets/icons/joinRecentRoom.svg';
import EmojiWhite from '../../assets/icons/emojiWhite.svg';
import Svg from './Svg';

export const DEFAULT_ICON_WIDTH = 24;
export const DEFAULT_ICON_HEIGHT = 24;

export function EditProfileIcon(props: SvgProps) {
  return (
    <Svg>
      <EditProfileWhite {...defaultProps(props)} />
    </Svg>
  );
}

export function SettingsIcon(props: SvgProps) {
  return (
    <Svg>
      <Settings {...defaultProps(props)} />
    </Svg>
  );
}

export function MutedIcon(props: SvgProps) {
  return (
    <Svg>
      <MicrophoneOffRed {...defaultProps(props)} />
    </Svg>
  );
}

export function SpeakerIcon(props: { enabled?: boolean } & SvgProps) {
  const { enabled, ...restProps } = props;

  return (
    <Svg>
      {enabled ? (
        <SpeakerOffWhite {...defaultProps(restProps)} />
      ) : (
        <SpeakerOnWhite {...defaultProps(restProps)} />
      )}
    </Svg>
  );
}

export function CopyIcon(props: SvgProps) {
  return (
    <Svg>
      <CopyBlack {...{ width: 17, height: 17, ...props }} />
    </Svg>
  );
}

export function BackIcon(props: SvgProps) {
  return (
    <Svg>
      <BackBlack {...{ width: 18, height: 18, ...props }} />
    </Svg>
  );
}

export function CloseIcon(props: { color?: 'white' | 'black' } & SvgProps) {
  const { color, ...restProps } = props;

  return (
    <Svg>
      {(color ?? 'black') === 'black' ? (
        <CloseBlack {...{ width: 18, height: 18, ...restProps }} />
      ) : (
        <CloseWhite {...{ width: 18, height: 18, ...restProps }} />
      )}
    </Svg>
  );
}

export function PlusIcon(props: SvgProps) {
  return (
    <Svg>
      <PlusWhite {...{ width: 13, height: 13, ...props }} />
    </Svg>
  );
}

export function HotdogIcon(props: SvgProps) {
  return (
    <Svg>
      <HotdogWhite {...{ width: 5.25, height: 21, ...props }} />
    </Svg>
  );
}

export function AudioIcon(props: { enabled?: boolean } & SvgProps) {
  const { enabled, ...restProps } = props;

  return (
    <Svg>
      {enabled ? (
        <MicrophoneWhite {...defaultProps(restProps)} />
      ) : (
        <MicrophoneOffBlack {...defaultProps(restProps)} />
      )}
    </Svg>
  );
}

export function ChangeCameraIcon(props: SvgProps) {
  return (
    <Svg>
      <FlipCameraWhite {...defaultProps(props)} />
    </Svg>
  );
}

export function VideoIcon(props: { enabled?: boolean } & SvgProps) {
  const { enabled, ...restProps } = props;

  return (
    <Svg>
      {enabled ?? true ? (
        <VideoWhite {...defaultProps(restProps)} />
      ) : (
        <VideoOffBlack {...defaultProps(restProps)} />
      )}
    </Svg>
  );
}

export function EndCallIcon(props: SvgProps) {
  return (
    <Svg>
      <HangUpWhite {...defaultProps(props)} />
    </Svg>
  );
}

export function ShareIcon(props: SvgProps) {
  return (
    <Svg>
      <ShareRoomWhite {...defaultProps(props)} />
    </Svg>
  );
}

export function SelectedIcon(props: SvgProps) {
  return (
    <Svg>
      <CheckWhite {...defaultProps(props)} />
    </Svg>
  );
}

export type TopLeftCornerIconVariant =
  | 'white'
  | 'black'
  | 'near-black'
  | 'gray'
  | 'green';

const TOP_LEFT_CORNERS_BY_VARIANT = {
  'white': TopLeftCornerWhite,
  'black': TopLeftCornerBlack,
  'near-black': TopLeftCornerNearBlack,
  'gray': TopLeftCornerGray,
  'green': TopLeftCornerGreen,
};

export function TopLeftCornerIcon(
  props: { color?: TopLeftCornerIconVariant } & SvgProps
) {
  const { color, ...restProps } = props;
  const TopLeftCornerVariant = TOP_LEFT_CORNERS_BY_VARIANT[color ?? 'black'];

  return (
    <Svg>
      <TopLeftCornerVariant {...defaultProps(restProps)} />
    </Svg>
  );
}

export function ScreenShareIcon(props: SvgProps) {
  return (
    <Svg>
      <ScreenShareWhite {...{ height: 16, width: 20, ...props }} />
    </Svg>
  );
}

export function TimerIcon(props: SvgProps) {
  return (
    <Svg>
      <TimerWhite {...{ height: 24, width: 18, ...props }} />
    </Svg>
  );
}

export function CreateRoomIcon(props: SvgProps) {
  return (
    <Svg>
      <CreateRoom {...defaultProps(props)} />
    </Svg>
  );
}

export function FindRoomIcon(props: SvgProps) {
  return (
    <Svg>
      <FindRoom {...defaultProps(props)} />
    </Svg>
  );
}
export function PaymentIcon(props: SvgProps) {
  return (
    <Svg>
      <Payment {...defaultProps(props)} />
    </Svg>
  );
}

export function JoinRecentRoomIcon(props: SvgProps) {
  return (
    <Svg>
      <JoinRecentRoom {...defaultProps(props)} />
    </Svg>
  );
}

export const EMOJI_WHITE_ICON_WIDTH = 20;
export const EMOJI_WHITE_ICON_HEIGHT = 20;
export function EmojiWhiteIcon(props: SvgProps) {
  return (
    <Svg>
      <EmojiWhite
        {...defaultProps({
          width: EMOJI_WHITE_ICON_WIDTH,
          height: EMOJI_WHITE_ICON_HEIGHT,
          ...props,
        })}
      />
    </Svg>
  );
}

const defaultProps = (props: SvgProps) => ({
  width: DEFAULT_ICON_WIDTH,
  height: DEFAULT_ICON_HEIGHT,
  ...props,
});
