import SecureStore from './SecureStore';

const AUTHORIZATION_KEY = 'AUTHORIZATION_KEY';
class AuthorizationStore {
  authorization?: string;
  loadingPromise?: Promise<void>;

  subscribers = new Map<number, () => void>();
  lastSubscriberId = 0;

  constructor() {
    this.enqueueOperation(async () => {
      try {
        this.authorization = await SecureStore.getItemAsync(AUTHORIZATION_KEY);
      } catch {}
    });
  }

  throwSuspenseIfLoading() {
    if (this.loadingPromise) {
      throw this.loadingPromise;
    }
  }

  async set(authorization: string) {
    this.enqueueOperation(async () => {
      await SecureStore.setItemAsync(AUTHORIZATION_KEY, authorization);
      this.authorization = authorization;
    });
  }

  async delete() {
    this.enqueueOperation(async () => {
      await SecureStore.deleteItemAsync(AUTHORIZATION_KEY);
      delete this.authorization;
    });
  }

  async getAsync() {
    await (this.loadingPromise || Promise.resolve());
    return this.authorization;
  }

  subscribe(fn: () => void) {
    const id = this.lastSubscriberId + 1;
    this.lastSubscriberId = id;
    this.subscribers.set(id, fn);
    return id;
  }

  unsubscribe(id: number) {
    this.subscribers.delete(id);
  }

  notifySubscribers() {
    this.subscribers.forEach((fn) => fn());
  }

  enqueueOperation(operation: () => Promise<void>) {
    const start = this.loadingPromise || Promise.resolve();
    const enqueuedPromise = start.then(operation).then(() => {
      if (this.loadingPromise === enqueuedPromise) {
        delete this.loadingPromise;
        this.notifySubscribers();
      }
    });
    this.loadingPromise = enqueuedPromise;
  }
}
const AUTHORIZATION_STORE = new AuthorizationStore();
export default AUTHORIZATION_STORE;
