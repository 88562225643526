import _ from 'lodash';
import { selectorFamily } from 'recoil';
import { LocalMembership } from './localMembershipState';
import membershipsState from './membershipsState';
import { RemoteMembership } from './remoteMembershipState';

export type Membership = LocalMembership | RemoteMembership;

const membershipState = selectorFamily<Membership, string>({
  key: 'membershipState',
  get: (id) => ({ get }) => {
    return _.find(get(membershipsState), (membership) => membership.id === id);
  },
});
export default membershipState;
