import React, { PropsWithChildren, ReactElement } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { DEFAULT_SPACING } from '../../config/constants';

type Props = PropsWithChildren<{
  icon: ReactElement;
  onPress: () => void;
}>;

const styles = StyleSheet.create({
  root: {
    padding: DEFAULT_SPACING * 4,
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    marginBottom: DEFAULT_SPACING,
    backgroundColor: 'white',
    borderRadius: DEFAULT_SPACING,
  },
  icon: {
    width: DEFAULT_SPACING * 3,
    marginRight: DEFAULT_SPACING * 2,
  },
  content: {
    flex: 1,
  },
});

export default function HomeScreenButton({ icon, onPress, children }: Props) {
  return (
    <Pressable style={styles.root} onPress={onPress}>
      <View style={styles.icon}>{icon}</View>
      <View style={styles.content}>{children}</View>
    </Pressable>
  );
}
