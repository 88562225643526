import React, { useCallback, useMemo, useState } from 'react';
import { Image, LayoutChangeEvent, StyleSheet } from 'react-native';
import { DEFAULT_SPACING } from '../../config/constants';

export default function DraftProfileAvatarUriPreview({
  avatarUri,
}: {
  avatarUri: string;
}) {
  const [imageWidth, setImageWidth] = useState(360);

  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setImageWidth(width);
    },
    []
  );

  const imageSizeStyle = useMemo(
    () => ({
      height: imageWidth,
    }),
    [imageWidth]
  );

  return (
    <Image
      style={[styles.root, imageSizeStyle]}
      source={{ uri: avatarUri }}
      resizeMode="cover"
      onLayout={onLayout}
    />
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    transform: [{ scaleX: -1 }],
    borderRadius: DEFAULT_SPACING,
    marginVertical: DEFAULT_SPACING * 3,
  },
});
