import _ from 'lodash';
import { selector } from 'recoil';
import groupIdState from './groupIdState';
import groupState from './groupState';

const videoGridDepartureCellsCountState = selector({
  key: 'videoGridDepartureCellsCountState',
  get: ({ get }) => {
    const departures = get(groupState(get(groupIdState)))?.departures ?? [];
    return _.size(departures);
  },
});
export default videoGridDepartureCellsCountState;
