import { useQuery } from 'react-query';
import { API_QUERY_KEY } from '../config/Api';
import { indexSuggestedMemberships } from '../config/suggestedMembershipsApi';

const SUGGESTED_MEMBERSHIPS_QUERY_KEY = [
  API_QUERY_KEY,
  'SUGGESTED_MEMBERSHIPS_QUERY_KEY',
];

export default function useSuggestedMemberships() {
  const suggestedMembershipsQuery = useQuery(
    SUGGESTED_MEMBERSHIPS_QUERY_KEY,
    indexSuggestedMemberships
  );
  if (!suggestedMembershipsQuery) {
    throw 'something went wrong!';
  }
  return suggestedMembershipsQuery.data;
}
