import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { useRecoilValue } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import groupIdState from '../../../state/groupIdState';
import groupState from '../../../state/groupState';
import membershipSelectionSummaryState from '../../../state/membershipSelectionSummaryState';
import roomState from '../../../state/roomState';
import JoinedRoomNotifications from '../notifications/JoinedRoomNotifications';
import ShareButton from './ShareButton';
import TimerButton from './TimerButton';

export default function JoinedRoomHeader() {
  const room = useRecoilValue(roomState);
  const groupId = useRecoilValue(groupIdState);
  const group = useRecoilValue(groupState(groupId));
  const membershipSelectionSummary = useRecoilValue(
    membershipSelectionSummaryState
  );

  const title = membershipSelectionSummary ?? group?.name;

  const topSafeAreaInset = useSafeAreaInsets().top;

  return (
    <View style={{ minHeight: topSafeAreaInset + HEADER_HEIGHT }}>
      <SafeAreaView edges={['top', 'left', 'right']}>
        <View style={styles.content}>
          <View style={styles.button}>
            {room.willExpireAt && <TimerButton />}
          </View>
          {title && (
            <Text style={styles.title}>
              {membershipSelectionSummary ?? group.name}
            </Text>
          )}
          <View style={styles.button}>
            <ShareButton />
          </View>
        </View>
      </SafeAreaView>
      <View style={{ marginTop: -(topSafeAreaInset + HEADER_HEIGHT) }}>
        <JoinedRoomNotifications />
      </View>
    </View>
  );
}

const HEADER_HEIGHT = DEFAULT_SPACING * 8;

const styles = StyleSheet.create({
  content: {
    height: HEADER_HEIGHT,
    flexDirection: 'row',
    alignContent: 'stretch',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    flex: 1,
  },
  button: {
    width: DEFAULT_SPACING * 9,
  },
});
