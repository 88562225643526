import _ from 'lodash';
import { selectorFamily } from 'recoil';
import groupIdState from './groupIdState';
import groupState from './groupState';
import { Frame } from './roomLayoutState';
import videoGridCellLayoutByCellIndexState, {
  EMPTY_FRAME,
} from './videoGridCellLayoutByCellIndexState';
import videoGridMembershipCellsCountState from './videoGridMembershipCellsCountState';

const videoGridDepartureCellLayoutState = selectorFamily<Frame, string>({
  key: 'videoGridDepartureCellLayoutState',
  get: (id) => ({ get }) => {
    const videoGridMembershipCellsCount = get(
      videoGridMembershipCellsCountState
    );
    const groupId = get(groupIdState);
    const departureIds = _.map(
      get(groupState(groupId))?.departures ?? [],
      'id'
    );

    const departureIndex = _.indexOf(departureIds, id);
    if (departureIndex === -1) {
      return EMPTY_FRAME;
    }

    return get(
      videoGridCellLayoutByCellIndexState(
        departureIndex + videoGridMembershipCellsCount
      )
    );
  },
});
export default videoGridDepartureCellLayoutState;
