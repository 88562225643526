import { selectorFamily } from 'recoil';
import departureState from './departureState';
import groupState, { Group } from './groupState';

const departureDestinationGroupState = selectorFamily<Group, string>({
  key: 'departureDestinationGroupState',
  get: (id) => ({ get }) => {
    const departure = get(departureState(id));
    return get(groupState(departure.destinationGroupId));
  },
});
export default departureDestinationGroupState;
