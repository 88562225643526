import React from 'react';
import { SvgProps } from 'react-native-svg';
import SubscribeIllustrationSvg from '../../assets/icons/subscribeIllustration.svg';
import Svg from './Svg';

export default function SubscribeIllustration(props: SvgProps) {
  return (
    <Svg>
      <SubscribeIllustrationSvg
        {...{
          width: 90,
          height: 90,
          ...props,
        }}
      />
    </Svg>
  );
}
