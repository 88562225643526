import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import groupIdState from '../../../../state/groupIdState';
import membershipState from '../../../../state/membershipState';
import pendingEmojiReactionState from '../../../../state/pendingEmojiReactionState';
import GroupEmojiReaction from './GroupEmojiReaction';
import MembershipEmojiReaction from './MembershipEmojiReaction';

export default function EmojiReaction({ id }: { id: string }) {
  const groupId = useRecoilValue(groupIdState);
  const emojiReaction = useRecoilValue(pendingEmojiReactionState(id));
  const membership = useRecoilValue(
    membershipState(emojiReaction.membershipId)
  );

  const initialGroupid = useRef(groupId).current;
  const initialEmojiReactionMembershipGroupid = useRef(membership.groupId)
    .current;

  if (initialGroupid === initialEmojiReactionMembershipGroupid) {
    return <MembershipEmojiReaction id={id} />;
  } else {
    return <GroupEmojiReaction id={id} />;
  }
}
