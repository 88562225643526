import { AUDIO_RECORDING, usePermissions } from 'expo-permissions';
import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import VolatileResourceStore from '../models/VolatileResourceStore';
import currentAudioDeviceIdState from '../state/currentAudioDeviceIdState';
import mutedState from '../state/mutedState';
import { LocalAudioTrack, LocalAudioTrackCreateParams } from '../twilio';
import useRerender from './useRerender';

export const AUDIO_TRACK_NAME_PREFIX = 'audio';

const LOCAL_AUDIO_TRACK_STORE = new VolatileResourceStore({
  create: (createParams: LocalAudioTrackCreateParams) => {
    return LocalAudioTrack.create(createParams);
  },
  destroy: (localAudioTrack: LocalAudioTrack) => {
    return localAudioTrack.destroy();
  },
});

export default function useLocalAudioTrack(): LocalAudioTrack {
  const [permission] = usePermissions(AUDIO_RECORDING);
  const microphonePermissionGranted = permission?.granted ?? false;
  const muted = useRecoilValue(mutedState);
  const rerender = useRerender();
  const audioDeviceId = useRecoilValue(currentAudioDeviceIdState);

  useEffect(() => {
    if (!microphonePermissionGranted) {
      return;
    }

    const subscription = LOCAL_AUDIO_TRACK_STORE.subscribe(rerender);
    return () => {
      LOCAL_AUDIO_TRACK_STORE.unsubscribe(subscription);
    };
  }, [rerender, microphonePermissionGranted]);

  useEffect(() => {
    const nameParam = { name: `${AUDIO_TRACK_NAME_PREFIX}-${Date.now()}` };
    const deviceIdParam = audioDeviceId ? { deviceId: audioDeviceId } : {};
    const createParams = _.merge(nameParam, deviceIdParam);
    LOCAL_AUDIO_TRACK_STORE.setCreateParams(createParams);
  }, [audioDeviceId]);

  useEffect(() => {
    const localAudioTrack = LOCAL_AUDIO_TRACK_STORE.get();
    if (localAudioTrack) {
      localAudioTrack.setIsEnabled(!muted);
    }
  }, [rerender, muted]);

  return LOCAL_AUDIO_TRACK_STORE.get();
}
