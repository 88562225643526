import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { GRAY, DEFAULT_SPACING } from '../../../config/constants';
import RoomPreviewInputSelectionButton from './RoomPreviewInputSelectionButton';
import RoomPreviewMuteButton from './RoomPreviewMuteButton';
import RoomPreviewVideoMuteButton from './RoomPreviewVideoMuteButton';
import PrimaryButton from '../../PrimaryButton';

export default function RoomPreviewFooter({
  onPressJoin,
}: {
  onPressJoin: () => void;
}) {
  return (
    <View style={styles.footer}>
      <SafeAreaView edges={['left', 'bottom', 'right']}>
        <View style={styles.footerRow}>
          <View style={styles.footerSpacer} />
          <View style={styles.footerActions}>
            <RoomPreviewMuteButton />
            <RoomPreviewInputSelectionButton />
            <RoomPreviewVideoMuteButton />
          </View>
          <View style={styles.footerSpacer} />
        </View>
        <View style={styles.footerRow}>
          <View style={styles.footerSpacer} />
          <View style={styles.footerActions}>
            <View style={styles.joinButton}>
              <PrimaryButton title="Join" onPress={onPressJoin} />
            </View>
          </View>
          <View style={styles.footerSpacer} />
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    backgroundColor: GRAY,
    borderTopLeftRadius: DEFAULT_SPACING,
    borderTopRightRadius: DEFAULT_SPACING,
    padding: DEFAULT_SPACING * 4,
  },
  footerRow: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignContent: 'stretch',
  },
  footerSpacer: {
    flex: 1,
    flexBasis: 0,
  },
  footerActions: {
    flexDirection: 'row',
    flexShrink: 1,
    flexBasis: 300,
    justifyContent: 'space-between',
  },
  joinButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
  },
});
