import _ from 'lodash';
import React, { useCallback } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { DEFAULT_SPACING, OFF_WHITE } from '../../config/constants';
import { useClearAuthorization } from '../../hooks/useAuthorization';
import useProfile from '../../hooks/useProfile';
import useSuggestedMemberships from '../../hooks/useSuggestedMemberships';
import Link from '../Link';
import ResponsiveContainer from '../ResponsiveContainer';
import CreateRoomButton from './CreateRoomButton';
import FindRoomButton from './FindRoomButton';
import SubscribeButton from './SubscribeButton';
import SuggestedRoomButton from './SuggestedRoomButton';

export default function HomeScreenActions() {
  const { subscribed } = useProfile();
  const suggestedMemberships = useSuggestedMemberships();
  const clearAuthorization = useClearAuthorization();

  const openPrivacyAndPolicies = useCallback(
    () => Linking.openURL('https://www.stoop.video/policies'),
    []
  );

  return (
    <SafeAreaView edges={['right', 'bottom', 'left']}>
      <ResponsiveContainer>
        <View style={styles.root}>
          <CreateRoomButton />
          <FindRoomButton />
          {!subscribed && <SubscribeButton />}
          {_.map(suggestedMemberships, ({ id }) => (
            <SuggestedRoomButton membershipId={id} key={id} />
          ))}
          <Link title="Log out" onPress={clearAuthorization} />
          <View style={styles.separator} />
          <Link title="Privacy & Policies" onPress={openPrivacyAndPolicies} />
        </View>
      </ResponsiveContainer>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: OFF_WHITE,
    marginVertical: DEFAULT_SPACING * 3,
    marginHorizontal: DEFAULT_SPACING * 4,
    alignSelf: 'stretch',
  },
  separator: {
    height: 1,
    backgroundColor: '#C4C4C4',
    marginVertical: DEFAULT_SPACING,
  },
});
