import _ from 'lodash';
import React, { useCallback } from 'react';
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import groupIdsState from '../../../state/groupIdsState';
import groupListLayoutState from '../../../state/groupListLayoutState';
import groupListScrollState from '../../../state/groupListScrollState';
import ScrollView from '../../ScrollView';
import GroupPreview from './GroupPreview';
import MatchmakingButton from './MatchmakingButton';
import NewGroupButton from './NewGroupButton';

export default function GroupList() {
  const groupIds = useRecoilValue(groupIdsState);

  const setGroupListScroll = useSetRecoilState(groupListScrollState);
  const onScroll = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      setGroupListScroll(nativeEvent);
    },
    [setGroupListScroll]
  );

  const setGroupListLayout = useSetRecoilState(groupListLayoutState);
  const onLayout = useCallback(
    ({ nativeEvent: { layout } }: LayoutChangeEvent) =>
      setGroupListLayout(layout),
    [setGroupListLayout]
  );

  return (
    <SafeAreaView edges={['bottom', 'left', 'right']}>
      <ScrollView
        contentContainerStyle={[styles.contentContainer]}
        horizontal={true}
        onScroll={onScroll}
        scrollEventThrottle={0}
        onLayout={onLayout}
      >
        <NewGroupButton />
        <MatchmakingButton />
        {_.map(groupIds, (id) => (
          <GroupPreview id={id} key={id} />
        ))}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    margin: DEFAULT_SPACING,
  },
});
