import React from 'react';
import { Image, Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  BARELY_TRANSPARENT_GRAY,
  DEFAULT_SPACING,
} from '../../../config/constants';
import { profileAvatarSource } from '../../../hooks/useProfile';
import remoteMembershipLocallyMutedState from '../../../state/remoteMembershipLocallyMutedState';
import remoteMembershipState from '../../../state/remoteMembershipState';
import { SpeakerIcon } from '../../Icons';

interface Props {
  id: string;
  visible: boolean;
  onClose: () => void;
}

export default function RemoteMembershipVideoActionsModal({
  id,
  visible,
  onClose,
}: Props) {
  const membership = useRecoilValue(remoteMembershipState(id));
  const [muted, setMuted] = useRecoilState(
    remoteMembershipLocallyMutedState(id)
  );

  return (
    <Modal animationType="fade" transparent={true} visible={visible}>
      <Pressable style={styles.centeredView} onPress={onClose}>
        <Pressable
          style={styles.modalView}
          onPress={(e) => e.stopPropagation()}
        >
          <Image
            resizeMode="cover"
            style={styles.avatar}
            source={profileAvatarSource(membership)}
          />
          <Text style={styles.text}>{membership.name}</Text>
          <View style={styles.divider} />
          <Pressable style={styles.muteButton} onPress={() => setMuted(!muted)}>
            <SpeakerIcon
              enabled={muted}
              style={{ marginRight: DEFAULT_SPACING }}
            />
            <Text style={styles.text}>{muted ? 'Unmute' : 'Mute'}</Text>
          </Pressable>
        </Pressable>
      </Pressable>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: BARELY_TRANSPARENT_GRAY,
  },
  modalView: {
    margin: DEFAULT_SPACING * 2,
    flexBasis: 300,
    flexShrink: 1,
    backgroundColor: '#1C1C1E',
    borderRadius: DEFAULT_SPACING * 3,
    paddingVertical: DEFAULT_SPACING,
    paddingHorizontal: DEFAULT_SPACING * 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    height: DEFAULT_SPACING * 4,
    width: DEFAULT_SPACING * 4,
    borderRadius: DEFAULT_SPACING,
    backgroundColor: 'white',
    marginVertical: DEFAULT_SPACING,
  },
  divider: {
    backgroundColor: '#535353',
    height: 1,
    width: '100%',
    marginVertical: DEFAULT_SPACING * 2,
  },
  muteButton: {
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'white',
  },
});
