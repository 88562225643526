import { LayoutRectangle } from 'react-native';
import { atom } from 'recoil';

const groupListLayoutState = atom<LayoutRectangle>({
  key: 'groupListLayoutState',
  default: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
});
export default groupListLayoutState;
