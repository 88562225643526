import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

export default function LoadingLogo() {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require('../../assets/stoop-logo-black.png')}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  image: {
    width: 126,
    height: 33,
    resizeMode: 'contain',
  },
});
