import { useRecoilState, useRecoilValue } from 'recoil';
import membershipIdState from '../state/membershipIdState';
import {
  useAddPendingEmojiReactions,
  useSelectRemotePendingEmojiReactions,
} from '../state/pendingEmojiReactionsState';
import { PendingEmojiReaction } from '../state/pendingEmojiReactionState';
import roomState, { Room } from '../state/roomState';
import useSubscription, { Subscription } from './useSubscription';

enum RoomChannelEvent {
  CONNECTED = 'connected',
  UPDATED = 'updated',
  PENDING_EMOJI_REACTIONS_CREATED = 'pending_emoji_reactions_created',
}

interface RoomSync {
  room: Room | null;
  subscription: Subscription;
}

export default function useRoomSync(): RoomSync {
  const membershipId = useRecoilValue(membershipIdState);
  const [room, setRoom] = useRecoilState(roomState);

  const selectRemotePendingEmojiReactions = useSelectRemotePendingEmojiReactions();
  const addPendingEmojiReactions = useAddPendingEmojiReactions();

  const subscription = useSubscription(
    {
      channel: 'RoomChannel',
      membershipId,
    },
    {
      [RoomChannelEvent.CONNECTED]: setRoom,
      [RoomChannelEvent.UPDATED]: setRoom,
      [RoomChannelEvent.PENDING_EMOJI_REACTIONS_CREATED]: ({
        pendingEmojiReactions,
      }: {
        pendingEmojiReactions: PendingEmojiReaction[];
      }) => {
        addPendingEmojiReactions(
          selectRemotePendingEmojiReactions(pendingEmojiReactions)
        );
      },
    }
  );

  return { subscription, room };
}
