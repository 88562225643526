import _ from 'lodash';
import { selector } from 'recoil';
import { DEFAULT_SPACING } from '../config/constants';
import roomScreenSharingMembershipExistsState from './roomScreenSharingMembershipExistsState';
import roomVideoGridLayoutSizeCandidatesState, {
  VideoGridLayoutSize as VideoGridLayoutSize,
} from './roomVideoGridLayoutSizeCandidatesState';
import videoGridSizeState from './videoGridSizeState';

export interface RoomLayoutSize {
  direction: 'row' | 'column';
  cellSize: number;
  videoGridLayout: VideoGridLayoutSize;
}

const roomLayoutCandidatesState = selector<RoomLayoutSize[]>({
  key: 'roomLayoutCandidatesState',
  get: ({ get }) => {
    const videoGridSize = get(videoGridSizeState);
    const videoGridLayoutSizeCandidates = get(
      roomVideoGridLayoutSizeCandidatesState
    );
    const roomScreenSharingMembershipExists = get(
      roomScreenSharingMembershipExistsState
    );

    return _.flatMap(
      videoGridLayoutSizeCandidates,
      (videoGridLayoutSizeCandidate) => {
        const videoGridWidthCells = videoGridLayoutSizeCandidate.maxRowSize;
        const videoGridHeightCells = videoGridLayoutSizeCandidate.rowsCount;

        if (roomScreenSharingMembershipExists) {
          const rowTotalWidthCells = videoGridWidthCells + 2;
          const rowTotalHeightCells = _.max([videoGridHeightCells, 2]);

          const rowTotalHorizontalMarginPoints =
            DEFAULT_SPACING * videoGridWidthCells;
          const rowTotalVerticalMarginPoints =
            DEFAULT_SPACING * (videoGridHeightCells - 1);

          const rowCellSizeConstrainedByWidth =
            (videoGridSize.width - rowTotalHorizontalMarginPoints) /
            rowTotalWidthCells;
          const rowCellSizeConstrainedByHeight =
            (videoGridSize.height - rowTotalVerticalMarginPoints) /
            rowTotalHeightCells;
          const rowCellSize = _.min([
            rowCellSizeConstrainedByWidth,
            rowCellSizeConstrainedByHeight,
          ]);

          const rowLayoutCandidate = {
            direction: 'row',
            videoGridLayout: {
              ...videoGridLayoutSizeCandidate,
            },
            cellSize: rowCellSize,
          } as RoomLayoutSize;

          const columnTotalWidthCells = _.max([videoGridWidthCells, 2]);
          const columnTotalHeightCells = videoGridHeightCells + 2;

          const columnTotalHorizontalMarginPoints =
            DEFAULT_SPACING * (videoGridWidthCells - 1);
          const columnTotalVerticalMarginPoints =
            DEFAULT_SPACING * videoGridHeightCells;

          const columnCellSizeConstrainedByWidth =
            (videoGridSize.width - columnTotalHorizontalMarginPoints) /
            columnTotalWidthCells;
          const columnCellSizeConstrainedByHeight =
            (videoGridSize.height - columnTotalVerticalMarginPoints) /
            columnTotalHeightCells;
          const columnCellSize = _.min([
            columnCellSizeConstrainedByWidth,
            columnCellSizeConstrainedByHeight,
          ]);

          const columnLayoutCandidate = {
            direction: 'column',
            videoGridLayout: {
              ...videoGridLayoutSizeCandidate,
            },
            cellSize: columnCellSize,
          } as RoomLayoutSize;

          return [rowLayoutCandidate, columnLayoutCandidate];
        } else {
          const totalWidthCells = videoGridWidthCells;
          const totalHeightCells = videoGridHeightCells;

          const totalHorizontalMarginPoints =
            DEFAULT_SPACING * (videoGridWidthCells - 1);
          const totalVerticalMarginPoints =
            DEFAULT_SPACING * (videoGridHeightCells - 1);

          const cellSizeConstrainedByWidth =
            (videoGridSize.width - totalHorizontalMarginPoints) /
            totalWidthCells;
          const cellSizeConstrainedByHeight =
            (videoGridSize.height - totalVerticalMarginPoints) /
            totalHeightCells;
          const cellSize = _.min([
            cellSizeConstrainedByWidth,
            cellSizeConstrainedByHeight,
          ]);

          return [
            {
              direction: 'column',
              videoGridLayout: {
                ...videoGridLayoutSizeCandidate,
              },
              cellSize: cellSize,
            },
          ] as RoomLayoutSize[];
        }
      }
    );
  },
});
export default roomLayoutCandidatesState;
