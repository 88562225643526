import _ from 'lodash';
import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Picker } from 'react-native-web';
import { useResetRecoilState } from 'recoil';
import { useRecoilState } from 'recoil';
import { DEFAULT_SPACING } from '../../config/constants';
import useAvailableAudioDevices from '../../hooks/useAvailableAudioDevices';
import useAvailableVideoDevices from '../../hooks/useAvailableVideoDevices';
import currentAudioDeviceIdState from '../../state/currentAudioDeviceIdState';
import currentVideoDeviceIdState from '../../state/currentVideoDeviceIdState';
import WebModal from '../WebModal';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default function ChangeAudioVideoDevicesModal({
  visible,
  onClose,
}: Props) {
  const videoDevices = useAvailableVideoDevices();
  const [currentVideoDeviceId, setCurrentVideoDeviceId] = useRecoilState(
    currentVideoDeviceIdState
  );
  const resetCurrentVideoDeviceId = useResetRecoilState(
    currentVideoDeviceIdState
  );
  const [currentAudioDeviceId, setCurrentAudioDeviceId] = useRecoilState(
    currentAudioDeviceIdState
  );
  const resetCurrentAudioDeviceId = useResetRecoilState(
    currentAudioDeviceIdState
  );
  const audioDevices = useAvailableAudioDevices();

  const onSelectVideoDeviceId = useCallback(
    (videoDeviceId: string) => {
      if (videoDeviceId === '') {
        resetCurrentVideoDeviceId();
      } else {
        setCurrentVideoDeviceId(videoDeviceId);
      }
    },
    [resetCurrentVideoDeviceId, setCurrentVideoDeviceId]
  );

  const onSelectAudioDeviceId = useCallback(
    (audioDeviceId: string) => {
      if (audioDeviceId === '') {
        resetCurrentAudioDeviceId();
      } else {
        setCurrentAudioDeviceId(audioDeviceId);
      }
    },
    [resetCurrentAudioDeviceId, setCurrentAudioDeviceId]
  );

  return (
    <WebModal
      visible={visible}
      onClose={onClose}
      title="Settings"
      maxWidth={500}
    >
      <View style={styles.content}>
        <View style={styles.control}>
          <Text style={styles.label}>Microphone</Text>
          <View style={styles.pickerContainer}>
            <Picker
              selectedValue={currentAudioDeviceId ?? ''}
              onValueChange={onSelectAudioDeviceId}
              style={styles.picker}
            >
              <Picker.Item key="" label="System Default" value="" />
              {_.map(audioDevices, (d) => (
                <Picker.Item key={d.id} label={d.name} value={d.id} />
              ))}
            </Picker>
          </View>
        </View>
        <View style={styles.control}>
          <Text style={styles.label}>Camera</Text>
          <View style={styles.pickerContainer}>
            <Picker
              selectedValue={currentVideoDeviceId ?? ''}
              onValueChange={onSelectVideoDeviceId}
              style={styles.picker}
            >
              <Picker.Item key="" label="System Default" value="" />
              {_.map(videoDevices, (d) => (
                <Picker.Item key={d.id} label={d.name} value={d.id} />
              ))}
            </Picker>
          </View>
        </View>
      </View>
    </WebModal>
  );
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'flex-start',
  },
  control: {
    width: '100%',
    marginBottom: DEFAULT_SPACING * 2,
  },
  label: {
    fontSize: DEFAULT_SPACING * 1.75,
    marginBottom: DEFAULT_SPACING,
  },
  pickerContainer: {
    paddingHorizontal: DEFAULT_SPACING * 1.5,
    borderRadius: DEFAULT_SPACING / 2,
    borderWidth: 1,
  },
  picker: {
    borderWidth: 0,
    paddingVertical: DEFAULT_SPACING * 1.5,
  },
});
