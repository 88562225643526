import Cookies from 'js-cookie';

export default {
  getItemAsync: (key: string) => {
    return Promise.resolve(Cookies.get(key));
  },
  setItemAsync: (key: string, value: string) => {
    return Promise.resolve(Cookies.set(key, value));
  },
  deleteItemAsync: (key: string) => {
    return Promise.resolve(Cookies.remove(key));
  },
};
