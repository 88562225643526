import _ from 'lodash';
import { Platform } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { API_QUERY_KEY } from '../config/Api';
import InAppPurchasesStore, {
  IAPResponseCode,
} from '../models/InAppPurchasesStore';

const PRODUCT_IDS = Platform.select({
  ios: ['MonthlySubscription2'],
  android: ['monthly_subscription_2'],
  web: ['price_1HpoFPLfx2ImaU9GBxfIXCd9', 'price_1HpKdfLfx2ImaU9GiukWWSZw'],
});

const MONTHLY_SUBSCRIPTION_QUERY_KEY = Platform.select({
  web: [API_QUERY_KEY, 'MONTHLY_SUBSCRIPTION_QUERY_KEY'],
  default: ['MONTHLY_SUBSCRIPTION_QUERY_KEY'],
});

export default function useMonthlySubscription() {
  return useQuery(MONTHLY_SUBSCRIPTION_QUERY_KEY, async () => {
    const InAppPurchases = await InAppPurchasesStore.get();
    const response = await InAppPurchases.getProductsAsync(PRODUCT_IDS);
    const { responseCode, results } = response;
    if (responseCode !== IAPResponseCode.OK) {
      throw responseCode;
    }
    return _.first(results);
  }).data;
}

export function useInvalidateMonthlySubscription() {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(MONTHLY_SUBSCRIPTION_QUERY_KEY);
  };
}
