import EventEmitter from 'eventemitter3';
import _ from 'lodash';
import Video from 'twilio-video';
import LocalAudioTrack from './LocalAudioTrack';
import LocalAudioTrackPublication from './LocalAudioTrackPublication';
import { findLocalTrack } from './LocalTrack';
import LocalVideoTrack from './LocalVideoTrack';
import LocalVideoTrackPublication from './LocalVideoTrackPublication';
import { toNetworkQualityLevel } from './NetworkQualityLevel';
import { getRooms } from './Room';

export function getLocalParticipants() {
  return _.compact(_.map(getRooms(), 'localParticipant'));
}

export default class LocalParticipant {
  twilioLocalParticipant: Video.LocalParticipant;
  localVideoTracks: LocalVideoTrackPublication[] = [];
  localAudioTracks: LocalAudioTrackPublication[] = [];
  eventEmitter = new EventEmitter();

  constructor(twilioLocalParticipant: Video.LocalParticipant) {
    this.twilioLocalParticipant = twilioLocalParticipant;

    twilioLocalParticipant.on('trackPublished', this.onTrackPublished);
    twilioLocalParticipant.on(
      'trackPublicationFailed',
      this.onTrackPublicationFailed
    );
    twilioLocalParticipant.on(
      'networkQualityLevelChanged',
      this.onNetworkQualityLevelChanged
    );
  }

  on(event: string, fn: (...args: any[]) => void) {
    this.eventEmitter.on(event, fn);
  }

  off(event: string, fn: (...args: any[]) => void) {
    this.eventEmitter.off(event, fn);
  }

  onTrackPublicationFailed = (error: any, twilioTrack: Video.LocalTrack) => {
    const eventName = `${twilioTrack.kind}TrackPublicationFailed`;

    const track = findLocalTrack(twilioTrack);
    const eventTrackAttribute = { [`${twilioTrack.kind}Track`]: track };

    this.eventEmitter.emit(eventName, {
      participant: this,
      ...eventTrackAttribute,
      error,
    });
  };

  onTrackPublished = (twilioTrackPublication: Video.LocalTrackPublication) => {
    const { kind } = twilioTrackPublication;
    const eventName = `${kind}TrackPublished`;

    let trackPublication:
      | Video.LocalTrackPublication
      | LocalAudioTrackPublication
      | LocalVideoTrackPublication;
    if (kind === 'audio') {
      const audioTrackPublication = new LocalAudioTrackPublication(
        twilioTrackPublication as Video.LocalAudioTrackPublication
      );
      this.localAudioTracks.push(audioTrackPublication);
      trackPublication = audioTrackPublication;
    } else if (kind === 'video') {
      const videoTrackPublication = new LocalVideoTrackPublication(
        twilioTrackPublication as Video.LocalVideoTrackPublication
      );
      this.localVideoTracks.push(videoTrackPublication);
      trackPublication = videoTrackPublication;
    } else {
      trackPublication = twilioTrackPublication;
    }

    const eventTrackAttribute = {
      [`${kind}TrackPublication`]: trackPublication,
    };

    this.eventEmitter.emit(eventName, {
      participant: this,
      ...eventTrackAttribute,
    });
  };

  onNetworkQualityLevelChanged = (
    twilioNetworkQualityLevel: Video.NetworkQualityLevel
  ) => {
    this.eventEmitter.emit('networkQualityLevelChanged', {
      participant: this,
      networkQualityLevel: toNetworkQualityLevel(twilioNetworkQualityLevel),
    });
  };

  publishLocalAudioTrack = async (localAudioTrack: LocalAudioTrack) => {
    await this.twilioLocalParticipant.publishTrack(
      localAudioTrack.twilioLocalAudioTrack
    );
    return true;
  };

  unpublishLocalAudioTrack = async (localAudioTrack: LocalAudioTrack) => {
    this.twilioLocalParticipant.unpublishTrack(
      localAudioTrack.twilioLocalAudioTrack
    );
    this.localAudioTracks = _.filter(
      this.localAudioTracks,
      ({ localTrack }) => localTrack !== localAudioTrack
    );
    return Promise.resolve(true);
  };

  publishLocalVideoTrack = async (localVideoTrack: LocalVideoTrack) => {
    await this.twilioLocalParticipant.publishTrack(
      localVideoTrack.twilioLocalVideoTrack
    );
    return true;
  };

  unpublishLocalVideoTrack = async (localVideoTrack: LocalVideoTrack) => {
    this.twilioLocalParticipant.unpublishTrack(
      localVideoTrack.twilioLocalVideoTrack
    );
    this.localVideoTracks = _.filter(
      this.localVideoTracks,
      ({ localTrack }) => localTrack !== localVideoTrack
    );
    return Promise.resolve(true);
  };

  destroy = () => {};

  get identity(): string {
    return this.twilioLocalParticipant.identity;
  }

  get sid(): string {
    return this.twilioLocalParticipant.sid;
  }

  get networkQualityLevel(): string {
    return toNetworkQualityLevel(
      this.twilioLocalParticipant.networkQualityLevel
    );
  }
}
