import _ from 'lodash';
import Video from 'twilio-video';
import LocalAudioTrackCreateParams from './LocalAudioTrackCreateParams';
import { getLocalParticipants } from './LocalParticipant';

const localAudioTracksByName = new Map<string, LocalAudioTrack>();

export function findLocalAudioTrack(name: string) {
  return localAudioTracksByName.get(name);
}

export default class LocalAudioTrack {
  twilioLocalAudioTrack: Video.LocalAudioTrack;

  constructor(twilioLocalAudioTrack: Video.LocalAudioTrack) {
    this.twilioLocalAudioTrack = twilioLocalAudioTrack;
    localAudioTracksByName.set(this.name, this);
  }

  static create = async (
    params: LocalAudioTrackCreateParams
  ): Promise<LocalAudioTrack> => {
    if (_.has(params, 'name') && localAudioTracksByName.has(params.name)) {
      return Promise.reject('Duplicate track name');
    }

    const options: Video.CreateLocalTrackOptions = {};
    if (_.has(params, 'name')) {
      options.name = params.name;
    }
    if (_.has(params, 'deviceId')) {
      options.deviceId = params.deviceId;
    }
    const twilioLocalAudioTrack = await Video.createLocalAudioTrack(options);

    const localAudioTrack = new LocalAudioTrack(twilioLocalAudioTrack);
    if (_.has(params, 'enabled')) {
      localAudioTrack.setIsEnabled(params.enabled);
    }

    localAudioTracksByName.set(localAudioTrack.name, localAudioTrack);
    return localAudioTrack;
  };

  setIsEnabled = (enabled: boolean) => {
    this.twilioLocalAudioTrack.enable(enabled);
    return Promise.resolve();
  };

  get isEnabled(): boolean {
    return this.twilioLocalAudioTrack.isEnabled;
  }

  get name(): string {
    return this.twilioLocalAudioTrack.name;
  }

  get state(): string | null {
    return this.twilioLocalAudioTrack.isStopped
      ? 'ended'
      : this.twilioLocalAudioTrack.isStarted
      ? 'live'
      : null;
  }

  destroy = async () => {
    this.twilioLocalAudioTrack.detach();
    this.twilioLocalAudioTrack.stop();

    await Promise.all(
      _.map(getLocalParticipants(), async (localParticipant) => {
        const localParticipantNeedsUnpublish = _.some(
          localParticipant.localAudioTracks,
          ({ localTrack }) => localTrack === this
        );
        if (localParticipantNeedsUnpublish) {
          await localParticipant.unpublishLocalAudioTrack(this);
        }
      })
    );

    localAudioTracksByName.delete(this.name);
    return Promise.resolve();
  };
}
