import Video from 'twilio-video';

export default class RemoteVideoTrack {
  twilioRemoteVideoTrack: Video.RemoteVideoTrack;

  constructor(twilioRemoteVideoTrack: Video.RemoteVideoTrack) {
    this.twilioRemoteVideoTrack = twilioRemoteVideoTrack;
  }

  get isSwitchedOff() {
    return this.twilioRemoteVideoTrack.isSwitchedOff;
  }
}
