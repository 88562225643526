import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  LayoutChangeEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useResetRecoilState } from 'recoil';
import { GREEN, YELLOW } from '../../../config/constants';
import notificationStyles from '../../../config/notificationStyles';
import useNextNotification from '../../../hooks/useNextNotification';
import {
  Notification,
  NotificationType,
} from '../../../state/nextStableNotificationState';
import pastNotificationsState, {
  useDismissNotification,
} from '../../../state/pastNotificationsState';
import FreeTrialRoomExpiringNotification from './FreeTrialRoomExpiringNotification';
import MatchmakingNotification from './MatchmakingNotification';
import ProposedEmptyGroupDepartureNotification from './ProposedEmptyGroupDepartureNotification';
import UpcomingDepartureNotification from './UpcomingDepartureNotification';

const OVERFLOW_HEIGHT = 1000;
const DISMISSED_NOTIFICATION_TRANSFORM_Y = -180;
const NOTIFICATION_DURATION_MS = 7000;

export default function JoinedRoomNotifications() {
  const [layoutHeight, setLayoutHeight] = useState<number>();

  const nextNotification = useNextNotification();
  const dismissNotification = useDismissNotification();
  const [
    currentNotification,
    setCurrentNotification,
  ] = useState<Notification>();

  const resetPastNotifications = useResetRecoilState(pastNotificationsState);
  useEffect(() => resetPastNotifications, [resetPastNotifications]);

  useEffect(() => {
    if (currentNotification) {
      const presentedAt = Date.now();
      const timeout = setTimeout(() => {
        dismissNotification(currentNotification);
      }, NOTIFICATION_DURATION_MS);
      return () => {
        clearTimeout(timeout);
        if (Date.now() - presentedAt > NOTIFICATION_DURATION_MS / 2) {
          dismissNotification(currentNotification);
        }
      };
    }
  }, [currentNotification, dismissNotification]);

  const onLayout = ({
    nativeEvent: {
      layout: { height },
    },
  }: LayoutChangeEvent) => {
    setLayoutHeight(height);
  };

  const translateY = useRef(
    new Animated.Value(DISMISSED_NOTIFICATION_TRANSFORM_Y)
  ).current;

  useEffect(() => {
    if (layoutHeight !== undefined) {
      if (nextNotification) {
        if (!_.isEqual(currentNotification, nextNotification)) {
          setCurrentNotification(nextNotification);
          Animated.timing(translateY, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }
      } else {
        Animated.timing(translateY, {
          toValue: DISMISSED_NOTIFICATION_TRANSFORM_Y,
          duration: 200,
          useNativeDriver: true,
        }).start((completed) => {
          if (completed) {
            setCurrentNotification(null);
          }
        });
      }
    }
  }, [currentNotification, nextNotification, layoutHeight, translateY]);

  const onPress = useCallback(() => {
    dismissNotification(currentNotification);
  }, [dismissNotification, currentNotification]);

  const rootBackgroundColorStyle =
    currentNotification?.type === NotificationType.FREE_TRIAL_ROOM_EXPIRING
      ? styles.warningBackgroundColor
      : styles.infoBackgroundColor;

  return (
    <Animated.View
      style={[
        styles.root,
        rootBackgroundColorStyle,
        { transform: [{ translateY }] },
      ]}
      onLayout={onLayout}
    >
      <View style={styles.overflowPlaceholder} />
      {(currentNotification?.type === NotificationType.WELCOME ||
        currentNotification?.type ===
          NotificationType.ACTIVE_REMOTE_MEMBERSHIPS_CREATED) && (
        <SafeAreaView edges={['top', 'left', 'right']}>
          <Pressable style={notificationStyles.content} onPress={onPress}>
            <Text style={notificationStyles.text}>
              {currentNotification.description}
            </Text>
          </Pressable>
        </SafeAreaView>
      )}
      {currentNotification?.type === NotificationType.UPCOMING_DEPARTURE && (
        <UpcomingDepartureNotification notification={currentNotification} />
      )}
      {currentNotification?.type ===
        NotificationType.PROPOSED_EMPTY_GROUP_DEPARTURE && (
        <ProposedEmptyGroupDepartureNotification />
      )}
      {currentNotification?.type === NotificationType.MATCHMAKING && (
        <MatchmakingNotification />
      )}
      {currentNotification?.type ===
        NotificationType.FREE_TRIAL_ROOM_EXPIRING && (
        <FreeTrialRoomExpiringNotification />
      )}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: -OVERFLOW_HEIGHT,
    alignSelf: 'stretch',
  },
  infoBackgroundColor: {
    backgroundColor: GREEN,
  },
  warningBackgroundColor: {
    backgroundColor: YELLOW,
  },
  overflowPlaceholder: {
    height: OVERFLOW_HEIGHT,
  },
});
