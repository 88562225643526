import _ from 'lodash';
import { selector } from 'recoil';
import videoGridCellsCountState from './videoGridCellsCountState';

export interface VideoGridRowLayoutSize {
  startIndex: number;
  size: number;
}

export interface VideoGridLayoutSize {
  rowsCount: number;
  maxRowSize: number;
  rows: VideoGridRowLayoutSize[];
}

export function layoutSizeCandidatesForCellsCount(cellsCount: number) {
  const rowsCountOptions = _.range(1, cellsCount + 1);
  return _.map(rowsCountOptions, (rowsCount) => {
    const narrowRowCellsCount = Math.floor(cellsCount / rowsCount);
    const wideRowCellsCount = Math.ceil(cellsCount / rowsCount);

    const wideRowsCount = cellsCount % rowsCount;
    const narrowRowsCount = rowsCount - wideRowsCount;

    const leadingNarrowRowsCount = Math.floor(narrowRowsCount / 2);

    const firstWideRowIndex = leadingNarrowRowsCount;

    let nextStartIndex = 0;
    const rows = _.times(rowsCount, (rowIndex) => {
      const wide =
        rowIndex >= firstWideRowIndex &&
        rowIndex < firstWideRowIndex + wideRowsCount;
      const size = wide ? wideRowCellsCount : narrowRowCellsCount;
      const videoGridRowLayoutCandidate = {
        startIndex: nextStartIndex,
        size,
      };
      nextStartIndex += size;
      return videoGridRowLayoutCandidate;
    });

    return {
      rowsCount,
      maxRowSize: wideRowCellsCount,
      rows,
    };
  });
}

const roomVideoGridLayoutSizeCandidatesState = selector<VideoGridLayoutSize[]>({
  key: 'roomVideoGridLayoutSizeCandidatesState',
  get: ({ get }) => {
    const groupSize = get(videoGridCellsCountState);
    return layoutSizeCandidatesForCellsCount(groupSize);
  },
});
export default roomVideoGridLayoutSizeCandidatesState;
