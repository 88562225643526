import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Pressable, StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import {
  BARELY_TRANSPARENT_GRAY,
  DEFAULT_SPACING,
} from '../../../config/constants';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import useVideoGridCellScaledStyle from '../../../hooks/useVideoGridCellScaledStyle';
import membershipIdState from '../../../state/membershipIdState';
import { CloseIcon, EmojiWhiteIcon } from '../../Icons';
import EmojiReactionsMenuButton from './EmojiReactionsMenuButton';

const styles = StyleSheet.create({
  root: {
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  menu: {
    backgroundColor: BARELY_TRANSPARENT_GRAY,
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  showMenuButtonIconContainer: {
    backgroundColor: BARELY_TRANSPARENT_GRAY,
  },
  emojiButtonStyle: {
    position: 'absolute',
  },
  clapEmojiButtonStyle: {
    top: 0,
    right: 0,
  },
  laughingEmojiButtonStyle: {
    top: '12%',
    right: '33%',
  },
  heartEmojiButtonStyle: {
    left: '12%',
    bottom: '33%',
  },
  fireEmojiButtonStyle: {
    left: 0,
    bottom: 0,
  },
});

const SHOW_MENU_ICON_SIZE = 20;
const SHOW_MENU_ICON_PADDING = 6;
const SHOW_MENU_ICON_CONTAINER_SIZE =
  SHOW_MENU_ICON_SIZE + SHOW_MENU_ICON_PADDING * 2;
const SHOW_MENU_BUTTON_ICON_RADIUS = SHOW_MENU_ICON_CONTAINER_SIZE / 2;
function ShowMenuButtonIcon() {
  const membershipId = useRecoilValue(membershipIdState);

  const scaledContainerStyle = useVideoGridCellScaledStyle(
    {
      padding: SHOW_MENU_ICON_PADDING,
      borderRadius: SHOW_MENU_BUTTON_ICON_RADIUS,
    },
    membershipId
  );

  const scaledIconStyle = useVideoGridCellScaledStyle(
    {
      height: SHOW_MENU_ICON_SIZE,
      width: SHOW_MENU_ICON_SIZE,
    },
    membershipId
  );

  return (
    <View style={[scaledContainerStyle, styles.showMenuButtonIconContainer]}>
      <EmojiWhiteIcon {...scaledIconStyle} />
    </View>
  );
}

const HIDE_MENU_ICON_SIZE = 18;
const HIDE_MENU_ICON_PADDING =
  (SHOW_MENU_ICON_CONTAINER_SIZE - HIDE_MENU_ICON_SIZE) / 2;
function HideMenuButtonIcon() {
  const membershipId = useRecoilValue(membershipIdState);
  const scaledIconContainerStyle = useVideoGridCellScaledStyle(
    {
      padding: HIDE_MENU_ICON_PADDING,
    },
    membershipId
  );
  const scaledIconStyle = useVideoGridCellScaledStyle(
    {
      height: HIDE_MENU_ICON_SIZE,
      width: HIDE_MENU_ICON_SIZE,
    },
    membershipId
  );

  return (
    <View style={scaledIconContainerStyle}>
      <CloseIcon color="white" {...scaledIconStyle} />
    </View>
  );
}

export default function EmojiReactionsMenuOverlay() {
  const roomSubscription = useContext(RoomSubscriptionContext);
  const membershipId = useRecoilValue(membershipIdState);

  const opacity = useRef(new Animated.Value(0)).current;
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    Animated.spring(opacity, {
      toValue: menuVisible ? 1 : 0,
      bounciness: 0,
      useNativeDriver: true,
    }).start();
  }, [menuVisible, opacity]);

  const animatedMenuStyle = useMemo(
    () => ({
      opacity,
    }),
    [opacity]
  );

  const scaledMenuStyle = useVideoGridCellScaledStyle(
    {
      borderRadius: DEFAULT_SPACING,
    },
    membershipId
  );

  const scaledMenuVisibilityButtonStyle = useVideoGridCellScaledStyle(
    {
      padding: DEFAULT_SPACING,
    },
    membershipId
  );

  const scaledMenuContainerStyle = useVideoGridCellScaledStyle(
    {
      width: 104,
      height: 104,
      margin: DEFAULT_SPACING,
    },
    membershipId
  );

  if (!roomSubscription) {
    return null;
  }
  return (
    <View style={[StyleSheet.absoluteFill, styles.root]}>
      <Animated.View
        style={[
          StyleSheet.absoluteFill,
          styles.menu,
          animatedMenuStyle,
          scaledMenuStyle,
        ]}
      >
        <View style={scaledMenuContainerStyle}>
          <View style={[styles.emojiButtonStyle, styles.clapEmojiButtonStyle]}>
            <EmojiReactionsMenuButton emoji="👏" disabled={!menuVisible} />
          </View>
          <View
            style={[styles.emojiButtonStyle, styles.laughingEmojiButtonStyle]}
          >
            <EmojiReactionsMenuButton emoji="😂" disabled={!menuVisible} />
          </View>
          <View style={[styles.emojiButtonStyle, styles.heartEmojiButtonStyle]}>
            <EmojiReactionsMenuButton emoji="❤️" disabled={!menuVisible} />
          </View>
          <View style={[styles.emojiButtonStyle, styles.fireEmojiButtonStyle]}>
            <EmojiReactionsMenuButton emoji="🔥" disabled={!menuVisible} />
          </View>
        </View>
      </Animated.View>
      <Pressable
        style={[scaledMenuVisibilityButtonStyle]}
        onPress={() => setMenuVisible(!menuVisible)}
      >
        {menuVisible ? <HideMenuButtonIcon /> : <ShowMenuButtonIcon />}
      </Pressable>
    </View>
  );
}
