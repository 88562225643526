import React, { PropsWithChildren, useMemo, useState } from 'react';
import {
  Linking,
  Pressable,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native';
import UAParser from 'ua-parser-js';
import AppleAppStoreButton from '../../assets/apple-app-store-button.svg';
import GooglePlayStoreButton from '../../assets/google-play-store-button.svg';
import { DEFAULT_SPACING } from '../config/constants';
import screenStyles from '../config/screenStyles';
import workflowScreenStyles from '../config/workflowScreenStyles';
import BodyText from './BodyText';
import Header from './Header';
import NavigationHeader from './NavigationHeader';
import PrimaryButton from './PrimaryButton';
import ResponsiveContainer from './ResponsiveContainer';
import { NATIVE_APP_URI_SCHEME } from './StoopScreens';
import Svg from './Svg';

const styles = StyleSheet.create({
  actions: {
    marginTop: DEFAULT_SPACING,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  appStoreButton: {
    margin: DEFAULT_SPACING,
  },
  openStoopButton: {
    height: 40,
    margin: DEFAULT_SPACING,
  },
});

const IOS_BUTTON_WIDTH = 120;
const ANDROID_BUTTON_WIDTH = 135;

export default function RecommendMobileApp({
  children,
}: PropsWithChildren<{}>) {
  const osName = useMemo(() => {
    const uaParser = new UAParser();
    return uaParser.getOS().name;
  }, []);

  const [isDismissed, setIsDismissed] = useState(false);

  if (!isDismissed && (osName === 'iOS' || osName === 'Android')) {
    return (
      <View style={screenStyles.root}>
        <SafeAreaView style={screenStyles.screen}>
          <NavigationHeader onClosePressed={() => setIsDismissed(true)} />
          <ResponsiveContainer>
            <View style={workflowScreenStyles.group}>
              <Header text="Try the Stoop app" />
              <BodyText text="Stoop for web isn't optimized for your device, but the app is. Check it out!" />
              <View style={styles.actions}>
                <View style={styles.appStoreButton}>
                  {osName === 'iOS' ? (
                    <Pressable
                      onPress={() =>
                        Linking.openURL(
                          'https://apps.apple.com/us/app/stoop-video/id1543505676'
                        )
                      }
                    >
                      <Svg>
                        <AppleAppStoreButton
                          width={IOS_BUTTON_WIDTH}
                          height={40}
                        />
                      </Svg>
                    </Pressable>
                  ) : (
                    <Pressable
                      onPress={() =>
                        Linking.openURL(
                          'https://play.google.com/store/apps/details?id=video.stoop.Stoop'
                        )
                      }
                    >
                      <Svg>
                        <GooglePlayStoreButton
                          width={ANDROID_BUTTON_WIDTH}
                          height={40}
                        />
                      </Svg>
                    </Pressable>
                  )}
                </View>
                <PrimaryButton
                  title="Open Stoop"
                  onPress={() =>
                    Linking.openURL(
                      `${NATIVE_APP_URI_SCHEME}${window.location.pathname}`
                    )
                  }
                  style={
                    ([
                      {
                        width:
                          osName === 'iOS'
                            ? IOS_BUTTON_WIDTH
                            : ANDROID_BUTTON_WIDTH,
                      },
                    ],
                    styles.openStoopButton)
                  }
                />
              </View>
            </View>
          </ResponsiveContainer>
        </SafeAreaView>
      </View>
    );
  }

  return <>{children}</>;
}
