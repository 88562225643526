import _ from 'lodash';
import React, { ReactElement } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { DEFAULT_SPACING, GRAY_ACTION } from '../../../config/constants';

interface Props<T> {
  memberships: T[];
  render: (membership: T, index: number) => ReactElement | null;
  after?: ReactElement;
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
  extraMemberships: {
    height: DEFAULT_SPACING * 4,
    minWidth: DEFAULT_SPACING * 4,
    paddingHorizontal: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING,
    backgroundColor: GRAY_ACTION,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: DEFAULT_SPACING,
  },
  extraMembershipsText: {
    color: 'white',
  },
});

export default function GroupListItemSummarizedMemberships<T>({
  memberships,
  render,
  after,
}: Props<T>) {
  const hasExtraMemberships = _.size(memberships) > 3;
  const membershipsToRender = hasExtraMemberships
    ? _.slice(memberships, 0, 2)
    : _.slice(memberships, 0, 3);
  const extraMembershipsCount = hasExtraMemberships
    ? _.size(memberships) - 2
    : 0;

  return (
    <View style={styles.root}>
      {_.map(membershipsToRender, render)}
      {hasExtraMemberships && (
        <View style={styles.extraMemberships}>
          <Text style={styles.extraMembershipsText}>
            +{extraMembershipsCount}
          </Text>
        </View>
      )}
      {after}
    </View>
  );
}
