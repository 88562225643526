import { Platform, StyleSheet } from 'react-native';

const screenStyles = StyleSheet.create({
  root: {
    flex: 1,
    alignSelf: 'stretch',
    alignContent: 'stretch',
    alignItems: 'stretch',
  },
  screen: Platform.select({
    web: {
      flex: 1,
      alignSelf: 'stretch',
    },
    default: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }),
});
export default screenStyles;
