import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import camelize from 'camelize';
import _ from 'lodash';
import { useQueryClient } from 'react-query';
import snakeize from 'snakeize';
import AUTHORIZATION_STORE from '../models/AuthorizationStore';
import config from './config';

export const API_QUERY_KEY = 'API_QUERY_KEY';

export function useInvalidateApiQueries() {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(API_QUERY_KEY);
  };
}

const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: `${config().apiUrl}/api/mobile/v1`,
  responseType: 'json',
  withCredentials: false,
};

const snakeizeJsonRequest = (request: AxiosRequestConfig) => {
  const { data, headers } = request;
  const isJson = !headers || headers['Content-Type'] === 'application/json';
  if (data && isJson) {
    return { ...request, data: snakeize(data) };
  } else {
    return request;
  }
};

const camelizeResponse = (response: AxiosResponse) => {
  if (_.has(response, 'data')) {
    return { ...response, data: camelize(response.data) };
  }
  return response;
};

const addAuthToRequest = async (axiosConfig: AxiosRequestConfig) => {
  const auth = await AUTHORIZATION_STORE.getAsync();
  if (auth) {
    axiosConfig.headers.Authorization = auth;
  }
  return axiosConfig;
};

const clearAuthOn401s = async ({ response }) => {
  if (response?.status === 401) {
    await AUTHORIZATION_STORE.delete();
  }
  return response;
};

const Api = Axios.create(axiosRequestConfig);
Api.interceptors.request.use(snakeizeJsonRequest);
Api.interceptors.response.use(camelizeResponse);
Api.interceptors.request.use(addAuthToRequest);
Api.interceptors.response.use(null, clearAuthOn401s);

const UnauthenticatedApi = Axios.create(axiosRequestConfig);
UnauthenticatedApi.interceptors.request.use(snakeizeJsonRequest);
UnauthenticatedApi.interceptors.response.use(camelizeResponse);
UnauthenticatedApi.interceptors.response.use(null, ({ response }) => response);

export { UnauthenticatedApi };
export default Api;
