import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import screenStyles from '../../config/screenStyles';
import usePortraitOrientation from '../../hooks/usePortraitOrientation';
import { Screen, ScreenStack } from '../../react-native-screens';
import draftProfileAvatarUriState from '../../state/draftProfileAvatarUriState';
import draftProfileNameState from '../../state/draftProfileNameState';
import draftProfileState from '../../state/draftProfileState';
import editProfileFlowVisibleState from '../../state/editProfileFlowVisibleState';
import ConfirmDraftProfile from './ConfirmDraftProfile';
import DraftProfileAvatarUriForm from './DraftProfileAvatarUriForm';
import DraftProfileNameForm from './DraftProfileNameForm';

export default function EditProfile() {
  const setEditProfileFlowVisible = useSetRecoilState(
    editProfileFlowVisibleState
  );

  const draftProfileName = useRecoilValue(draftProfileNameState);
  const resetDraftProfileName = useResetRecoilState(draftProfileNameState);
  const draftProfileAvatarUri = useRecoilValue(draftProfileAvatarUriState);
  const resetDraftProfileAvatarUri = useResetRecoilState(
    draftProfileAvatarUriState
  );
  const resetDraftProfile = useResetRecoilState(draftProfileState);

  useEffect(() => resetDraftProfile, [resetDraftProfile]);

  usePortraitOrientation();

  const screens = useMemo(
    () =>
      _.compact(
        _.flattenDeep([
          <Screen key="draft_profile_name_form" style={screenStyles.screen}>
            <DraftProfileNameForm />
          </Screen>,
          draftProfileName && [
            <Screen
              key="draft_profile_avatar_base_64_form"
              style={screenStyles.screen}
              onDismissed={resetDraftProfileName}
            >
              <DraftProfileAvatarUriForm
                onBackPressed={resetDraftProfileName}
                isOnScreen={!draftProfileAvatarUri}
              />
            </Screen>,
            draftProfileAvatarUri && (
              <Screen
                key="confirm"
                style={screenStyles.screen}
                onDismissed={resetDraftProfileAvatarUri}
              >
                <ConfirmDraftProfile
                  onBackPressed={resetDraftProfileAvatarUri}
                  onConfirmed={() => setEditProfileFlowVisible(false)}
                />
              </Screen>
            ),
          ],
        ])
      ),
    [
      draftProfileName,
      resetDraftProfileName,
      draftProfileAvatarUri,
      resetDraftProfileAvatarUri,
      setEditProfileFlowVisible,
    ]
  );

  return <ScreenStack style={screenStyles.root}>{screens}</ScreenStack>;
}
