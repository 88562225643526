import _ from 'lodash';
import { DefaultValue, selectorFamily } from 'recoil';
import selectedRemoteMembershipIdsState from './selectedRemoteMembershipIdsState';

const remoteMembershipIsSelectedState = selectorFamily<boolean, string>({
  key: 'remoteMembershipIsSelectedState',
  get: (id) => {
    return ({ get }) => {
      return _.includes(get(selectedRemoteMembershipIdsState), id);
    };
  },
  set: (id) => {
    return ({ get, set }, newValue) => {
      if (newValue instanceof DefaultValue || !newValue) {
        set(
          selectedRemoteMembershipIdsState,
          _.difference(get(selectedRemoteMembershipIdsState), [id])
        );
      } else {
        set(
          selectedRemoteMembershipIdsState,
          _.union(get(selectedRemoteMembershipIdsState), [id])
        );
      }
    };
  },
});
export default remoteMembershipIsSelectedState;
