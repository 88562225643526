import React from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import Header from '../Header';
import LottieView from '../LottieView';
import ResponsiveContainer from '../ResponsiveContainer';
import WorkflowNavigationHeader from '../workflows/WorkflowNavigationHeader';

export default function Success() {
  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <WorkflowNavigationHeader />
      <View style={workflowScreenStyles.content}>
        <ResponsiveContainer>
          <View style={workflowScreenStyles.group}>
            <Header text="Success!" />
            <View
              style={[styles.illustration, workflowScreenStyles.illustration]}
            >
              <LottieView
                source={require('../../../assets/animations/check.json')}
                autoPlay
              />
            </View>
          </View>
        </ResponsiveContainer>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  illustration: {
    height: 200,
    width: 200,
  },
});
