import React from 'react';
import { Text, StyleSheet, TextStyle, StyleProp } from 'react-native';
import { DEFAULT_SPACING } from '../config/constants';

interface Props {
  text: string;
  style?: StyleProp<TextStyle>;
}

export default function BodyText({ text, style }: Props) {
  return <Text style={[styles.bodyText, style]}>{text}</Text>;
}

const styles = StyleSheet.create({
  bodyText: {
    fontSize: DEFAULT_SPACING * 2,
    lineHeight: DEFAULT_SPACING * 2.5,
    textAlign: 'center',
  },
});
