import { useState } from 'react';
import useSubscription from './useSubscription';

interface RoomPreviewMembership {
  id: string;
  avatarPath: string;
  name: string;
}

interface RoomPreview {
  id: string;
  description: string;
  memberships: RoomPreviewMembership[];
}

enum RoomPreviewChannelEvent {
  CONNECTED = 'connected',
  UPDATED = 'updated',
}

export default function useRoomPreview(membershipId: string) {
  const [roomPreview, setRoomPreview] = useState<RoomPreview>();

  useSubscription(
    {
      channel: 'RoomPreviewChannel',
      membershipId,
    },
    {
      [RoomPreviewChannelEvent.CONNECTED]: (preview) => {
        setRoomPreview(preview);
      },
      [RoomPreviewChannelEvent.UPDATED]: (preview) => {
        setRoomPreview(preview);
      },
    }
  );

  return roomPreview;
}
