import { Platform, StyleSheet } from 'react-native';
import { DEFAULT_SPACING, WHITE_TINT } from './constants';

const videoButtonStyles = StyleSheet.create({
  action: {
    height: DEFAULT_SPACING * 8,
    width: DEFAULT_SPACING * 8,
    borderRadius: DEFAULT_SPACING * 4,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    ...Platform.select({
      web: {},
      default: {
        backgroundColor: WHITE_TINT,
        marginHorizontal: DEFAULT_SPACING,
      },
    }),
  },
  actionHighlight: {
    backgroundColor: 'white',
  },
  join: {
    height: DEFAULT_SPACING * 4,
    width: DEFAULT_SPACING * 4,
    paddingHorizontal: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default videoButtonStyles;
