import React from 'react';
import { SafeAreaView, View } from 'react-native';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import BodyText from '../BodyText';
import Header from '../Header';
import PhoneGroupIllustration from '../PhoneGroupIllustration';
import PrimaryButton from '../PrimaryButton';
import ResponsiveContainer from '../ResponsiveContainer';
import SecondaryButton from '../SecondaryButton';
import WorkflowNavigationHeader from '../workflows/WorkflowNavigationHeader';

interface Props {
  onSignUpPressed: () => void;
  onSignInPressed: () => void;
}

export default function Welcome({ onSignUpPressed, onSignInPressed }: Props) {
  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <WorkflowNavigationHeader />
      <View style={workflowScreenStyles.content}>
        <ResponsiveContainer>
          <View style={workflowScreenStyles.group}>
            <Header text="Welcome to Stoop!" />
            <BodyText text="Video chat with small groups" />
            <View style={workflowScreenStyles.illustration}>
              <PhoneGroupIllustration />
            </View>
          </View>
          <View style={workflowScreenStyles.actions}>
            <PrimaryButton title="Continue" onPress={onSignUpPressed} />
            <SecondaryButton title="Log in" onPress={onSignInPressed} />
          </View>
        </ResponsiveContainer>
      </View>
    </SafeAreaView>
  );
}
