import React, { useCallback, useContext } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { GRAY_ACTION, DEFAULT_SPACING } from '../../../config/constants';
import videoButtonStyles from '../../../config/videoButtonStyles';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import useVideoGridCellScaledStyle from '../../../hooks/useVideoGridCellScaledStyle';
import { PlusIcon } from '../../Icons';

export default function JoinDepartureButton({ id }: { id: string }) {
  const roomSubscription = useContext(RoomSubscriptionContext);

  const onPress = useCallback(() => {
    roomSubscription.perform('join_departure', { departure: { id } });
  }, [id, roomSubscription]);

  const scaledRootStyle = useVideoGridCellScaledStyle({
    height: DEFAULT_SPACING * 4,
    width: DEFAULT_SPACING * 4,
    paddingHorizontal: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING,
    padding: DEFAULT_SPACING,
  });

  const scaledPlusIconStyle = useVideoGridCellScaledStyle({
    width: 13,
    height: 13,
  });

  return (
    <Pressable
      style={[videoButtonStyles.join, styles.root, scaledRootStyle]}
      onPress={onPress}
    >
      <PlusIcon {...scaledPlusIconStyle} />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: GRAY_ACTION,
  },
});
