import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import { useRecoilValue } from 'recoil';
import useVideoGridCellAnimatedLayoutStyle from '../../../hooks/useVideoGridCellAnimatedLayoutStyle';
import membershipIdIsLocalMembershipIdState from '../../../state/membershipIdIsLocalMembershipIdState';
import videoGridMembershipCellLayoutState, {
  VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
} from '../../../state/videoGridMembershipCellLayoutState';
import LocalMembershipVideoGridCell from './LocalMembershipVideoGridCell';
import RemoteMembershipVideoGridCell from './RemoteMembershipVideoGridCell';

export default function MembershipVideoGridCell({ id }: { id: string }) {
  const isLocalMembership = useRecoilValue(
    membershipIdIsLocalMembershipIdState(id)
  );

  const layoutStyle = useVideoGridCellAnimatedLayoutStyle(
    videoGridMembershipCellLayoutState(id)
  );

  return (
    <Animated.View style={[styles.root, layoutStyle]}>
      {isLocalMembership ? (
        <LocalMembershipVideoGridCell />
      ) : (
        <RemoteMembershipVideoGridCell id={id} />
      )}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
    height: VIDEO_GRID_MEMBERSHIP_CELL_UNTRANSFORMED_SIZE,
  },
});
