import { atom, useSetRecoilState } from 'recoil';

const editProfileFlowVisibleState = atom({
  key: 'editProfileFlowVisibleState',
  default: false,
});
export default editProfileFlowVisibleState;

export function useShowEditProfileFlow() {
  const setEditProfileFlowVisible = useSetRecoilState(
    editProfileFlowVisibleState
  );
  return () => setEditProfileFlowVisible(true);
}

export function useHideEditProfileFlow() {
  const setEditProfileFlowVisible = useSetRecoilState(
    editProfileFlowVisibleState
  );
  return () => setEditProfileFlowVisible(false);
}
