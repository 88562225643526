import Api from './Api';

export interface GuestUserUpgradeCreateParams {
  email: string;
  password: string;
  passwordConfirmation: string;
}

export async function createGuestUserUpgrade(
  guestUserUpgrade: GuestUserUpgradeCreateParams
) {
  const response = await Api.post('guest_user_upgrade', { guestUserUpgrade });

  if (response.status !== 201) {
    console.error(response);
    throw 'something went wrong';
  }

  return;
}
