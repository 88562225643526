import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { listMicrophones } from '../twilio';

const AVAILABLE_AUDIO_DEVICES_QUERY_KEY = 'AVAILABLE_AUDIO_DEVICES_QUERY_KEY';

export default function useAvailableAudioDevices() {
  return useQuery(AVAILABLE_AUDIO_DEVICES_QUERY_KEY, () => listMicrophones())
    .data;
}

export function useInvalidateAvailableAudioDevices() {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries(AVAILABLE_AUDIO_DEVICES_QUERY_KEY),
    [queryClient]
  );
}
