import { atom } from 'recoil';
import { Group } from './groupState';
import { PendingEmojiReaction } from './pendingEmojiReactionState';

export interface Room {
  twilioRoomSid: string;
  roomAccessToken: string;
  screenSharingMembershipId: string;
  expired: boolean;
  willExpireAt: string;
  groups: Group[];
  matchmakingMembershipsCount: number;
  matchmakingGracePeriodStartedAt: string | null;
  matchmakingWillCompleteAt: string | null;
  pendingEmojiReactions?: PendingEmojiReaction[];
}

const roomState = atom<Room>({
  key: 'roomState',
  default: null,
});
export default roomState;
