import {
  DefaultValue,
  selector,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import _ from 'lodash';
import roomState from './roomState';
import membershipIdState from './membershipIdState';
import { PendingEmojiReaction } from './pendingEmojiReactionState';

const pendingEmojiReactionsState = selector<PendingEmojiReaction[]>({
  key: 'pendingEmojiReactionsState',
  get: ({ get }) => {
    return get(roomState)?.pendingEmojiReactions || [];
  },
  set: ({ get, set }, newValue) => {
    const room = get(roomState);
    if (newValue instanceof DefaultValue) {
      set(roomState, _.omit(room, 'pendingEmojiReactions'));
    } else {
      set(roomState, { ...room, pendingEmojiReactions: newValue });
    }
  },
});
export default pendingEmojiReactionsState;

export function useSelectRemotePendingEmojiReactions() {
  const membershipId = useRecoilValue(membershipIdState);
  return (pendingEmojiReactions: PendingEmojiReaction[]) =>
    _.filter(
      pendingEmojiReactions,
      (pendingEmojiReaction) =>
        pendingEmojiReaction.membershipId !== membershipId
    );
}

export function useAddPendingEmojiReactions() {
  const setPendingEmojiReactionsState = useSetRecoilState(
    pendingEmojiReactionsState
  );
  return (pendingEmojiReactions: PendingEmojiReaction[]) => {
    setPendingEmojiReactionsState((currVal) => [
      ...currVal,
      ...pendingEmojiReactions,
    ]);
  };
}

export function useAddPendingEmojiReaction() {
  const addPendingEmojiReactions = useAddPendingEmojiReactions();
  return (pendingEmojiReaction: PendingEmojiReaction) => {
    addPendingEmojiReactions([pendingEmojiReaction]);
  };
}
