import React from 'react';
import { useRecoilValue } from 'recoil';
import membershipIdState from '../../../state/membershipIdState';
import mutedState from '../../../state/mutedState';
import someRemoteMembershipSelectedState from '../../../state/someRemoteMembershipSelectedState';
import EmojiReactionsMenuOverlay from './EmojiReactionsMenuOverlay';
import MutedVideoOverlay from './MutedVideoOverlay';
import SelectableVideoOverlay from './SelectableVideoOverlay';
import SelfVideo from './SelfVideo';

export default function LocalMembershipVideoGridCell() {
  const selected = useRecoilValue(someRemoteMembershipSelectedState);
  const id = useRecoilValue(membershipIdState);
  const muted = useRecoilValue(mutedState);

  return (
    <>
      <SelfVideo />
      <MutedVideoOverlay muted={muted} id={id} />
      <SelectableVideoOverlay selected={selected} membershipId={id} />
      <EmojiReactionsMenuOverlay />
    </>
  );
}
