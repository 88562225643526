import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import useIsSmallMediaQuery from '../hooks/useIsSmallMediaQuery';

export default function ResponsiveContainer({
  children,
}: PropsWithChildren<{}>) {
  const isSmall = useIsSmallMediaQuery();

  if (isSmall) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <View style={styles.container}>{children}</View>;
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    alignItems: 'center',
    width: 400,
    marginHorizontal: 'auto',
  },
});
