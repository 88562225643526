import React, { PropsWithChildren } from 'react';
import LocalParticipantVideoTrackContext from '../../contexts/LocalParticipantVideoTrackContext';
import useLocalVideoTrack from '../../hooks/useLocalVideoTrack';

export default function LocalParticipantVideoTrackProvider({
  children,
}: PropsWithChildren<{}>) {
  const localParticipantVideoTrack = useLocalVideoTrack();
  return (
    <LocalParticipantVideoTrackContext.Provider
      value={localParticipantVideoTrack}
    >
      {children}
    </LocalParticipantVideoTrackContext.Provider>
  );
}
