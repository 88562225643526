import { Formik } from 'formik';
import React, { useRef } from 'react';
import { SafeAreaView, Text, TextInput, View } from 'react-native';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useInvalidateApiQueries } from '../config/Api';
import { createSession, SessionParams } from '../config/userSessionApi';
import workflowScreenStyles from '../config/workflowScreenStyles';
import Header from './Header';
import KeyboardDismissingBackground from './KeyboardDismissingBackground';
import PrimaryButton from './PrimaryButton';
import ResponsiveContainer from './ResponsiveContainer';
import TextField from './TextField';
import WorkflowNavigationHeader from './workflows/WorkflowNavigationHeader';

const loginValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required.'),
  password: yup.string().required('Password is required.'),
});

interface Props {
  onBackPressed: () => void;
}

export default function SignInForm({ onBackPressed }: Props) {
  const invalidateApiQueries = useInvalidateApiQueries();
  const { mutateAsync, isLoading, isError, error } = useMutation(
    async (params: SessionParams) => {
      return createSession(params);
    },
    {
      onSuccess: invalidateApiQueries,
    }
  );

  const passwordRef = useRef<TextInput>();

  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <WorkflowNavigationHeader onBackPressed={onBackPressed} />
      <KeyboardDismissingBackground style={workflowScreenStyles.content}>
        <Formik<SessionParams>
          validationSchema={loginValidationSchema}
          validateOnBlur={false}
          initialValues={{ email: '', password: '' }}
          onSubmit={(values) => mutateAsync(values)}
        >
          {({ handleSubmit }) => (
            <ResponsiveContainer>
              <View style={workflowScreenStyles.group}>
                <Header text="Log in" />
                {isError && (
                  <Text style={workflowScreenStyles.errorText}>{error}</Text>
                )}
                <TextField
                  name="email"
                  label="Email"
                  keyboardType="email-address"
                  onSubmitEditing={() => passwordRef.current?.focus()}
                  returnKeyType="next"
                />
                <TextField
                  innerRef={passwordRef}
                  name="password"
                  label="Password"
                  secureTextEntry={true}
                  returnKeyType="done"
                  onSubmitEditing={handleSubmit}
                />
              </View>
              <View style={workflowScreenStyles.actions}>
                <PrimaryButton
                  title={isLoading ? 'Logging in...' : 'Log in'}
                  isSubmitting={isLoading}
                  onPress={() => handleSubmit()}
                />
              </View>
            </ResponsiveContainer>
          )}
        </Formik>
      </KeyboardDismissingBackground>
    </SafeAreaView>
  );
}
