import React from 'react';
import { Modal, Pressable, StyleSheet } from 'react-native';
import {
  BARELY_TRANSPARENT_GRAY,
  DEFAULT_SPACING,
} from '../../config/constants';
import { WorkflowModalProps } from './WorkflowModal';

export default function WorkflowModalCentered({
  children,
  onClose,
}: WorkflowModalProps) {
  return (
    <Modal visible={true} transparent={true} animationType="fade">
      <Pressable
        style={styles.centeredView}
        onPress={onClose}
        focusable={false}
      >
        <Pressable
          style={styles.content}
          onPress={(e) => e.stopPropagation()}
          focusable={false}
        >
          {children}
        </Pressable>
      </Pressable>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: BARELY_TRANSPARENT_GRAY,
  },
  content: {
    backgroundColor: 'white',
    borderRadius: DEFAULT_SPACING,
    alignSelf: 'center',
  },
});
