import React, { Suspense } from 'react';
import {
  ActivityIndicator,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native';
import { GREEN } from '../../config/constants';
import workflowScreenStyles from '../../config/workflowScreenStyles';
import usePortraitOrientation from '../../hooks/usePortraitOrientation';
import ResponsiveContainer from '../ResponsiveContainer';
import MonetizationScreens from './MonetizationScreens';

function Loading() {
  return (
    <SafeAreaView style={workflowScreenStyles.screen}>
      <View style={workflowScreenStyles.content}>
        <ResponsiveContainer>
          <View style={styles.loading}>
            <ActivityIndicator
              size="large"
              color={Platform.select({ ios: undefined, default: GREEN })}
            />
          </View>
        </ResponsiveContainer>
      </View>
    </SafeAreaView>
  );
}

export default function Monetization() {
  usePortraitOrientation();

  return (
    <Suspense fallback={<Loading />}>
      <MonetizationScreens />
    </Suspense>
  );
}

const styles = StyleSheet.create({
  loading: {
    backgroundColor: 'white',
    flexBasis: 500,
    flex: 1,
    justifyContent: 'center',
  },
});
