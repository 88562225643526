import _ from 'lodash';
import md5 from 'md5';
import { selectorFamily } from 'recoil';
import groupIdState from './groupIdState';
import groupRemoteMembershipIdsState from './groupRemoteMembershipIdsState';
import membershipIdState from './membershipIdState';

const groupSortedMembershipIdsState = selectorFamily<string[], string>({
  key: 'groupSortedMembershipIdsState',
  get: (id) => {
    return ({ get }) => {
      const groupId = get(groupIdState);
      const membershipId = get(membershipIdState);
      const groupRemoteMembershipIds = get(groupRemoteMembershipIdsState(id));

      const sortByIteratee = (iterateeId: string) =>
        md5(`${iterateeId}-${groupId}`);

      if (groupId === id) {
        if (_.isEmpty(groupRemoteMembershipIds)) {
          return [membershipId];
        } else if (_.size(groupRemoteMembershipIds) === 1) {
          return [...groupRemoteMembershipIds, membershipId];
        } else {
          return _.sortBy(
            [membershipId, ...groupRemoteMembershipIds],
            sortByIteratee
          );
        }
      } else {
        return _.sortBy(groupRemoteMembershipIds, sortByIteratee);
      }
    };
  },
});
export default groupSortedMembershipIdsState;
