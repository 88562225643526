import React, { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { DEFAULT_SPACING, GREEN } from '../../../config/constants';
import roomScreenSharingStyles from '../../../config/roomScreenSharingStyles';
import LocalParticipantScreenSharingVideoTrackContext from '../../../contexts/LocalParticipantScreenSharingVideoTrackContext';
import roomScreenSharingVideoLayoutState from '../../../state/roomScreenSharingVideoLayoutState';
import screenSharingState from '../../../state/screenSharingState';
import { LocalVideoTrackView } from '../../../twilio';
import SecondaryButton from '../../SecondaryButton';

export default function SelfRoomScreenSharingVideo() {
  const localParticipantScreenSharingVideoTrack = useContext(
    LocalParticipantScreenSharingVideoTrackContext
  );

  const onStopScreensharingPressed = useResetRecoilState(screenSharingState);

  const { width, height } = useRecoilValue(roomScreenSharingVideoLayoutState);

  const videoSizeStyle = useMemo(
    () => ({
      width: width - DEFAULT_SPACING * 2,
      height: height - DEFAULT_SPACING * 2 - 60,
    }),
    [width, height]
  );

  return (
    <View style={[roomScreenSharingStyles.root, styles.root]}>
      <View style={styles.videoContainer}>
        {localParticipantScreenSharingVideoTrack && (
          <LocalVideoTrackView
            localVideoTrack={localParticipantScreenSharingVideoTrack}
            scaleType="aspectFit"
            style={[roomScreenSharingStyles.video, videoSizeStyle]}
          />
        )}
      </View>
      <SecondaryButton
        color="white"
        style={styles.stopScreensharingButton}
        onPress={onStopScreensharingPressed}
        title="Stop Screensharing"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: GREEN,
  },
  videoContainer: {
    flex: 1,
    alignSelf: 'stretch',
  },
  stopScreensharingButton: {
    marginBottom: DEFAULT_SPACING * 2,
    padding: DEFAULT_SPACING,
    alignSelf: 'center',
  },
});
