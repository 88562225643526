import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { listCameras } from '../twilio';

const AVAILABLE_VIDEO_DEVICES_QUERY_KEY = 'AVAILABLE_VIDEO_DEVICES_QUERY_KEY';

export default function useAvailableVideoDevices() {
  return useQuery(AVAILABLE_VIDEO_DEVICES_QUERY_KEY, () => listCameras()).data;
}

export function useInvalidateAvailableVideoDevices() {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries(AVAILABLE_VIDEO_DEVICES_QUERY_KEY),
    [queryClient]
  );
}
