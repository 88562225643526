import { atom } from 'recoil';

interface Size {
  width: number;
  height: number;
}

const videoGridSizeState = atom<Size>({
  key: 'videoGridSizeState',
  default: { width: 100, height: 100 },
});
export default videoGridSizeState;
