import _ from 'lodash';
import { selector } from 'recoil';
import notificationEventsState, {
  NotificationEvent,
} from './notificationEventsState';
import pastNotificationEventsState from './pastNotificationEventsState';

const pendingNotificationEventsState = selector<NotificationEvent[]>({
  key: 'pendingNotificationEvents',
  get: ({ get }) => {
    const notificationEvents = get(notificationEventsState);
    const pastNotificationEvents = get(pastNotificationEventsState);
    return _.differenceWith(
      notificationEvents,
      pastNotificationEvents,
      _.isEqual
    );
  },
});
export default pendingNotificationEventsState;
