import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { DEFAULT_SPACING } from '../config/constants';

interface Props {
  text: string;
  style?: StyleProp<TextStyle>;
}

export default function Header({
  text,
  style,
  children,
}: PropsWithChildren<Props>) {
  if (text) {
    return <Text style={[styles.header, style]}>{text}</Text>;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

const styles = StyleSheet.create({
  header: {
    fontSize: DEFAULT_SPACING * 3.75,
    fontWeight: 'bold',
    marginBottom: DEFAULT_SPACING * 2,
    textAlign: 'center',
  },
});
