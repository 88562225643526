import listify from 'listify';
import _ from 'lodash';
import { selector } from 'recoil';
import remoteMembershipState from './remoteMembershipState';
import selectedRemoteMembershipIdsState from './selectedRemoteMembershipIdsState';
import someRemoteMembershipSelectedState from './someRemoteMembershipSelectedState';

const membershipSelectionSummaryState = selector<string>({
  key: 'membershipSelectionSummaryState',
  get: ({ get }) => {
    if (!get(someRemoteMembershipSelectedState)) {
      return;
    }

    const selectedRemoteMembershipIds = get(selectedRemoteMembershipIdsState);
    const selectedRemoteMembershipNames = _.compact(
      _.map(
        selectedRemoteMembershipIds,
        (id) => get(remoteMembershipState(id))?.name
      )
    );
    const selectedNames = ['You', ...selectedRemoteMembershipNames];
    return `${listify(selectedNames)} are selected`;
  },
});
export default membershipSelectionSummaryState;
