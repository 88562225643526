import pluralize from 'pluralize';
import React from 'react';
import Countdown from 'react-countdown';
import { Pressable, StatusBar, StyleSheet, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useRecoilValue } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import notificationStyles from '../../../config/notificationStyles';
import { useShowMonetizationFlow } from '../../../state/monetizationFlowVisibleState';
import roomState from '../../../state/roomState';

export default function FreeTrialRoomExpiringNotification() {
  const { willExpireAt } = useRecoilValue(roomState);
  const showMonetizationFlow = useShowMonetizationFlow();

  return (
    <React.Fragment>
      <StatusBar barStyle="dark-content" />
      <SafeAreaView edges={['top', 'left', 'right']}>
        <Pressable
          style={notificationStyles.content}
          onPress={showMonetizationFlow}
        >
          <Countdown
            date={willExpireAt}
            renderer={({ minutes, seconds, completed }) => {
              const effectiveMinutes = Math.round(minutes + seconds / 60);
              const formattedWillExpireAt =
                completed || effectiveMinutes < 1
                  ? 'imminently'
                  : `in ${pluralize('minute', effectiveMinutes, true)}`;
              return (
                <Text style={[styles.text, styles.textContainer]}>
                  ⏳ Free trial room will expire {formattedWillExpireAt}.{' '}
                  <Text style={[styles.text, styles.underline]}>
                    Log In or Sign Up
                  </Text>{' '}
                  to remove the time limit.
                </Text>
              );
            }}
          />
        </Pressable>
      </SafeAreaView>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    marginHorizontal: DEFAULT_SPACING * 6,
  },
  text: {
    textAlign: 'center',
    color: 'black',
    fontSize: DEFAULT_SPACING * 2,
  },
  underline: {
    textDecorationLine: 'underline',
  },
});
