import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { DEFAULT_SPACING, GREEN } from '../../../config/constants';
import useVideoGridCellScaledStyle from '../../../hooks/useVideoGridCellScaledStyle';
import {
  DEFAULT_ICON_HEIGHT,
  DEFAULT_ICON_WIDTH,
  SelectedIcon,
} from '../../Icons';

const styles = StyleSheet.create({
  selected: {
    borderColor: GREEN,
  },
  selectionIndicator: {
    position: 'absolute',
    backgroundColor: GREEN,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function SelectionIndicator({
  selected,
  membershipId,
}: {
  selected: boolean;
  membershipId: string;
}) {
  const scaledStyle = useVideoGridCellScaledStyle({
    top: DEFAULT_SPACING,
    right: DEFAULT_SPACING,
    height: DEFAULT_SPACING * 3,
    width: DEFAULT_SPACING * 3,
    borderRadius: DEFAULT_SPACING * 1.5,
  });

  const scaledSelectedIconProps = useVideoGridCellScaledStyle(
    {
      width: DEFAULT_ICON_WIDTH,
      height: DEFAULT_ICON_HEIGHT,
    },
    membershipId
  );

  return (
    selected && (
      <View style={[styles.selectionIndicator, scaledStyle]}>
        <SelectedIcon {...scaledSelectedIconProps} />
      </View>
    )
  );
}

type OverlayContainerProps = PropsWithChildren<{
  style: StyleProp<ViewStyle>;
  onPress?: () => void;
  onLongPress?: () => void;
}>;

function PressableOverlayContainer(props: OverlayContainerProps) {
  return <Pressable {...props} />;
}
function ViewOverlayContainer(props: OverlayContainerProps) {
  return <View {..._.omit(props, 'onPress', 'onLongPress')} />;
}

interface Props {
  selected: boolean;
  onPress?: () => void;
  onLongPress?: () => void;
  membershipId: string;
}

export default function SelectableVideoOverlay({
  selected,
  onPress,
  onLongPress,
  membershipId,
}: Props) {
  const scaledSelected = useVideoGridCellScaledStyle(
    {
      borderWidth: DEFAULT_SPACING / 2,
      borderRadius: DEFAULT_SPACING,
    },
    membershipId
  );

  const OverlayContainer =
    onPress || onLongPress ? PressableOverlayContainer : ViewOverlayContainer;

  return (
    <OverlayContainer
      style={[
        StyleSheet.absoluteFill,
        selected && [styles.selected, scaledSelected],
      ]}
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <SelectionIndicator selected={selected} membershipId={membershipId} />
    </OverlayContainer>
  );
}
