import { AxiosResponse } from 'axios';
import AUTHORIZATION_STORE from '../models/AuthorizationStore';
import { UnauthenticatedApi } from './Api';
import { SessionResponse } from './userSessionApi';

export async function createGuestSession() {
  const response = await UnauthenticatedApi.post<
    {},
    AxiosResponse<SessionResponse>
  >('guest_sessions');
  const {
    data: {
      session: { token },
    },
  } = response;
  const authentication = `Bearer ${token}`;
  await AUTHORIZATION_STORE.set(authentication);
}
