import React, { useCallback, useState } from 'react';
import { Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import { useRecoilState } from 'recoil';
import { DEFAULT_SPACING, GRAY, RED } from '../../../config/constants';
import useNavigateBackOrHome from '../../../hooks/useNavigateBackOrHome';
import screenSharingState from '../../../state/screenSharingState';
import { HotdogIcon, ScreenShareIcon, SettingsIcon } from '../../Icons';
import PrimaryButton from '../../PrimaryButton';
import Tooltip from '../../Tooltip';
import BottomBarMuteButton from './BottomBarMuteButton';
import BottomBarVideoMuteButton from './BottomBarVideoMuteButton';
import ChangeAudioVideoDevicesModal from '../ChangeAudioVideoDevicesModal';

export default function JoinedRoomFooter() {
  const [menuVisible, setMenuVisible] = useState(false);
  const onPressHotdog = useCallback(() => {
    setMenuVisible(true);
  }, []);

  const [screenSharing, setScreenSharing] = useRecoilState(screenSharingState);
  const onPressScreenSharing = () => {
    setMenuVisible(false);
    setScreenSharing(!screenSharing);
  };

  const [settingsVisible, setSettingsVisible] = useState(false);
  const onPressSettings = () => {
    setMenuVisible(false);
    setSettingsVisible(true);
  };

  const navigateBackOrHome = useNavigateBackOrHome();

  return (
    <View style={styles.root}>
      <View style={[styles.menuSide, styles.leftMenuSide]} />
      <View style={styles.primaryControls}>
        <BottomBarMuteButton />
        <BottomBarVideoMuteButton />
      </View>
      <View style={[styles.menuSide, styles.rightMenuSide]}>
        <PrimaryButton
          color={RED}
          title="✌ Leave"
          onPress={navigateBackOrHome}
        />
        <Tooltip text="More">
          <Pressable style={styles.menuButton} onPress={onPressHotdog}>
            <HotdogIcon />
          </Pressable>
        </Tooltip>
      </View>
      <Modal animationType="fade" transparent={true} visible={menuVisible}>
        <Pressable
          style={styles.fullScreenOverlay}
          onPress={() => setMenuVisible(false)}
        >
          <View style={styles.menu}>
            <Pressable style={styles.menuItem} onPress={onPressSettings}>
              <SettingsIcon />
              <Text style={styles.menuItemText}>Settings</Text>
            </Pressable>
            <Pressable style={styles.menuItem} onPress={onPressScreenSharing}>
              <ScreenShareIcon />
              <Text style={styles.menuItemText}>
                {screenSharing ? 'Stop sharing screen' : 'Share screen'}
              </Text>
            </Pressable>
          </View>
        </Pressable>
      </Modal>
      <ChangeAudioVideoDevicesModal
        visible={settingsVisible}
        onClose={() => setSettingsVisible(false)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignContent: 'stretch',
    backgroundColor: GRAY,
    paddingVertical: DEFAULT_SPACING * 2,
    alignItems: 'center',
  },
  primaryControls: {
    flexDirection: 'row',
    flexShrink: 1,
    flexBasis: 160,
    justifyContent: 'space-between',
  },
  menuSide: {
    flexGrow: 1,
    flexBasis: 140,
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftMenuSide: {
    justifyContent: 'flex-start',
  },
  rightMenuSide: {
    justifyContent: 'flex-end',
  },
  menuButton: {
    width: DEFAULT_SPACING * 3,
    height: DEFAULT_SPACING * 3,
    marginHorizontal: DEFAULT_SPACING * 2,
    alignContent: 'center',
    alignItems: 'center',
  },
  fullScreenOverlay: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  menu: {
    backgroundColor: GRAY,
    padding: DEFAULT_SPACING * 2,
    marginRight: DEFAULT_SPACING * 2,
    marginBottom: 78 + DEFAULT_SPACING * 2,
    borderRadius: DEFAULT_SPACING,
  },
  menuItem: {
    padding: DEFAULT_SPACING * 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuItemText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: DEFAULT_SPACING * 2,
  },
});
