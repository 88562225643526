import _ from 'lodash';
import { useMemo } from 'react';
import { IAPItemDetails, InAppPurchase } from '../models/InAppPurchasesStore';
import useMonthlySubscription from './useMonthlySubscription';

export function resultsToIAPItemDetails(
  results: IAPItemDetails | InAppPurchase | undefined
): IAPItemDetails | null {
  if (!_.has(results, 'purchaseTime')) {
    return results as IAPItemDetails;
  }
}

export default function useAvailableMonthlySubscription() {
  const monthlySubscription = useMonthlySubscription();
  const availableMonthlySubscription = useMemo(
    () => resultsToIAPItemDetails(monthlySubscription),
    [monthlySubscription]
  );
  return availableMonthlySubscription;
}
