import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import localMembershipState, {
  LocalMembership,
} from '../state/localMembershipState';
import useSubscription from './useSubscription';

const HEARTBEAT_INTERVAL_DURATION = 2500;

enum LocalMembershipChannelEvent {
  CONNECTED = 'connected',
  UPDATED = 'updated',
}

const LOCAL_MUTABLE_FIELDS = [
  'muted',
  'twilioParticipantSid',
  'videoMuted',
  'shouting',
  'selectedMembershipIds',
  'screenSharing',
  'groupId',
];
const REMOTE_MUTABLE_FIELDS = [
  'avatarPath',
  'groupId',
  'name',
  'selectedMembershipIds',
  'screenSharing',
  'matchmaking',
];

function localMembershipToUpdateActionAttributes(
  localMembership: Partial<LocalMembership>
) {
  return _.pick(localMembership, LOCAL_MUTABLE_FIELDS);
}

function localMembershipEventDataToSetStateAttributes(
  localMembership: LocalMembership
) {
  return _.pick(localMembership, REMOTE_MUTABLE_FIELDS);
}

const LOCAL_MUTABLE_FIELDS_WITH_SIMPLE_EQUALITY = _.difference(
  LOCAL_MUTABLE_FIELDS,
  ['selectedMembershipIds']
);

function needsUpdate(
  currentLocalValue: Partial<LocalMembership>,
  lastSyncedValue: Partial<LocalMembership>
) {
  if (
    _.some(
      LOCAL_MUTABLE_FIELDS_WITH_SIMPLE_EQUALITY,
      (field) => currentLocalValue[field] !== lastSyncedValue[field]
    )
  ) {
    return true;
  }

  const sortedCurrentLocalValueSelectedMembershipIds = _.sortBy(
    currentLocalValue.selectedMembershipIds
  );
  const sortedLastSyncedValueSelectedMembershipIds = _.sortBy(
    lastSyncedValue.selectedMembershipIds
  );
  return !_.isEqual(
    sortedCurrentLocalValueSelectedMembershipIds,
    sortedLastSyncedValueSelectedMembershipIds
  );
}

export default function useLocalMembershipSync() {
  const [localMembership, setLocalMembership] = useRecoilState(
    localMembershipState
  );
  const { id } = localMembership;

  const updateActionAttributes = useMemo(
    () => localMembershipToUpdateActionAttributes(localMembership),
    [localMembership]
  );

  const [
    syncedUpdateActionAttributes,
    setSyncedUpdateActionAttributes,
  ] = useState({});

  const onLocalMembershipEvent = useCallback(
    (updatedLocalMembership: LocalMembership) => {
      setLocalMembership(
        localMembershipEventDataToSetStateAttributes(updatedLocalMembership)
      );
      setSyncedUpdateActionAttributes(
        localMembershipToUpdateActionAttributes({
          ...syncedUpdateActionAttributes,
          ...updatedLocalMembership,
        })
      );
    },
    [
      setLocalMembership,
      syncedUpdateActionAttributes,
      setSyncedUpdateActionAttributes,
    ]
  );

  const subscription = useSubscription(
    {
      channel: 'LocalMembershipChannel',
      id,
    },
    {
      [LocalMembershipChannelEvent.CONNECTED]: onLocalMembershipEvent,
      [LocalMembershipChannelEvent.UPDATED]: onLocalMembershipEvent,
    }
  );

  useEffect(() => {
    if (needsUpdate(updateActionAttributes, syncedUpdateActionAttributes)) {
      subscription.perform('update', {
        localMembership: updateActionAttributes,
      });
      setSyncedUpdateActionAttributes(updateActionAttributes);
    }
  }, [updateActionAttributes, subscription, syncedUpdateActionAttributes]);

  const subscriptionRef = useRef(subscription);

  useEffect(() => {
    subscriptionRef.current = subscription;
  }, [subscription]);

  useEffect(() => {
    const interval = setInterval(() => {
      subscriptionRef.current?.perform('heartbeat', {});
    }, HEARTBEAT_INTERVAL_DURATION);
    return () => clearInterval(interval);
  }, []);

  return { subscription };
}
