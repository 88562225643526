import React from 'react';
import * as Sentry from 'sentry-expo';
import StoopRoot from './app/components/StoopRoot';
import config from './app/config/config';
import { enableScreens } from './app/react-native-screens';

enableScreens();

Sentry.init({
  dsn: config().sentryDsn,
  enableInExpoDevelopment: false,
  debug: false,
});

export default function App() {
  return <StoopRoot />;
}
