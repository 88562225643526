import React, { PropsWithChildren } from 'react';
import { useProfileQuery } from '../../hooks/useProfile';
import Onboarding from './Onboarding';

export default function RequireProfile({ children }: PropsWithChildren<{}>) {
  const profileQuery = useProfileQuery();

  if (profileQuery.data) {
    return <>{children}</>;
  }

  return <Onboarding />;
}
