import { DEFAULT_SPACING } from './constants';
import { StyleSheet } from 'react-native';

const roomPreviewButtonStyles = StyleSheet.create({
  action: {
    height: DEFAULT_SPACING * 6,
    width: DEFAULT_SPACING * 6,
    borderRadius: DEFAULT_SPACING * 3,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  actionHighlight: {
    backgroundColor: 'white',
  },
});

export default roomPreviewButtonStyles;
