import _ from 'lodash';
import { DefaultValue, selectorFamily } from 'recoil';
import pendingEmojiReactionsState from './pendingEmojiReactionsState';

export interface PendingEmojiReaction {
  id: string;
  emoji: string;
  membershipId: string;
  createdAt: string;
  pendingDuration: number;
}

const pendingEmojiReactionState = selectorFamily<PendingEmojiReaction, string>({
  key: 'pendingEmojiReactionState',
  get: (id) => ({ get }) => {
    return _.find(
      get(pendingEmojiReactionsState),
      (pendingEmojiReaction) => pendingEmojiReaction.id === id
    );
  },
  set: (id) => ({ get, set }, newValue) => {
    const pendingEmojiReactions = get(pendingEmojiReactionsState);
    const index = _.findIndex(
      pendingEmojiReactions,
      (pendingEmojiReaction) => pendingEmojiReaction.id === id
    );
    if (newValue instanceof DefaultValue) {
      set(pendingEmojiReactionsState, [
        ..._.slice(pendingEmojiReactions, 0, index),
        ..._.slice(pendingEmojiReactions, index + 1),
      ]);
    } else {
      set(pendingEmojiReactionsState, [
        ..._.slice(pendingEmojiReactions, 0, index),
        newValue,
        ..._.slice(pendingEmojiReactions, index + 1),
      ]);
    }
  },
});
export default pendingEmojiReactionState;
