import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { DEFAULT_SPACING } from '../../../config/constants';
import videoStyles from '../../../config/videoStyles';
import useVideoGridCellScaledStyle from '../../../hooks/useVideoGridCellScaledStyle';
import membershipDepartureState from '../../../state/membershipDepartureState';
import membershipIdState from '../../../state/membershipIdState';
import roomState from '../../../state/roomState';
import { TopLeftCornerIcon, TopLeftCornerIconVariant } from '../../Icons';

export default function RoundedCornersVideoOverlay({
  children,
  membershipId,
}: PropsWithChildren<{ membershipId?: string }>) {
  const scaledSize = useVideoGridCellScaledStyle(
    {
      height: DEFAULT_SPACING,
      width: DEFAULT_SPACING,
    },
    membershipId
  );

  const room = useRecoilValue(roomState);
  const departure = useRecoilValue(membershipDepartureState(membershipId));
  const currentMembershipId = useRecoilValue(membershipIdState);
  const currentMembershipDeparture = useRecoilValue(
    membershipDepartureState(currentMembershipId)
  );

  const topLeftCornerIconVariant: TopLeftCornerIconVariant = !room
    ? 'black'
    : departure
    ? departure === currentMembershipDeparture
      ? 'green'
      : 'gray'
    : 'near-black';

  return (
    <View style={videoStyles.root}>
      {children}
      <View style={StyleSheet.absoluteFill}>
        <View style={[styles.corner, styles.topLeftCorner]}>
          <TopLeftCornerIcon {...scaledSize} color={topLeftCornerIconVariant} />
        </View>
        <View style={[styles.corner, styles.topRightCorner]}>
          <TopLeftCornerIcon {...scaledSize} color={topLeftCornerIconVariant} />
        </View>
        <View style={[styles.corner, styles.bottomRightCorner]}>
          <TopLeftCornerIcon {...scaledSize} color={topLeftCornerIconVariant} />
        </View>
        <View style={[styles.corner, styles.bottomLeftCorner]}>
          <TopLeftCornerIcon {...scaledSize} color={topLeftCornerIconVariant} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  corner: {
    position: 'absolute',
  },
  topLeftCorner: {
    top: 0,
    left: 0,
  },
  topRightCorner: {
    top: 0,
    right: 0,
    transform: [{ rotate: '90deg' }],
  },
  bottomRightCorner: {
    bottom: 0,
    right: 0,
    transform: [{ rotate: '180deg' }],
  },
  bottomLeftCorner: {
    bottom: 0,
    left: 0,
    transform: [{ rotate: '270deg' }],
  },
});
