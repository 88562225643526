import _ from 'lodash';
import { DefaultValue, selector } from 'recoil';
import avatarPathState from './avatarPathState';
import groupIdState from './groupIdState';
import matchmakingState from './matchmakingState';
import membershipIdState from './membershipIdState';
import mutedState from './mutedState';
import nameState from './nameState';
import screenSharingState from './screenSharingState';
import selectedRemoteMembershipIdsState from './selectedRemoteMembershipIdsState';
import twilioParticipantSidState from './twilioParticipantSidState';
import videoMutedState from './videoMutedState';

export type LocalMembership = Partial<{
  id: string;
  twilioParticipantSid: string;
  groupId: string;
  name: string;
  avatarPath: string;
  muted: boolean;
  videoMuted: boolean;
  shouting: boolean;
  selectedMembershipIds: string[];
  screenSharing: boolean;
  matchmaking: boolean;
}>;

const localMembershipState = selector<LocalMembership>({
  key: 'localMembershipState',
  get: ({ get }) => {
    return {
      id: get(membershipIdState),
      twilioParticipantSid: get(twilioParticipantSidState),
      groupId: get(groupIdState),
      name: get(nameState),
      avatarPath: get(avatarPathState),
      muted: get(mutedState),
      videoMuted: get(videoMutedState),
      selectedMembershipIds: get(selectedRemoteMembershipIdsState),
      screenSharing: get(screenSharingState),
      matchmaking: get(matchmakingState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(twilioParticipantSidState);
      reset(groupIdState);
      reset(nameState);
      reset(avatarPathState);
      reset(mutedState);
      reset(videoMutedState);
      reset(selectedRemoteMembershipIdsState);
      reset(screenSharingState);
      reset(matchmakingState);
    } else {
      if (_.has(newValue, 'name')) {
        set(nameState, newValue.name);
      }
      if (_.has(newValue, 'avatarPath')) {
        set(avatarPathState, newValue.avatarPath);
      }
      if (_.has(newValue, 'muted')) {
        set(mutedState, newValue.muted);
      }
      if (_.has(newValue, 'groupId')) {
        set(groupIdState, newValue.groupId);
      }
      if (_.has(newValue, 'videoMuted')) {
        set(videoMutedState, newValue.videoMuted);
      }
      if (_.has(newValue, 'selectedMembershipIds')) {
        set(selectedRemoteMembershipIdsState, newValue.selectedMembershipIds);
      }
      if (_.has(newValue, 'screenSharing')) {
        set(screenSharingState, newValue.screenSharing);
      }
      if (_.has(newValue, 'matchmaking')) {
        set(matchmakingState, newValue.matchmaking);
      }
    }
  },
});
export default localMembershipState;
