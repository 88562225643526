import React from 'react';
import { Modal, StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import {
  BARELY_TRANSPARENT_GRAY,
  DEFAULT_SPACING,
} from '../../../config/constants';
import useNavigateBackOrHome from '../../../hooks/useNavigateBackOrHome';
import monetizationFlowVisibleState, {
  useShowMonetizationFlow,
} from '../../../state/monetizationFlowVisibleState';
import roomState from '../../../state/roomState';
import BodyText from '../../BodyText';
import Link from '../../Link';

export default function JoinedRoomExpiredModal() {
  const { expired } = useRecoilValue(roomState);
  const navigateBackOrHome = useNavigateBackOrHome();
  const monetizationFlowVisible = useRecoilValue(monetizationFlowVisibleState);
  const showMonetizationFlow = useShowMonetizationFlow();

  return (
    <Modal
      visible={expired && !monetizationFlowVisible}
      transparent={true}
      animationType="fade"
    >
      <View style={styles.centeredView}>
        <View style={styles.content}>
          <BodyText text="Free trial ended" style={styles.header} />
          <BodyText
            text="Unlock the room to keep chatting."
            style={styles.bodyText}
          />
          <Link
            title="Unlock"
            onPress={showMonetizationFlow}
            style={styles.unlockButton}
          />
          <Link title="Leave room" onPress={() => navigateBackOrHome()} />
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: BARELY_TRANSPARENT_GRAY,
  },
  content: {
    alignSelf: 'center',
    alignItems: 'center',
    padding: DEFAULT_SPACING * 4,
    backgroundColor: 'white',
    borderRadius: DEFAULT_SPACING,
  },
  header: {
    fontWeight: 'bold',
    marginBottom: DEFAULT_SPACING,
  },
  bodyText: {
    marginBottom: DEFAULT_SPACING,
  },
  unlockButton: {
    marginBottom: 0,
  },
});
