import React from 'react';
import { useRecoilState } from 'recoil';
import videoButtonStyles from '../../../config/videoButtonStyles';
import videoMutedState from '../../../state/videoMutedState';
import BottomBarTouchableHighlight from './BottomBarTouchableHighlight';
import { VideoIcon } from '../../Icons';
import Tooltip from '../../Tooltip';

export default function BottomBarVideoMuteButton() {
  const [videoMuted, setVideoMuted] = useRecoilState(videoMutedState);

  return (
    <Tooltip text={videoMuted ? 'Turn on camera' : 'Turn off camera'}>
      <BottomBarTouchableHighlight
        style={[
          videoButtonStyles.action,
          videoMuted ? videoButtonStyles.actionHighlight : null,
        ]}
        onPress={() => setVideoMuted(!videoMuted)}
      >
        <VideoIcon enabled={!videoMuted} />
      </BottomBarTouchableHighlight>
    </Tooltip>
  );
}
