import { StyleSheet } from 'react-native';
import { DEFAULT_SPACING, GREEN_HIGHLIGHT_ACTION } from './constants';

const notificationStyles = StyleSheet.create({
  content: {
    padding: DEFAULT_SPACING,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: DEFAULT_SPACING * 8,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  spacer: {
    height: 2 * DEFAULT_SPACING,
    width: 2 * DEFAULT_SPACING,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'stretch',
    flexWrap: 'wrap',
  },
  actionButton: {
    backgroundColor: GREEN_HIGHLIGHT_ACTION,
    marginHorizontal: DEFAULT_SPACING / 2,
    flexGrow: 1,
    justifyContent: 'center',
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING,
  },
  actionButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontSize: DEFAULT_SPACING * 2,
  },
});
export default notificationStyles;
