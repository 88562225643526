import { useKeepAwake } from 'expo-keep-awake';
import React, { PropsWithChildren, useEffect } from 'react';
import { Pressable, StatusBar, StyleSheet } from 'react-native';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { NEAR_BLACK } from '../../../config/constants';
import LocalParticipantScreenSharingVideoTrackContext from '../../../contexts/LocalParticipantScreenSharingVideoTrackContext';
import RemoteConnectionsContext from '../../../contexts/RemoteConnectionsContext';
import RoomSubscriptionContext from '../../../contexts/RoomSubscriptionContext';
import useLocalMembershipSync from '../../../hooks/useLocalMembershipSync';
import useRoomSync from '../../../hooks/useRoomSync';
import useTwilioRoom from '../../../hooks/useTwilioRoom';
import groupListLayoutState from '../../../state/groupListLayoutState';
import groupListScrollState from '../../../state/groupListScrollState';
import groupPreviewRelativeLayoutsState from '../../../state/groupPreviewRelativeLayoutsState';
import joinedRoomControlsVisibilityState from '../../../state/joinedRoomControlsVisibilityState';
import localMembershipState from '../../../state/localMembershipState';
import monetizationFlowVisibleState from '../../../state/monetizationFlowVisibleState';
import proposedEmptyGroupDepartureExistsState from '../../../state/proposedEmptyGroupDepartureExistsState';
import remoteMembershipsState from '../../../state/remoteMembershipsState';
import roomState from '../../../state/roomState';
import twilioParticipantSidState from '../../../state/twilioParticipantSidState';
import videoGridSizeState from '../../../state/videoGridSizeState';
import VideoGrid from '../video_grid/VideoGrid';
import GroupList from './GroupList';
import Hotkeys from './Hotkeys';
import JoinedRoomExpiredModal from './JoinedRoomExpiredModal';
import JoinedRoomFooter from './JoinedRoomFooter';
import JoinedRoomHeader from './JoinedRoomHeader';
import RemoteMembershipAudioSubscriptions from './RemoteMembershipAudioSubscriptions';

function useResetEphemeralStateOnUnmount(state: RecoilState<any>) {
  const resetEphemeralState = useResetRecoilState(state);
  useEffect(() => resetEphemeralState, [resetEphemeralState]);
}

function useResetAllEphemeralStateOnUnmount() {
  useResetEphemeralStateOnUnmount(groupListLayoutState);
  useResetEphemeralStateOnUnmount(groupListScrollState);
  useResetEphemeralStateOnUnmount(groupPreviewRelativeLayoutsState);
  useResetEphemeralStateOnUnmount(joinedRoomControlsVisibilityState);
  useResetEphemeralStateOnUnmount(localMembershipState);
  useResetEphemeralStateOnUnmount(monetizationFlowVisibleState);
  useResetEphemeralStateOnUnmount(proposedEmptyGroupDepartureExistsState);
  useResetEphemeralStateOnUnmount(remoteMembershipsState);
  useResetEphemeralStateOnUnmount(roomState);
  useResetEphemeralStateOnUnmount(videoGridSizeState);
}

function RoomContent() {
  useKeepAwake();

  const { twilioRoomSid, roomAccessToken } = useRecoilValue(roomState);

  const {
    remoteMemberships,
    remoteConnections,
    localParticipantScreenSharingVideoTrack,
    localParticipantTwilioParticipantSid,
  } = useTwilioRoom({ twilioRoomSid, token: roomAccessToken });

  const setRemoteMemberships = useSetRecoilState(remoteMembershipsState);

  useEffect(() => {
    setRemoteMemberships(remoteMemberships);
  }, [remoteMemberships, setRemoteMemberships]);

  const setTwilioParticipantSid = useSetRecoilState(twilioParticipantSidState);

  useEffect(() => {
    setTwilioParticipantSid(localParticipantTwilioParticipantSid);
  }, [localParticipantTwilioParticipantSid, setTwilioParticipantSid]);

  const [
    joinedRoomControlsVisibility,
    setJoinedRoomControlsVisibility,
  ] = useRecoilState(joinedRoomControlsVisibilityState);

  useResetAllEphemeralStateOnUnmount();

  return (
    <Pressable
      style={styles.root}
      onPress={() =>
        setJoinedRoomControlsVisibility(!joinedRoomControlsVisibility)
      }
    >
      <StatusBar barStyle="light-content" />
      <RemoteConnectionsContext.Provider value={remoteConnections}>
        <LocalParticipantScreenSharingVideoTrackContext.Provider
          value={localParticipantScreenSharingVideoTrack}
        >
          <JoinedRoomHeader />
          <VideoGrid />
          <RemoteMembershipAudioSubscriptions />
          <GroupList />
          <JoinedRoomFooter />
          <JoinedRoomExpiredModal />
          <Hotkeys />
        </LocalParticipantScreenSharingVideoTrackContext.Provider>
      </RemoteConnectionsContext.Provider>
    </Pressable>
  );
}

function WithRoomSync({ children }: PropsWithChildren<{}>) {
  const { room, subscription } = useRoomSync();
  useLocalMembershipSync();

  if (room && subscription) {
    return (
      <RoomSubscriptionContext.Provider value={subscription}>
        {children}
      </RoomSubscriptionContext.Provider>
    );
  }

  return null;
}

export default function JoinedRoom() {
  return (
    <WithRoomSync>
      <RoomContent />
    </WithRoomSync>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: NEAR_BLACK,
  },
});
