import EventEmitter from 'eventemitter3';
import _ from 'lodash';
import Video from 'twilio-video';
import { toNetworkQualityLevel } from './NetworkQualityLevel';
import RemoteAudioTrackPublication from './RemoteAudioTrackPublication';
import RemoteVideoTrackPublication from './RemoteVideoTrackPublication';

export default class RemoteParticipant {
  twilioRemoteParticipant: Video.RemoteParticipant;
  remoteVideoTracks: RemoteVideoTrackPublication[] = [];
  remoteAudioTracks: RemoteAudioTrackPublication[] = [];
  eventEmitter = new EventEmitter();

  constructor(twilioRemoteParticipant: Video.RemoteParticipant) {
    this.twilioRemoteParticipant = twilioRemoteParticipant;

    twilioRemoteParticipant.audioTracks.forEach(
      (twilioRemoteAudioTrackPublication) => {
        this.remoteAudioTracks.push(
          new RemoteAudioTrackPublication(twilioRemoteAudioTrackPublication)
        );
      }
    );
    twilioRemoteParticipant.videoTracks.forEach(
      (twilioRemoteVideoTrackPublication) => {
        this.remoteVideoTracks.push(
          new RemoteVideoTrackPublication(twilioRemoteVideoTrackPublication)
        );
      }
    );

    twilioRemoteParticipant.on(
      'networkQualityLevelChanged',
      this.onNetworkQualityLevelChanged
    );
    twilioRemoteParticipant.on('trackDisabled', this.onTrackDisabled);
    twilioRemoteParticipant.on('trackEnabled', this.onTrackEnabled);
    twilioRemoteParticipant.on('trackPublished', this.onTrackPublished);
    twilioRemoteParticipant.on(
      'trackPublishPriorityChanged',
      this.onTrackPublishPriorityChanged
    );
    twilioRemoteParticipant.on('trackSubscribed', this.onTrackSubscribed);
    twilioRemoteParticipant.on(
      'trackSubscriptionFailed',
      this.onTrackSubscriptionFailed
    );
    twilioRemoteParticipant.on('trackSwitchedOff', this.onTrackSwitchedOff);
    twilioRemoteParticipant.on('trackSwitchedOn', this.onTrackSwitchedOn);
    twilioRemoteParticipant.on('trackUnpublished', this.onTrackUnpublished);
    twilioRemoteParticipant.on('trackUnsubscribed', this.onTrackUnsubscribed);
  }

  on(event: string, fn: (...args: any[]) => void) {
    this.eventEmitter.on(event, fn);
  }

  off(event: string, fn: (...args: any[]) => void) {
    this.eventEmitter.off(event, fn);
  }

  onNetworkQualityLevelChanged = (twilioNetworkQualityLevel: number) => {
    this.eventEmitter.emit('networkQualityLevelChanged', {
      participant: this,
      networkQualityLevel: toNetworkQualityLevel(twilioNetworkQualityLevel),
    });
  };

  onTrackDisabled = (twilioPublication: Video.RemoteTrackPublication) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackDisabled`, {
      participant: this,
      publication,
    });
  };

  onTrackEnabled = (twilioPublication: Video.RemoteTrackPublication) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackEnabled`, {
      participant: this,
      publication,
    });
  };

  onTrackPublished = (twilioPublication: Video.RemoteTrackPublication) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      const remoteAudioTrackPublication = new RemoteAudioTrackPublication(
        twilioPublication as Video.RemoteAudioTrackPublication
      );
      this.remoteAudioTracks.push(remoteAudioTrackPublication);
      publication = remoteAudioTrackPublication;
    } else if (kind === 'video') {
      const remoteVideoTrackPublication = new RemoteVideoTrackPublication(
        twilioPublication as Video.RemoteVideoTrackPublication
      );
      this.remoteVideoTracks.push(remoteVideoTrackPublication);
      publication = remoteVideoTrackPublication;
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackPublished`, {
      participant: this,
      publication,
    });
  };

  onTrackPublishPriorityChanged = (
    priority: Video.Track.Priority,
    twilioPublication: Video.RemoteTrackPublication
  ) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackPublishPriorityChanged`, {
      participant: this,
      publication,
      priority,
    });
  };

  onTrackSubscribed = (
    _twilioTrack: Video.RemoteTrack,
    twilioPublication: Video.RemoteTrackPublication
  ) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }
    const trackProperty = { [`${kind}Track`]: publication?.track };

    this.eventEmitter.emit(`${kind}TrackSubscribed`, {
      participant: this,
      publication,
      ...trackProperty,
    });
  };

  onTrackSubscriptionFailed = (
    error: any,
    twilioPublication: Video.RemoteTrackPublication
  ) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackSubscriptionFailed`, {
      participant: this,
      publication,
      error,
    });
  };

  onTrackSwitchedOff = (
    _track: Video.RemoteTrack,
    twilioPublication: Video.RemoteTrackPublication
  ) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackSwitchedOff`, {
      participant: this,
      track: publication.track,
    });
  };

  onTrackSwitchedOn = (
    _track: Video.RemoteTrack,
    twilioPublication: Video.RemoteTrackPublication
  ) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackSwitchedOn`, {
      participant: this,
      track: publication.track,
    });
  };

  onTrackUnpublished = (twilioPublication: Video.RemoteTrackPublication) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
      this.remoteAudioTracks = _.filter(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid !== twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
      this.remoteVideoTracks = _.filter(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid !== twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }

    this.eventEmitter.emit(`${kind}TrackPublished`, {
      participant: this,
      publication,
    });
  };

  onTrackUnsubscribed = (
    _twilioTrack: Video.RemoteTrack,
    twilioPublication: Video.RemoteTrackPublication
  ) => {
    const { kind } = twilioPublication;

    let publication:
      | Video.RemoteTrackPublication
      | RemoteAudioTrackPublication
      | RemoteVideoTrackPublication;
    if (kind === 'audio') {
      publication = _.find(
        this.remoteAudioTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else if (kind === 'video') {
      publication = _.find(
        this.remoteVideoTracks,
        ({ trackSid }) => trackSid === twilioPublication.trackSid
      );
    } else {
      publication = twilioPublication;
    }
    const trackProperty = { [`${kind}Track`]: publication?.track };

    this.eventEmitter.emit(`${kind}TrackUnubscribed`, {
      participant: this,
      publication,
      ...trackProperty,
    });
  };

  get sid(): string {
    return this.twilioRemoteParticipant.sid;
  }

  get identity(): string {
    return this.twilioRemoteParticipant.identity;
  }
}
