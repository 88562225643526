import _ from 'lodash';
import { Camera } from './Camera';
import { Microphone } from './Microphone';

export default class Devices {
  static async listCameras() {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = _.filter(
      allDevices,
      ({ kind }) => kind === 'videoinput'
    );

    return videoDevices.map(
      (d) =>
        ({
          id: d.deviceId,
          name: d.label,
          position: null,
        } as Camera)
    );
  }

  static async listMicrophones() {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = _.filter(
      allDevices,
      ({ kind }) => kind === 'audioinput'
    );

    return audioDevices.map(
      (d) =>
        ({
          id: d.deviceId,
          name: d.label,
        } as Microphone)
    );
  }
}
