import { AxiosResponse } from 'axios';
import AUTHORIZATION_STORE from '../models/AuthorizationStore';
import { UnauthenticatedApi } from './Api';

export interface SessionParams {
  email: string;
  password: string;
}

export interface SessionResponse {
  session: {
    token: string;
  };
}

interface ErrorResponse {
  error: string;
}

export async function createSession(params: SessionParams) {
  const auth = await AUTHORIZATION_STORE.getAsync();

  const response = await UnauthenticatedApi.post<
    SessionParams,
    AxiosResponse<SessionResponse | ErrorResponse>
  >(
    'sessions',
    {
      user: params,
    },
    {
      headers: {
        GuestAuthorization: auth,
      },
    }
  );

  if (response.status === 401) {
    const { error } = response.data as ErrorResponse;
    throw error;
  }

  if (response.status !== 200) {
    console.error(response);
    throw 'something went wrong';
  }

  const {
    session: { token },
  } = response.data as SessionResponse;
  const authentication = `Bearer ${token}`;
  await AUTHORIZATION_STORE.set(authentication);
}
