import React from 'react';
import { FindRoomIcon } from '../Icons';
import HomeScreenButton from './HomeScreenButton';
import { Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { homeScreenActionsButtonStyles } from '../../config/homeScreenActionsButtonStyles';

export default function FindRoomButton() {
  const { navigate } = useNavigation();

  return (
    <HomeScreenButton
      icon={<FindRoomIcon />}
      onPress={() => navigate('FindRoom')}
    >
      <Text style={homeScreenActionsButtonStyles.buttonText}>Find a room</Text>
    </HomeScreenButton>
  );
}
