import React, { useEffect, useRef } from 'react';
import { View, ViewProps } from 'react-native';
import RemoteVideoTrack from '../models/RemoteVideoTrack';

interface RemoteVideoTrackViewProps extends ViewProps {
  scaleType?: 'aspectFill' | 'aspectFit';
  mirror?: boolean;
  remoteVideoTrack: RemoteVideoTrack;
}

export default function RemoteVideoTrackView({
  remoteVideoTrack,
  mirror,
  scaleType,
  style,
}: RemoteVideoTrackViewProps) {
  const videoElementRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (remoteVideoTrack) {
      const videoElement = videoElementRef.current;
      remoteVideoTrack.twilioRemoteVideoTrack.attach(videoElement);
      return () => {
        remoteVideoTrack.twilioRemoteVideoTrack.detach(videoElement);
      };
    }
  }, [remoteVideoTrack]);

  const transformStyle = mirror ? { transform: 'scaleX(-1)' } : {};

  return (
    <View style={style}>
      {/* eslint-disable react-native/no-inline-styles */}
      <video
        ref={videoElementRef}
        style={{
          width: '100%',
          height: '100%',
          objectFit: scaleType === 'aspectFill' ? 'cover' : 'contain',
          ...transformStyle,
        }}
      />
    </View>
  );
}
