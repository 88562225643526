import _ from 'lodash';
import { selector } from 'recoil';
import { DEFAULT_SPACING } from '../config/constants';
import roomLayoutCandidatesState from './roomLayoutCandidatesState';
import roomScreenSharingMembershipExistsState from './roomScreenSharingMembershipExistsState';
import { VideoGridLayoutSize } from './roomVideoGridLayoutSizeCandidatesState';
import videoGridSizeState from './videoGridSizeState';

export interface Frame {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface RoomLayout {
  cellSize: number;
  screenSharingVideoFrame?: Frame;
  videoGridCellFrames: Frame[];
}

export function videoGridCellFramesWithinFrame(
  videoGridLayoutSize: VideoGridLayoutSize,
  cellSize: number,
  frame: { x: number; y: number; width: number; height: number }
): Frame[] {
  const { rows } = videoGridLayoutSize;
  return _.flatMap(rows, (row, rowIndex) => {
    const { size } = row;
    const width = size * cellSize + (size - 1) * DEFAULT_SPACING;
    const excessWidth = frame.width - width;
    const rowX = excessWidth / 2;

    const y = rowIndex * (cellSize + DEFAULT_SPACING) + frame.y;
    return _.map(_.range(size), (cellIndex) => {
      const x = frame.x + rowX + cellIndex * (cellSize + DEFAULT_SPACING);
      return { x, y, width: cellSize, height: cellSize };
    });
  });
}

const roomLayoutState = selector<RoomLayout>({
  key: 'roomLayoutState',
  get: ({ get }) => {
    const optimalLayoutCandidate = _.maxBy(
      get(roomLayoutCandidatesState),
      'cellSize'
    );
    const roomScreenSharingMembershipExists = get(
      roomScreenSharingMembershipExistsState
    );
    const videoGridSize = get(videoGridSizeState);

    const { cellSize, videoGridLayout } = optimalLayoutCandidate;

    if (roomScreenSharingMembershipExists) {
      if (optimalLayoutCandidate.direction === 'column') {
        const screenSharingVideoWidth = cellSize * 2;
        const screenSharingExcessHorizontalSpace =
          videoGridSize.width - screenSharingVideoWidth;
        const screenSharingX = screenSharingExcessHorizontalSpace / 2;

        return {
          screenSharingVideoFrame: {
            x: screenSharingX,
            y: 0,
            width: cellSize * 2,
            height: cellSize * 2,
          },
          cellSize,
          videoGridCellFrames: videoGridCellFramesWithinFrame(
            videoGridLayout,
            cellSize,
            {
              x: 0,
              y: cellSize * 2 + DEFAULT_SPACING,
              width: videoGridSize.width,
              height: videoGridSize.height - cellSize * 2 + DEFAULT_SPACING,
            }
          ),
        };
      } else {
        const layoutWidth =
          cellSize * 2 +
          (cellSize + DEFAULT_SPACING) * videoGridLayout.maxRowSize;
        const excessWidth = videoGridSize.width - layoutWidth;
        const screenSharingX = excessWidth / 2;

        return {
          screenSharingVideoFrame: {
            x: screenSharingX,
            y: 0,
            width: cellSize * 2,
            height: cellSize * 2,
          },
          cellSize,
          videoGridCellFrames: videoGridCellFramesWithinFrame(
            videoGridLayout,
            cellSize,
            {
              x: screenSharingX + 2 * cellSize + DEFAULT_SPACING,
              y: 0,
              width:
                videoGridSize.width -
                excessWidth -
                (2 * cellSize + DEFAULT_SPACING),
              height: videoGridSize.height,
            }
          ),
        };
      }
    } else {
      return {
        cellSize,
        videoGridCellFrames: videoGridCellFramesWithinFrame(
          videoGridLayout,
          cellSize,
          {
            ...videoGridSize,
            x: 0,
            y: 0,
          }
        ),
      };
    }
  },
});
export default roomLayoutState;
