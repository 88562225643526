import React from 'react';
import { CreateRoomIcon } from '../Icons';
import HomeScreenButton from './HomeScreenButton';
import { Text } from 'react-native';
import { useMutation } from 'react-query';
import Api from '../../config/Api';
import { useNavigation } from '@react-navigation/native';
import { homeScreenActionsButtonStyles } from '../../config/homeScreenActionsButtonStyles';

export default function CreateRoomButton() {
  const { navigate } = useNavigation();
  const { mutateAsync } = useMutation(
    async () => {
      try {
        const response = await Api.post('rooms');
        return { roomId: response.data.room.id };
      } catch (error) {
        console.error(error);
      }
    },
    {
      onSuccess: (data) => {
        navigate('Room', data);
      },
    }
  );

  return (
    <HomeScreenButton icon={<CreateRoomIcon />} onPress={() => mutateAsync()}>
      <Text style={homeScreenActionsButtonStyles.buttonText}>Create room</Text>
    </HomeScreenButton>
  );
}
