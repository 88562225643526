import _ from 'lodash';
import React, { PropsWithChildren, ReactElement } from 'react';
import { View } from 'react-native';
import WorkflowModal from '../components/workflows/WorkflowModal';

export function enableScreens() {}

export function Screen({ children }: PropsWithChildren<{}>) {
  return <>{children}</>;
}

export function ScreenStack({ children }: PropsWithChildren<{}>) {
  let childrenArray = [];
  React.Children.forEach(children, (child) => {
    if (child) {
      childrenArray.push(child);
    }
  });

  const lastNonModalChildIndex = _.findLastIndex(
    childrenArray,
    (child: ReactElement) => {
      if (child.props?.stackPresentation === 'modal') {
        return false;
      }
      return true;
    }
  );

  const visibleChildren = _.slice(childrenArray, lastNonModalChildIndex);
  return (
    <>
      {_.map(visibleChildren, (child: ReactElement, index) => {
        const { children: childViewChildren, ...childViewProps } = child.props;

        if (child.props?.stackPresentation !== 'modal') {
          return (
            <View key={index} {...childViewProps}>
              {childViewChildren}
            </View>
          );
        }
        return (
          <WorkflowModal
            key={`screen-${index}`}
            onClose={child.props.onDismissed}
          >
            <View {...childViewProps}>{childViewChildren}</View>
          </WorkflowModal>
        );
      })}
    </>
  );
}

export function ScreenStackHeaderConfig() {
  return null;
}
