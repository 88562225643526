import { StyleSheet } from 'react-native';
import { DEFAULT_SPACING } from './constants';

const buttonStyles = StyleSheet.create({
  roomHeaderButton: {
    padding: DEFAULT_SPACING,
    marginHorizontal: DEFAULT_SPACING * 2,
  },
});
export default buttonStyles;
