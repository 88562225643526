import { atom, useSetRecoilState } from 'recoil';

const monetizationFlowVisibleState = atom({
  key: 'monetizationFlowVisibleState',
  default: false,
});
export default monetizationFlowVisibleState;

export function useShowMonetizationFlow() {
  const setMonetizationFlowVisible = useSetRecoilState(
    monetizationFlowVisibleState
  );
  return () => setMonetizationFlowVisible(true);
}

export function useHideMonetizationFlow() {
  const setMonetizationFlowVisible = useSetRecoilState(
    monetizationFlowVisibleState
  );
  return () => setMonetizationFlowVisible(false);
}
