import { selectorFamily } from 'recoil';
import remoteMembershipLocallyMutedState from './remoteMembershipLocallyMutedState';
import remoteMembershipState from './remoteMembershipState';

const remoteMembershipMutedState = selectorFamily<boolean, string>({
  key: 'remoteMembershipMutedState',
  get: (id) => ({ get }) => {
    return (
      get(remoteMembershipLocallyMutedState(id)) ||
      get(remoteMembershipState(id)).muted
    );
  },
});
export default remoteMembershipMutedState;
