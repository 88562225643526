import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import membershipIdState from '../../../state/membershipIdState';
import roomScreenSharingVideoLayoutState from '../../../state/roomScreenSharingVideoLayoutState';
import roomState from '../../../state/roomState';
import RemoteMembershipRoomScreenSharingVideo from './RemoteMembershipRoomScreenSharingVideo';
import SelfRoomScreenSharingVideo from './SelfRoomScreenSharingVideo';

export default function RoomScreenSharingVideo() {
  const room = useRecoilValue(roomState);
  const membershipId = useRecoilValue(membershipIdState);
  const { x, y, width, height } = useRecoilValue(
    roomScreenSharingVideoLayoutState
  );

  const layoutStyle = useMemo(
    () => ({
      left: x,
      top: y,
      width,
      height,
    }),
    [x, y, width, height]
  );

  if (!room?.screenSharingMembershipId) {
    return null;
  }

  return (
    <View style={[styles.root, layoutStyle]}>
      {room.screenSharingMembershipId === membershipId ? (
        <SelfRoomScreenSharingVideo />
      ) : (
        <RemoteMembershipRoomScreenSharingVideo />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});
