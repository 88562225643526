import React, { useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { GRAY, DEFAULT_SPACING } from '../../../config/constants';
import roomScreenSharingStyles from '../../../config/roomScreenSharingStyles';
import { profileAvatarSource } from '../../../hooks/useProfile';
import useRemoteConnection from '../../../hooks/useRemoteConnection';
import remoteMembershipState from '../../../state/remoteMembershipState';
import roomScreenSharingVideoLayoutState from '../../../state/roomScreenSharingVideoLayoutState';
import roomState from '../../../state/roomState';
import { RemoteVideoTrackView } from '../../../twilio';

export default function RemoteMembershipRoomScreenSharingVideo() {
  const room = useRecoilValue(roomState);
  const remoteConnection = useRemoteConnection(room.screenSharingMembershipId);
  const remoteMembership = useRecoilValue(
    remoteMembershipState(room.screenSharingMembershipId)
  );
  const screenSharingVideoTrack = remoteConnection?.screenSharingVideoTrack;

  const { width, height } = useRecoilValue(roomScreenSharingVideoLayoutState);

  const videoSizeStyle = useMemo(
    () => ({
      width: width - DEFAULT_SPACING * 2,
      height: height - DEFAULT_SPACING * 2,
    }),
    [width, height]
  );

  return (
    <View style={[roomScreenSharingStyles.root, styles.root]}>
      {screenSharingVideoTrack && (
        <RemoteVideoTrackView
          remoteVideoTrack={screenSharingVideoTrack}
          scaleType="aspectFit"
          style={[roomScreenSharingStyles.video, videoSizeStyle]}
        />
      )}
      {remoteMembership && (
        <Image
          style={roomScreenSharingStyles.avatarImage}
          source={profileAvatarSource(remoteMembership)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: GRAY,
  },
});
