import _ from 'lodash';
import Video from 'twilio-video';
import { getLocalParticipants } from './LocalParticipant';
import LocalVideoTrackCreateParams from './LocalVideoTrackCreateParams';

const localVideoTracksByName = new Map<string, LocalVideoTrack>();

export function findLocalVideoTrack(name: string) {
  return localVideoTracksByName.get(name);
}

export default class LocalVideoTrack {
  twilioLocalVideoTrack: Video.LocalVideoTrack;

  constructor(twilioLocalVideoTrack: Video.LocalVideoTrack) {
    this.twilioLocalVideoTrack = twilioLocalVideoTrack;
    localVideoTracksByName.set(this.name, this);
  }

  static create = async (params: LocalVideoTrackCreateParams) => {
    if (_.has(params, 'name') && localVideoTracksByName.has(params.name)) {
      return Promise.reject('Duplicate track name');
    }

    const options: Video.CreateLocalTrackOptions = {};
    if (_.has(params, 'name')) {
      options.name = params.name;
    }
    if (_.has(params, 'deviceId')) {
      options.deviceId = params.deviceId;
    }
    if (_.has(params, 'format')) {
      const {
        format: {
          dimensions: { width, height },
          framerate,
        },
      } = params;
      options.width = width;
      options.height = height;
      options.frameRate = framerate;
    }
    const twilioLocalVideoTrack = await Video.createLocalVideoTrack(options);
    const localVideoTrack = new LocalVideoTrack(twilioLocalVideoTrack);

    if (_.has(params, 'enabled')) {
      localVideoTrack.setIsEnabled(params.enabled);
    }

    return localVideoTrack;
  };

  get name(): string {
    return this.twilioLocalVideoTrack.name;
  }

  get state(): string {
    return this.twilioLocalVideoTrack.isStopped
      ? 'ended'
      : this.twilioLocalVideoTrack.isStarted
      ? 'live'
      : null;
  }

  setIsEnabled = (enabled: boolean) => {
    this.twilioLocalVideoTrack.enable(enabled);
    return Promise.resolve();
  };

  destroy = async () => {
    this.twilioLocalVideoTrack.detach();
    this.twilioLocalVideoTrack.stop();

    await Promise.all(
      _.map(getLocalParticipants(), async (localParticipant) => {
        const localParticipantNeedsUnpublish = _.some(
          localParticipant.localVideoTracks,
          ({ localTrack }) => localTrack === this
        );
        if (localParticipantNeedsUnpublish) {
          await localParticipant.unpublishLocalVideoTrack(this);
        }
      })
    );

    localVideoTracksByName.delete(this.name);
    return true;
  };
}
