import { Subscription } from '../hooks/useSubscription';
import { createContext } from 'react';
import { RemoteAudioTrack, RemoteVideoTrack } from '../twilio';

export interface RemoteConnection {
  membershipId: string;
  audioTrack: RemoteAudioTrack;
  videoTrack: RemoteVideoTrack;
  screenSharingVideoTrack: RemoteVideoTrack;
  screenSharingAudioTrack: RemoteAudioTrack;
  subscription: Subscription;
}

const RemoteConnectionsContext = createContext([] as RemoteConnection[]);
export default RemoteConnectionsContext;
