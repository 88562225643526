import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import useRemoteConnection from '../../../hooks/useRemoteConnection';
import remoteMembershipAudioEnabledState from '../../../state/remoteMembershipAudioEnabledState';
import roomState from '../../../state/roomState';
import { RemoteAudioTrack } from '../../../twilio/index.web';

function AttachedWebRemoteAudioTrack({
  webRemoteAudioTrack,
  enabled,
}: {
  webRemoteAudioTrack: RemoteAudioTrack;
  enabled?: boolean;
}) {
  const audioElementRef = useRef<HTMLAudioElement>();
  useEffect(() => {
    if (webRemoteAudioTrack) {
      const audioElement = audioElementRef.current;
      webRemoteAudioTrack.attach(audioElement);
      return () => webRemoteAudioTrack.detatch(audioElement);
    }
  }, [webRemoteAudioTrack]);

  useEffect(() => {
    audioElementRef.current.volume = enabled ?? true ? 1 : 0;
  }, [enabled]);

  return <audio ref={audioElementRef} autoPlay />;
}

export default function RemoteMembershipAudioSubscription({
  id,
}: {
  id: string;
}) {
  const audioEnabled = useRecoilValue(remoteMembershipAudioEnabledState(id));
  const screenSharingAudioEnabled =
    useRecoilValue(roomState).screenSharingMembershipId === id;
  const remoteConnection = useRemoteConnection(id);

  const audioTrack = (remoteConnection?.audioTrack as unknown) as RemoteAudioTrack;
  const screenSharingAudioTrack = (remoteConnection?.screenSharingAudioTrack as unknown) as RemoteAudioTrack;

  return (
    <React.Fragment>
      {audioTrack && (
        <AttachedWebRemoteAudioTrack
          webRemoteAudioTrack={audioTrack}
          enabled={audioEnabled}
        />
      )}
      {screenSharingAudioTrack && (
        <AttachedWebRemoteAudioTrack
          webRemoteAudioTrack={screenSharingAudioTrack}
          enabled={screenSharingAudioEnabled}
        />
      )}
    </React.Fragment>
  );
}
