import { atom } from 'recoil';
import { UpdateProfileAttributes } from '../config/profileApi';

type DraftProfile = Partial<UpdateProfileAttributes>;

const draftProfileState = atom<DraftProfile>({
  key: 'draftProfileState',
  default: {},
});
export default draftProfileState;
