import { IAPItemDetails } from 'expo-in-app-purchases';
import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { DEFAULT_SPACING } from '../../config/constants';
import useAvailableMonthlySubscription from '../../hooks/useAvailableMonthlySubscription';

const MICROS_PER_DOLLAR = 1000000;
function subscriptionFormattedPrice(subscription: IAPItemDetails) {
  if (
    subscription.priceCurrencyCode === 'USD' &&
    subscription.priceAmountMicros % MICROS_PER_DOLLAR === 0
  ) {
    return `$${subscription.priceAmountMicros / MICROS_PER_DOLLAR}`;
  }
  return subscription.price;
}

export default function SubscriptionCard() {
  const subscription = useAvailableMonthlySubscription();

  return (
    <View style={styles.root} key={subscription.productId}>
      <View style={styles.priceRow}>
        <Text style={styles.priceText}>
          {subscriptionFormattedPrice(subscription)}
        </Text>
        <Text style={styles.periodText}>/month</Text>
      </View>
      <Text style={styles.descriptionText}>
        No time limit on any of the rooms you create or join.
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    borderRadius: DEFAULT_SPACING,
    padding: DEFAULT_SPACING * 5,
    ...Platform.select({
      android: { elevation: 10 },
      default: {
        shadowOffset: { width: 0, height: DEFAULT_SPACING / 2 },
        shadowRadius: DEFAULT_SPACING / 2,
        shadowOpacity: 0.25,
        shadowColor: 'black',
      },
    }),
    marginTop: DEFAULT_SPACING * 5,
    backgroundColor: 'white',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  priceRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    alignContent: 'center',
  },
  priceText: {
    fontWeight: 'bold',
    fontSize: 36,
  },
  periodText: {
    fontSize: 14,
    textAlign: 'center',
  },
  descriptionText: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: DEFAULT_SPACING * 3,
    paddingHorizontal: DEFAULT_SPACING * 5,
  },
});
