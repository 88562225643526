import React from 'react';

export default function Svg({ children }) {
  /* eslint-disable react-native/no-inline-styles */
  return (
    <img
      src={children.type}
      {...children.props}
      style={{ userSelect: 'none' }}
    />
  );
  /* eslint-enable react-native/no-inline-styles */
}
